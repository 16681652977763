import { useTranslation } from "react-i18next";
import { SelectedCompanyFeatureFlagRequired } from "components/SelectedCompanyFeatureFlagRequired";
import {
  GeneralLayoutContent,
  GeneralLayoutHeader,
} from "components/GeneralLayout";
import { FaqPage } from "./FaqPage";

import { ReactComponent as ContactIcon } from "assets/icons/Wallet/contact.svg";

export function FaqPageWithGates() {
  const { t } = useTranslation();

  return (
    <SelectedCompanyFeatureFlagRequired allowByFeatureFlags={() => true}>
      <GeneralLayoutContent
        header={
          <GeneralLayoutHeader title={t("title.faq")} icon={<ContactIcon />} />
        }
      >
        <FaqPage />
      </GeneralLayoutContent>
    </SelectedCompanyFeatureFlagRequired>
  );
}
