import { Navigate } from "react-router-dom";

import { routes } from "routes";

import { getIsStripeCompletedByDriverProfile } from "helpers/getIsStripeCompletedByDriverProfile";
import { useQueryCompanies } from "hooks/useQueryCompanies";

import { Container } from "components/Container";
import { WaveLayout } from "components/WaveLayout";

import { StripeKyc } from "components/StripeConfirmationModal";

export const StripeKycPage = () => {
  const { driverCompaniesById, currentCompanyId, driverProfilesByCompanyId } =
    useQueryCompanies();

  const currentFleet = driverCompaniesById[currentCompanyId];
  const currentDriverProfile = driverProfilesByCompanyId[currentCompanyId];
  const driverProfileId = currentDriverProfile?.id;

  if (!currentDriverProfile) {
    return null;
  }

  if (getIsStripeCompletedByDriverProfile(currentDriverProfile)) {
    return <Navigate to={routes.index.getURL()} />;
  }

  return (
    <WaveLayout>
      <Container width="small">
        <StripeKyc
          driverProfile={currentDriverProfile}
          driverProfileId={driverProfileId}
          selectedFleetSlug={currentFleet?.url_slug}
        />
      </Container>
    </WaveLayout>
  );
};
