import { useEffect, useRef } from "react";

/**
 * Ensures that effect is triggered only once after component is rendered,
 * Avoid <StrictMode>'s force cleanup and second-time setup.
 */
export function useEffectOnce(effect) {
  const runEffect = useRef(true);

  useEffect(() => {
    if (runEffect.current) {
      runEffect.current = false;
      return effect();
    }

    return () => undefined;
  }, []);
}
