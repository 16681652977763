import { forwardRef } from "react";
import { InputNumber } from "antd";
import "./NumberInput.scss";

export const NumberInput = forwardRef(function InputInternal(
  {
    currency = "£",
    min = 0,
    max = 40,
    value = min,
    onChange = () => {},
    ...otherProps
  },
  ref,
) {
  return (
    <InputNumber
      {...otherProps}
      min={min}
      max={(max || 40) * 100}
      onChange={onChange}
      formatter={() => `${currency} ${value}`}
      className="numberInput"
      ref={ref}
    />
  );
});
