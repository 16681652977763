import { useState } from "react";
import { Drawer } from "antd";
import { format, isDate, sub } from "date-fns";
import { useTranslation } from "react-i18next";

import { ReactComponent as XIcon } from "assets/icons/xIconCurrentColor.svg";
import { Button } from "components/Button";
import { DayPicker } from "./DayPicker";

import styles from "./MobileDatePickerRangeDrawer.module.scss";
import "./MobileDatePickerRangeDrawer.scss";

export const ONE_MONTH_AGO = sub(new Date(), { months: 1 });

export function MobileDatePickerRangeDrawer({
  visible,
  value,
  onClose,
  onApply,
}) {
  const { t } = useTranslation();

  const valueAsObject = asObject(value);

  const [range, setRange] = useState({
    from: valueAsObject.from ?? ONE_MONTH_AGO,
    to: valueAsObject.to,
  });

  const isRangeValid = isDate(range?.from) && isDate(range?.to);

  return (
    <Drawer
      closable={false}
      visible={visible}
      placement="bottom"
      className="icabbi-bottom-drawer"
      onClose={onClose}
    >
      <div className={styles.header}>
        <h1 className={styles.title}>
          {t("componentDatePicker.filterByDateRange")}
        </h1>
        <Button variant="icon" shape="round" onClick={onClose}>
          <XIcon />
        </Button>
      </div>
      <div className={styles.content}>
        <div className={styles.badge}>
          <p className={styles.badgeLabel}>
            {t("componentDatePicker.selectedRange")}
          </p>
          <p className={styles.badgeValue}>{formatRange(range)}</p>
        </div>
        <div className={styles.dayPickerWrapper}>
          <DayPicker
            fixedWeeks
            mode="range"
            defaultMonth={ONE_MONTH_AGO}
            className={styles.dayPicker}
            selected={range}
            onSelect={setRange}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          block
          variant="primary"
          shape="round"
          onClick={() => onApply(asArray(range))}
          disabled={!isRangeValid}
        >
          {t("componentDatePicker.filter")}
        </Button>
      </div>
    </Drawer>
  );
}

export function asObject([from, to] = []) {
  return {
    from,
    to,
  };
}

export function asArray({ from, to } = {}) {
  return [from, to];
}

export const MOBILE_DATE_FORMAT = "MMM d";

export function formatRange({ from, to } = {}, formatStr = MOBILE_DATE_FORMAT) {
  return `${formatDay(from, formatStr)} - ${formatDay(to, formatStr)}`;
}

export function formatDay(day, formatStr) {
  if (!isDate(day)) {
    return ".";
  }

  return format(day, formatStr);
}
