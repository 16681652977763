import { AuthRequired } from "components/AuthRequired";
import { AcceptedLatestUserAgreementVersionRequired } from "components/AcceptedLatestUserAgreementVersionRequired";
import { StripeKycPage } from "./StripeKycPage";

export function OnboardingStripeKycPage() {
  return (
    <AuthRequired>
      <AcceptedLatestUserAgreementVersionRequired>
        <StripeKycPage />
      </AcceptedLatestUserAgreementVersionRequired>
    </AuthRequired>
  );
}
