import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import debounce from "lodash/debounce";

i18next
  .use(initReactI18next)
  .use(HttpBackend)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    interpolation: {
      /**
       * escapeValue is disabled as a fix of TA-1627.
       * It is expected that React itself escapes values good enough.
       */
      escapeValue: false,
    },
  });

export function changeLanguage(language) {
  if (!language || language === i18next.language) {
    /**
     * Skip to mitigate calling with the `undefined`.
     * Skip to mitigate calling with the selected language.
     */
    return;
  }

  i18next.changeLanguage(language);
}

/**
 * Debounced to mitigate calling from different effects and callbacks.
 */
export const changeLanguageDebounced = debounce(changeLanguage, 160);

/**
 * getLatestTermsAndConditionsVersion is a function, but not a constant in sake
 * of testing simplicity
 * @returns {string}
 */
export function getLatestTermsAndConditionsVersion() {
  return process.env.REACT_APP_LATEST_TERMS_AND_CONDITIONS_VERSION;
}

/**
 * getTermsAndConditionsVersionList is a function, but not a constant in sake
 * of testing simplicity
 * @returns {string[]}
 */
export function getTermsAndConditionsVersionList() {
  return ["28-05-2024-1"];
}
