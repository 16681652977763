import { Navigate } from "react-router-dom";
import { useSelectedFleet } from "hooks/useSelectedFleet";

export function SelectedCompanyFeatureFlagRequired({
  children,
  allowByFeatureFlags = () => false,
  redirectToWhenNotAllowed = "/not-found",
}) {
  const { selectedFleet } = useSelectedFleet();

  const isDriverPayEnabled = Boolean(selectedFleet?.enable_driver_pay);
  const isDocumentsFeatureEnabled = Boolean(
    selectedFleet?.enable_documents_feature,
  );

  if (!selectedFleet) {
    return null;
  }

  const isAllowed = allowByFeatureFlags({
    isDriverPayEnabled,
    isDocumentsFeatureEnabled,
  });

  if (!isAllowed) {
    // if return <NotFoundPage>, then it will render alongside header and sidebar nav;
    // if navigate to unknown route, then it will render only <NotFoundPage>;
    return <Navigate to={redirectToWhenNotAllowed} replace />;
  }

  return children;
}
