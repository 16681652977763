import { Button, Form, Input, message, Select } from "antd";
import { useMemo } from "react";
import { useState } from "react";
import {
  USER_PERMISSION_FLEET_ADMIN,
  USER_PERMISSION_FLEET_ADMIN_READONLY,
  USER_PERMISSION_SUPER_ADMIN,
} from "store/slices/authUser";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "helpers/windowMassages";
import { useCallback } from "react";
import { api } from "api";
import "./Invitation.scss";
import useRoles from "hooks/useRoles";

const { Option } = Select;

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
  },
};

export const Invitation = () => {
  const [selectedRole, setSelectedRole] = useState(null);
  const [inviteFleetAdmin] = api.endpoints.inviteFleetAdmin.useMutation();
  const [hasSelectedFleets, setHasSelectedFleets] = useState(true);
  const [inviteUserForm] = Form.useForm();
  const { isSuperAdmin, isSupport } = useRoles();

  const { data: fleetsData } = api.endpoints.getFleets.useQuery();

  const fleets = fleetsData?.data || [];

  const showFleetSelector = useMemo(() => {
    if (selectedRole === USER_PERMISSION_SUPER_ADMIN) {
      return false;
    }
    return true;
  }, [selectedRole]);

  const handleFleetSelectorChange = useCallback(() => {
    setHasSelectedFleets(true);
  }, []);

  const onInvitationSend = useCallback(
    (e) => {
      const role = e.role;
      let fleet_ids = e.fleet_ids || [];

      if (role === USER_PERMISSION_SUPER_ADMIN) {
        fleet_ids = [];
      } else {
        if (!fleet_ids.length) {
          return setHasSelectedFleets(false);
        }
      }
      showLoadingMsg({ content: "Sending...", key: "invitation_loading" });

      inviteFleetAdmin({
        ...e,
        role,
        fleet_ids,
      })
        .unwrap()
        .then(() => {
          showSuccessMsg({ content: "User invited successfuly " });
          inviteUserForm.resetFields();
        })
        .catch(({ data }) => {
          const message = data?.error?.message;
          showErrorMsg({
            content: `There was an error processing your request${message ? `: ${message}` : ""}`,
          });
          const errors = data?.errors;
          if (errors) {
            Object.keys(errors).forEach((key) => {
              errors[key].forEach((err) => {
                showErrorMsg({ content: err });
              });
            });
          }
        })
        .finally(() => {
          message.destroy("invitation_loading");
        });
    },
    [inviteFleetAdmin, inviteUserForm],
  );

  return (
    <div className="invitation-wrapper">
      <h2>Invite team members</h2>
      <p>
        You can add unlimited teammates. For now, everyone added will have the
        same permissions as you, but we’re working on providing more granular
        control in the near future.
      </p>
      <Form
        validateMessages={validateMessages}
        onFinish={onInvitationSend}
        autoComplete="off"
        layout="vertical"
        form={inviteUserForm}
      >
        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={isSupport} placeholder="Email" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          label="Roles"
          name="role"
        >
          <Select
            disabled={isSupport}
            onChange={setSelectedRole}
            allowClear
            placeholder="Roles"
          >
            {isSuperAdmin && (
              <Option key={USER_PERMISSION_SUPER_ADMIN}>
                iCabbi Super Admin
              </Option>
            )}
            <Option key={USER_PERMISSION_FLEET_ADMIN}>Admin</Option>
            <Option key={USER_PERMISSION_FLEET_ADMIN_READONLY}>
              Read only
            </Option>
          </Select>
        </Form.Item>
        {showFleetSelector && (
          <Form.Item label="Fleet selector" name="fleet_ids">
            <Select
              disabled={isSupport}
              onChange={handleFleetSelectorChange}
              mode="multiple"
              allowClear
              placeholder="Fleets"
            >
              {fleets.map(({ name, id }) => (
                <Option key={id}>{name}</Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {!hasSelectedFleets && (
          <div className="ant-form-item-explain ant-form-item-explain-connected noFleetSelected">
            <div role="alert" className="ant-form-item-explain-error">
              Select at least one fleet!
            </div>
          </div>
        )}
        <Form.Item>
          <Button htmlType="submit">invite</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Invitation;
