import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { DateRangeInput } from "components/DateRangeInput";

export const Filters = ({
  searchInputValue,
  onSearch,
  onClearSearch,
  date,
  onDateChange,
  dropdown,
  showDropdown,
}) => {
  return (
    <div className="filters">
      <Input
        addonBefore={<SearchOutlined />}
        placeholder="Search Driver Ref."
        onPressEnter={onSearch}
        value={searchInputValue}
        onChange={({ target }) => onClearSearch(target.value)}
        allowClear
      />
      <DateRangeInput value={date} onChange={onDateChange} />
      {showDropdown && dropdown}
    </div>
  );
};
