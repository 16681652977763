import { Fragment } from "react";
import clsx from "clsx";

import { DocumentStatus } from "../../statusUtils";

import { ReactComponent as CheckIcon } from "./check.svg";
import { ReactComponent as ExclamationIcon } from "./exclamation.svg";
import { ReactComponent as CrossIcon } from "./cross.svg";
import { ReactComponent as ClockIcon } from "./clock.svg";
import { ReactComponent as QuestionIcon } from "./question.svg";

import styles from "./NewDocumentStatusIcon.module.scss";

const statusToIconComponent = {
  [DocumentStatus.UP_TO_DATE]: CheckIcon,
  [DocumentStatus.EXPIRING]: ExclamationIcon,
  [DocumentStatus.EXPIRED]: CrossIcon,
  [DocumentStatus.REJECTED]: CrossIcon,
  [DocumentStatus.IN_REVIEW]: ClockIcon,
  [DocumentStatus.ABSENT]: QuestionIcon,
  [DocumentStatus.REQUIRES_INPUT]: QuestionIcon,
};

export function NewDocumentStatusIcon({ status, inline = false }) {
  const Icon = statusToIconComponent[status] ?? Fragment;

  return (
    <div
      className={clsx(styles.container, {
        [styles.inline]: inline,
        [styles.success]: status === DocumentStatus.UP_TO_DATE,
        [styles.warning]: status === DocumentStatus.EXPIRING,
        [styles.error]:
          status === DocumentStatus.EXPIRED ||
          status === DocumentStatus.REJECTED,
        [styles.waiting]: status === DocumentStatus.IN_REVIEW,
        [styles.absent]:
          status === DocumentStatus.ABSENT ||
          status === DocumentStatus.REQUIRES_INPUT,
      })}
    >
      <Icon className={styles.icon} />
    </div>
  );
}
