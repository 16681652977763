/**
 * Frontend-only enum intended to distinguish different document
 * urgency/importance levels.
 * @type {Readonly<{REQUIRES_INPUT: "requires-input", UP_TO_DATE: "up-to-date",
 *   EXPIRED: "expired", EXPIRING: "expiring", IN_REVIEW: "in-review", ABSENT:
 *   "absent", REJECTED: "rejected"}>}
 */
export const DocumentStatus = Object.freeze({
  UP_TO_DATE: "up-to-date",
  REQUIRES_INPUT: "requires-input",
  EXPIRING: "expiring",
  EXPIRED: "expired",
  IN_REVIEW: "in-review",
  ABSENT: "absent",
  REJECTED: "rejected",
});
