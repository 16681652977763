import { Alert, Button, Form, Input, Select } from "antd";
import { SubTopNavigator } from "components/SubTopNavigator";
import { useSearchParams } from "react-router-dom";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "helpers/windowMassages";
import {
  USER_PERMISSION_ADMIN,
  USER_PERMISSION_FLEET_ADMIN_READONLY,
  USER_PERMISSION_SUPER_ADMIN,
} from "store/slices/authUser";
import useRoles from "hooks/useRoles";
import { api } from "api";
import { useMemo } from "react";
import "./User.scss";

const { Option } = Select;

export const User = () => {
  const [urlParams] = useSearchParams();
  const { user } = useRoles();

  const roles = [
    USER_PERMISSION_ADMIN,
    USER_PERMISSION_FLEET_ADMIN_READONLY,
    USER_PERMISSION_SUPER_ADMIN,
  ];

  const currentRole =
    roles.filter((role) => user?.roles.indexOf(role) !== -1)[0] || "";

  const [form] = Form.useForm();
  const id = urlParams.get("id") || "";
  const name = urlParams.get("name") || "";
  const email = urlParams.get("email") || "";

  const [updateUser] = api.endpoints.updateUser.useMutation();

  const isUserSuperAdmin = useMemo(() => {
    return user.roles.includes(USER_PERMISSION_SUPER_ADMIN);
  }, [user.roles]);

  const onFinish = (body) => {
    showLoadingMsg({ content: "Saving..." });
    updateUser({
      id,
      body,
    })
      .unwrap()
      .then(() => {
        showSuccessMsg({
          content: "Updated successfully.",
        });
      })
      .catch((err) => {
        showErrorMsg({
          content: err.data.message,
        });
      });
  };

  return (
    <div className="user-wrapper">
      <SubTopNavigator pathName="console/users" subject="Edit team member" />
      <div className="edit-section">
        <Alert
          message="Please edit the details with caution"
          type="info"
          banner
        />
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            name,
            email,
            role: currentRole,
          }}
          validateMessages={{
            types: {
              email: "${label} is not a valid email!",
            },
          }}
        >
          <Form.Item
            label="Full name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email address"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Email can't be empty",
              },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Roles"
            name="role"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Roles">
              {isUserSuperAdmin && (
                <Option value={USER_PERMISSION_SUPER_ADMIN}>
                  iCabbi Super Admin
                </Option>
              )}
              <Option value={USER_PERMISSION_ADMIN}>Admin</Option>
              <Option value={USER_PERMISSION_FLEET_ADMIN_READONLY}>
                Read only
              </Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default User;
