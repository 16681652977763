import { useMemo } from "react";
import keyBy from "lodash/keyBy";
import { useTranslation } from "react-i18next";
import { useFlags } from "launchdarkly-react-client-sdk";

import { getContentOfRelevantItems } from "helpers/getContentOfRelevantItems";
import { useFeatureFlagsFromSearchParams } from "hooks/useFeatureFlagsFromSearchParams";

import { DocumentType } from "./DocumentType";
import { FieldType } from "./FieldType";

export function useQueryPredefinedDocumentTypes() {
  const { t } = useTranslation();

  const { releaseAddInsurerField: isInsurerFieldReleasedFromLaunchDarkly } =
    useFlags();

  const { isInsurerFieldReleased: isInsurerFieldReleasedFromSearchParams } =
    useFeatureFlagsFromSearchParams();

  const isInsurerFieldReleased =
    isInsurerFieldReleasedFromLaunchDarkly ||
    isInsurerFieldReleasedFromSearchParams;

  return useMemo(() => {
    return keyBy(
      [
        {
          id: DocumentType.ADDRESS_PROOF,
          name: t("documentTypeProofOfAddress.name"),
          description: t("documentTypeProofOfAddress.description"),
          short_description: t("documentTypeProofOfAddress.shortDescription"),
          documentable_types: ["driver_profile", "driver_application"],
          expiry_date_required: false,
          upload_required: true,
          minimum_files_to_upload: 1,
          maximum_files_to_upload: 2,
          file_upload_label: t("documentTypeProofOfAddress.uploadLabel"),
          helper_text: t("documentTypeProofOfAddress.helperText"),
          extra_info: [
            {
              name: "address",
              parameter: "address",
              label: t("field.yourAddress"),
              type: FieldType.TEXT,
            },
          ],
        },

        {
          id: DocumentType.DRIVER_LICENSE,
          name: t("documentTypeDriverLicense.name"),
          description: t("documentTypeDriverLicense.description"),
          short_description: t("documentTypeDriverLicense.shortDescription"),
          documentable_types: ["driver_profile", "driver_application"],
          expiry_date_required: true,
          upload_required: true,
          minimum_files_to_upload: 2,
          maximum_files_to_upload: 2,
          file_upload_label: t("documentTypeDriverLicense.uploadLabel"),
          extra_info: [
            {
              name: "licence",
              parameter: "licence",
              label: t("field.driverLicenseNumber"),
              type: FieldType.TEXT,
            },
          ],
        },

        {
          id: DocumentType.BADGE,
          name: t("documentTypeBadge.name"),
          description: t("documentTypeBadge.description"),
          short_description: t("documentTypeBadge.shortDescription"),
          documentable_types: ["driver_profile", "driver_application"],
          expiry_date_required: true,
          upload_required: true,
          minimum_files_to_upload: 1,
          maximum_files_to_upload: 2,
          file_upload_label: t("documentTypeBadge.uploadLabel"),
          extra_info: [
            {
              name: "badge_number",
              parameter: "badge_number",
              label: t("field.badgeNumber"),
              type: FieldType.TEXT,
            },
            {
              name: "badge_type",
              parameter: "badge_type",
              label: t("field.badgeType"),
              type: FieldType.SELECT,
              payload: {
                options: [
                  {
                    value: "licensed_taxi",
                    children: t("optionBadgeType.licensedTaxi"),
                  },
                  {
                    value: "PRIVATE HIRE",
                    children: t("optionBadgeType.privateHire"),
                  },
                  {
                    value: "BOTH",
                    children: t("optionBadgeType.both"),
                  },
                ],
              },
            },
          ],
        },

        {
          id: DocumentType.VEHICLE_DETAILS,
          name: t("documentTypeVehicleDetails.name"),
          description: t("documentTypeVehicleDetails.description"),
          short_description: t("documentTypeVehicleDetails.shortDescription"),
          documentable_types: ["vehicle", "driver_application"],
          expiry_date_required: false,
          upload_required: false,
          minimum_files_to_upload: null,
          maximum_files_to_upload: null,
          file_upload_label: null,
          extra_info: [
            {
              name: "reg",
              parameter: "reg",
              label: t("field.vehicleRegistration"),
              type: FieldType.TEXT,
            },
            {
              name: "make",
              parameter: "make",
              label: t("field.vehicleMake"),
              type: FieldType.TEXT,
            },
            {
              name: "model",
              parameter: "model",
              label: t("field.vehicleModel"),
              type: FieldType.TEXT,
            },
            {
              name: "colour",
              parameter: "colour",
              label: t("field.vehicleColor"),
              type: FieldType.TEXT,
            },
          ],
        },

        {
          id: DocumentType.PLATE,
          name: t("documentTypePlate.name"),
          description: t("documentTypePlate.description"),
          short_description: t("documentTypePlate.shortDescription"),
          documentable_types: ["vehicle", "driver_application"],
          expiry_date_required: true,
          upload_required: true,
          minimum_files_to_upload: 1,
          maximum_files_to_upload: 2,
          file_upload_label: t("documentTypePlate.uploadLabel"),
          extra_info: [
            {
              name: "plate",
              parameter: "plate",
              label: t("field.plateNumber"),
              type: FieldType.TEXT,
            },
          ],
        },

        {
          id: DocumentType.INSURANCE,
          name: t("documentTypeInsurance.name"),
          description: t("documentTypeInsurance.description"),
          short_description: t("documentTypeInsurance.shortDescription"),
          documentable_types: ["vehicle", "driver_application"],
          expiry_date_required: true,
          upload_required: true,
          minimum_files_to_upload: 1,
          maximum_files_to_upload: 2,
          file_upload_label: t("documentTypeInsurance.uploadLabel"),
          extra_info: getContentOfRelevantItems([
            {
              condition: isInsurerFieldReleased,
              content: {
                name: "insurer",
                parameter: "insurer",
                label: t("field.insurer"),
                type: FieldType.TEXT,
              },
            },
            {
              condition: true,
              content: {
                name: "insurance",
                parameter: "insurance",
                label: t("field.policyNumber"),
                type: FieldType.TEXT,
              },
            },
          ]),
        },

        {
          id: DocumentType.SCHOOL_BADGE,
          name: t("documentTypeSchoolBadge.name"),
          description: t("documentTypeSchoolBadge.description"),
          short_description: t("documentTypeSchoolBadge.shortDescription"),
          documentable_types: ["driver_profile", "driver_application"],
          expiry_date_required: true,
          upload_required: true,
          minimum_files_to_upload: 1,
          maximum_files_to_upload: 2,
          file_upload_label: t("documentTypeSchoolBadge.uploadLabel"),
          extra_info: [],
        },

        {
          id: DocumentType.COUNCIL_COMPLIANCE,
          name: t("documentTypeCouncilCompliance.name"),
          description: t("documentTypeCouncilCompliance.description"),
          short_description: t(
            "documentTypeCouncilCompliance.shortDescription",
          ),
          documentable_types: ["vehicle", "driver_application"],
          expiry_date_required: true,
          upload_required: true,
          minimum_files_to_upload: 1,
          maximum_files_to_upload: 2,
          file_upload_label: t("documentTypeCouncilCompliance.uploadLabel"),
          extra_info: [],
        },
      ],
      "id",
    );
  }, [t, isInsurerFieldReleased]);
}
