import {
  driverBookingsPaymentMethodsInitialValues,
  driverBookingsPaymentStatusInitialValues,
} from "pages/BookingsListPage";

export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      getBookingsList: build.query({
        keepUnusedDataFor: 0,
        query: ({ isWallet, ...params }) => {
          let payment_type = params.payment_type;
          if (
            payment_type.length ===
              driverBookingsPaymentMethodsInitialValues.length &&
            isWallet
          ) {
            payment_type = [];
          }
          let payout_status = params.payout_status;
          if (
            payout_status.length ===
              driverBookingsPaymentStatusInitialValues.length &&
            isWallet
          ) {
            payout_status = [];
          }
          return {
            params: {
              ...params,
              payment_type,
              payout_status,
            },
            url: "bookings",
          };
        },
        serializeQueryArgs: ({ endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems) => {
          const firstFleetInListId = newItems?.data?.[0]?.fleet_id;
          const firstFleetInCacheId = currentCache?.data?.[0]?.fleet_id;

          if (firstFleetInListId !== firstFleetInCacheId) {
            currentCache.data = [...(newItems?.data || [])];
          } else {
            currentCache.data = [
              ...currentCache.data,
              ...(newItems?.data || []),
            ];
          }

          currentCache.meta = newItems?.meta;
          currentCache.links = newItems?.links;
        },
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
      }),

      putPayoutStatus: build.mutation({
        query: ({ id, ...data }) => ({
          data,
          url: `bookings/${id}/payout-status`,
          method: "PUT",
        }),
      }),

      putPayoutStatusMultiple: build.mutation({
        query: (data) => ({
          data,
          url: "bookings/payout-status",
          method: "PUT",
        }),
      }),

      getBookingById: build.query({
        query: ({ id, params }) => ({
          url: `bookings/${id}`,
          method: "GET",
          params,
        }),
      }),

      syncBooking: build.mutation({
        query: ({ id, params }) => ({
          url: `bookings/sync/${id}`,
          method: "GET",
          params,
        }),
      }),

      exportMultipleBookings: build.mutation({
        query: ({
          fleet_id,
          model_ids,
          date_from,
          date_to,
          payment_type,
          payment_status,
        }) => ({
          method: "POST",
          url: "bookings/export",
          params: {
            fleet_id,
            model_ids,
            date_from,
            date_to,
            payment_type,
            payment_status,
          },
        }),
      }),

      exportBookings: build.mutation({
        query: ({ date_from, date_to }) => ({
          method: "GET",
          params: {
            date_from,
            date_to,
          },
          url: "bookings/export",
        }),
      }),
    }),
  });
}
