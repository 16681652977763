import { createSlice } from "@reduxjs/toolkit";

const initialState = normalizeFleetId(getSelectedFleetIdFromLocalStorage());

export const selectedFleetIdSlice = createSlice({
  name: "selectedFleetId",
  initialState,
  reducers: {
    setSelectedFleetId: (state, action) => {
      return normalizeFleetId(action.payload);
    },
  },
});

function getSelectedFleetIdFromLocalStorage() {
  return localStorage.getItem("selectedFleetId");
}

export function setSelectedFleetIdToLocalStorage(value) {
  return localStorage.setItem("selectedFleetId", value);
}

export function normalizeFleetId(fleetId) {
  const parsedFleetId = parseInt(fleetId, 10);

  if (Number.isNaN(parsedFleetId)) {
    return null;
  }

  return parsedFleetId;
}

export function selectSelectedFleetId(state) {
  return state.selectedFleetId;
}

export const { setSelectedFleetId } = selectedFleetIdSlice.actions;
