import { useTranslation } from "react-i18next";
import { api } from "api";

import { routes } from "../../../routes";

import { Button } from "components/Button";
import { InfoSection } from "components/InfoSection/InfoSection";

import { ReactComponent as AddressIcon } from "assets/icons/address-icon.svg";
import { ReactComponent as PhotoIdIcon } from "assets/icons/photo-id-icon.svg";
import { ReactComponent as WalletIcon } from "assets/icons/wallet-icon.svg";
import { ReactComponent as PoweredByStripeIcon } from "assets/icons/stripe-powered-icon.svg";

import styles from "./StripeKyc.module.scss";

export const StripeKyc = ({
  driverProfileId,
  driverProfile,
  selectedFleetSlug,
  children,
}) => {
  const { t } = useTranslation();

  const [getStripeKycUrl, { isLoading }] =
    api.endpoints.getStripeKycUrl.useMutation();

  const handleStripeRedirect = () => {
    if (!driverProfileId && !driverProfile) return;

    const isPayoutsAccountRequired = Boolean(
      driverProfile?.stripe_requirements?.includes("external_account"),
    );

    const relativePath = isPayoutsAccountRequired
      ? routes.onboardingSelectPayoutAccount.getURL(
          selectedFleetSlug,
          driverProfileId,
        )
      : routes.walletHome.getURL();
    const url = window.location.origin + relativePath;

    getStripeKycUrl({
      id: driverProfileId,
      refresh_url: url,
      return_url: url,
      type: "account_onboarding",
    })
      .unwrap()
      .then((response) => {
        window.location.href = response.url;
      });
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>
        {t("componentStripeKyc.setupYourPayouts")}
      </h1>
      <h2 className={styles.subheading}>
        {t("componentStripeKyc.insureInformation")}
      </h2>
      <InfoSection
        iconComponent={<PhotoIdIcon />}
        heading={t("componentStripeKyc.photoId")}
        subheading={t("componentStripeKyc.photoIdDescription")}
      />
      <hr className={styles.divider} />
      <InfoSection
        iconComponent={<AddressIcon />}
        heading={t("componentStripeKyc.proofOfAddress")}
        subheading={t("componentStripeKyc.proofOfAddressDescription")}
      />
      <hr className={styles.divider} />
      <InfoSection
        iconComponent={<WalletIcon />}
        heading={t("componentStripeKyc.bankAccountDetails")}
      />
      <Button
        variant="primary"
        shape="round"
        block
        onClick={handleStripeRedirect}
        disabled={isLoading}
      >
        {t("action.continue")}
      </Button>
      <PoweredByStripeIcon className={styles.stripeIcon} />
      {children}
    </div>
  );
};
