import { forwardRef } from "react";

export const InputCanvas = forwardRef(function InputCanvasInternal(props, ref) {
  return (
    <canvas
      ref={ref}
      style={{
        width: "100%",
        height: 200,
        border: "1px solid #ddd",
        borderRadius: 6,
      }}
    />
  );
});
