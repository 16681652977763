import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { routes } from "../../routes";

const EMAIL_NOT_VERIFIED_BACKEND_ERROR_TEXT =
  "Your email address is not verified.";

export function AuthRequired({
  children,
  allowAccessWithoutEmailVerification = false,
}) {
  const { user, error, isLoading } = useAuth();
  const { pathname, search } = useLocation();

  if (isLoading) {
    return null;
  }

  const isEmailNotVerified =
    error?.status === 403 &&
    error.data.message === EMAIL_NOT_VERIFIED_BACKEND_ERROR_TEXT;

  if (!allowAccessWithoutEmailVerification && isEmailNotVerified) {
    return (
      <Navigate
        to={routes.checkEmail.getURL("unknown")}
        state={{ pathname, search }}
        replace
      />
    );
  }

  if (error?.status === 401) {
    return (
      <Navigate
        to={routes.signIn.getURL()}
        state={{ pathname, search }}
        replace
      />
    );
  }

  if (
    allowAccessWithoutEmailVerification ||
    (user !== null && user !== undefined)
  ) {
    return children;
  }

  return null;
}
