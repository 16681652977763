import { AuthRequired } from "components/AuthRequired";
import { EmailVerificationPage } from "./EmailVerificationPage";

export function EmailVerificationPageWithGates() {
  return (
    <AuthRequired allowAccessWithoutEmailVerification>
      <EmailVerificationPage />
    </AuthRequired>
  );
}
