import {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useLayoutEffect,
} from "react";
import { ReactComponent as Loader } from "../../../assets/icons/loader.svg";
import { useDispatch, useSelector } from "react-redux";
import { api, axiosInstance } from "api";
import {
  showErrorMsg,
  showInfoMsg,
  showLoadingMsg,
  showSuccessMsg,
  showWarningMsg,
} from "../../../helpers/windowMassages";
import "./BookingsList.scss";
import DeclinementDrawer from "./Filters/DeclinementDrawer/DeclinementDrawer";
import { Filters } from "./Filters/Filters";
import Table, { BOOKING_LIST_TABLE_WRAPPER } from "./Table/Table";
import { ScrollTop } from "components/ScrollTop";
import { differenceInCalendarDays, format } from "date-fns";
import {
  bookingActionOptions,
  bookingListInitalPaymentFilterValue,
  paymentStatusOptions,
  paymentStatusOptionsInitialValue,
  paymentTypeOptions,
  paymentTypeOptionsInitialValue,
} from "./Filters/DropdownOptions";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { CustomDropdown } from "components/CustomDropdown";
import { MenuToggle } from "components/MenuToggle/MenuToggle";
import { PageHeader } from "components/PageHeader/PageHeader";
import { ReactComponent as TransactionsIcon } from "../../../assets/icons/Sidebar/bookings.svg";
import { generateListApiParams } from "helpers/helpers";
import { useIsMobile } from "hooks/useMobile";
import { setBookingDateFilter } from "store/slices/bookingFilters";
import { RedirectToCompany, useQueryCompanies } from "hooks/useQueryCompanies";
import { PAYOUT_STATUS } from "constants/index";

const BOOKING_TABLE_ID = "booking_list_table";

const lastSevenDays = new Date().setDate(new Date().getDate() - 7);

const initialParams = (id) =>
  generateListApiParams(id, "fleet_id", true, 1, 25, {
    date_from: format(new Date(lastSevenDays), "yyyy-MM-dd"),
    date_to: format(new Date(), "yyyy-MM-dd"),
    payment_type: paymentTypeOptionsInitialValue,
    payout_status: paymentStatusOptionsInitialValue,
  });

export const generateExportParams = ({
  fleet_id,
  date_from = format(new Date(), "yyyy-MM-dd"),
  date_to = format(new Date(), "yyyy-MM-dd"),
  payment_type,
  payment_status,
  model_ids,
  driver_ref,
}) => {
  const params = { fleet_id };
  if (model_ids.length) {
    params.model_ids = model_ids;
    return params;
  }

  return {
    fleet_id,
    date_from,
    date_to,
    payment_type:
      payment_type?.length === paymentTypeOptions?.length
        ? undefined
        : payment_type?.map((type) => type.toUpperCase()),
    payment_status:
      payment_status?.length === paymentStatusOptions?.length
        ? undefined
        : payment_status?.map((status) => status.toUpperCase()),
    driver_ref,
  };
};

const BookingsList = () => {
  const isMobile = useIsMobile();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1500px)" });

  // Initial state controllers
  const [urlParams] = useSearchParams();

  const lastDate = urlParams.get("lastDate");
  const firstDate = urlParams.get("firstDate");
  const driverId = urlParams.get("driverId");

  const { dateFilter: savedDateFilter } = useSelector(
    (state) => state.bookingFilters,
  );

  const initialDate = useMemo(() => {
    if (!lastDate && !firstDate) {
      return [new Date(savedDateFilter[0]), new Date(savedDateFilter[1])];
    }

    return [new Date(lastDate), new Date(firstDate)];
  }, [firstDate, lastDate, savedDateFilter]);

  const { currentCompanyId: id } = useQueryCompanies({
    redirectTo: RedirectToCompany.ADMIN,
  });
  const dispatch = useDispatch();

  // Component state
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [showTableLoader, setShowTableLoader] = useState(true);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  // Api state
  const resetApiState = useCallback(() => {
    dispatch(
      api.util.updateQueryData("getBookingsList", undefined, (bookings) => {
        bookings.data = [];
      }),
    );
  }, [dispatch]);
  const [params, setParams] = useState(null);
  const [data, setData] = useState();
  const [paymentFilters, setPaymentFilters] = useState(
    bookingListInitalPaymentFilterValue,
  );
  const [searchInputValue, setSearchValue] = useState(driverId || "");
  const [date, setDate] = useState(initialDate);

  const setStoredDate = useCallback(
    (dateFilter) => {
      dispatch(
        setBookingDateFilter([
          dateFilter[0].toString(),
          dateFilter[1].toString(),
        ]),
      );
    },
    [dispatch],
  );

  const handleDateChange = useCallback(
    (date) => {
      setDate(date);
      setStoredDate(date);
    },
    [setStoredDate],
  );

  // Api mutations and queries
  const [syncBooking] = api.endpoints.syncBooking.useMutation();
  const [exportMultipleBookings] =
    api.endpoints.exportMultipleBookings.useMutation();
  const [putPayoutStatus] = api.endpoints.putPayoutStatus.useMutation();
  const [putMultiplePayoutStatus] =
    api.endpoints.putPayoutStatusMultiple.useMutation();
  const {
    data: bookings,
    isLoading,
    isFetching,
    isError,
  } = api.endpoints.getBookingsList.useQuery(params, {
    skip: !params,
  });

  const isUninitialized = typeof bookings === "undefined";

  // Api results
  const bookingsList = bookings?.data || [];
  const areParamsPresent = lastDate && firstDate && driverId;
  const loading = isLoading || isFetching;
  const paginationInfo = useMemo(() => {
    const pagination = bookings?.meta;

    if (!pagination) {
      return;
    }

    setShowTableLoader(false);

    return {
      lastPage: pagination.last_page,
      currentPage: pagination.current_page,
      total: pagination?.total,
      nextPage: pagination?.next_cursor,
    };
  }, [bookings?.meta]);

  // Effects
  useLayoutEffect(() => {
    if (areParamsPresent && id) {
      setParams({
        ...initialParams(id),
        search: driverId,
      });
      handleDateChange([new Date(lastDate), new Date(firstDate)]);
    }
  }, [areParamsPresent, driverId, firstDate, id, lastDate, handleDateChange]);

  useEffect(() => {
    if (id && !areParamsPresent) {
      setParams(initialParams(id));
      const elementToScrollTo = document.getElementById(BOOKING_TABLE_ID);
      if (elementToScrollTo) {
        elementToScrollTo.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [areParamsPresent, id]);

  useEffect(() => {
    if (isError) {
      setShowTableLoader(false);
    }
  }, [isError]);

  const handleDrawer = () => {
    setVisibleDrawer((prevState) => !prevState);
  };

  const onTableScroll = useCallback((e) => {
    const tableWrapper = document.querySelector(
      `.${BOOKING_LIST_TABLE_WRAPPER}`,
    );
    const position = tableWrapper.getBoundingClientRect();
    if (position.y > 0) {
      setShowScrollToTop(false);
    } else setShowScrollToTop(true);
  }, []);

  const onSearch = useCallback(
    (e) => {
      if (e.target.value) {
        resetApiState();
        setShowTableLoader(true);
        handleDateChange([new Date(lastSevenDays), new Date()]);
        setParams((currentParams) => ({
          ...currentParams,
          search: e.target.value,
          page: 1,
        }));
      } else {
        resetApiState();
        setShowTableLoader(true);
        setParams(initialParams(id));
      }
    },
    [resetApiState, id, handleDateChange],
  );

  const onClearSearch = useCallback(
    (value) => {
      setSearchValue(value);
      if (!value) {
        resetApiState();
        setShowTableLoader(true);
        setParams(initialParams(id));
      }
    },
    [id, resetApiState],
  );

  const onRowSelect = (e, booking) => {
    if (e.target.checked === true) {
      setSelectedBookings((prevState) => [...prevState, booking.id]);
    } else {
      const filteredBookings = selectedBookings.filter((item) => {
        return item !== booking.id;
      });
      setSelectedBookings(filteredBookings);
    }
  };

  const getNextBookings = () => {
    setParams((prevParams) => {
      return {
        ...prevParams,
        cursor: paginationInfo.nextPage,
      };
    });
  };

  const onBookingApprove = useCallback(() => {
    if (selectedBookings.length === 0) {
      showWarningMsg({ content: "Select at least one booking" });
      return;
    }
    showLoadingMsg();
    const onlyOneSelected = selectedBookings.length === 1;

    if (onlyOneSelected) {
      putPayoutStatus({
        id: selectedBookings[0],
        payout_status: PAYOUT_STATUS.APPROVED,
        payout_status_reason: null,
      })
        .unwrap()
        .then(() => {
          showSuccessMsg({ content: "Approved successfully" });
          setParams(initialParams(id));
        })
        .catch(() => {
          showErrorMsg({ content: "Something went wrong" });
        });
    } else {
      putMultiplePayoutStatus({
        ids: selectedBookings,
        payout_status: PAYOUT_STATUS.APPROVED,
        payout_status_reason: null,
      })
        .unwrap()
        .then(() => {
          showInfoMsg({
            content: "Bookings are in queue. They will be updated soon.",
          });
        })
        .catch(() => {
          showErrorMsg({ content: "Something went wrong" });
        });
    }
  }, [id, putMultiplePayoutStatus, putPayoutStatus, selectedBookings]);

  const onDeclinement = useCallback(
    (reason) => {
      if (selectedBookings.length === 0) {
        showWarningMsg({ content: "Select at least one booking" });
        return;
      }
      showLoadingMsg();
      axiosInstance
        .put(
          `bookings${
            selectedBookings.length === 1 ? `/${selectedBookings[0]}` : ""
          }/payout-status`,
          {
            ...(selectedBookings.length > 1 && { ids: selectedBookings }),
            payout_status: "declined",
            payout_status_reason: reason,
          },
        )
        .then((res) => {
          setSelectedBookings([]);
          setVisibleDrawer(false);
          if (selectedBookings.length === 1) {
            showSuccessMsg({ content: "Declined successfully" });
            const updatedBookingId = res.data.data.id;
            const updatedList = data.map((booking) =>
              booking.id === updatedBookingId
                ? { ...booking, payout_status: "declined" }
                : booking,
            );
            setData(updatedList);
          } else {
            showInfoMsg({
              content: "Bookings are in queue. They will be updated soon.",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          showErrorMsg({ content: "Something went wrong" });
        });
    },
    [data, selectedBookings],
  );

  const onExport = useCallback(() => {
    const { date_from, date_to } = params;
    const parsedDateFrom = new Date(date_from);
    const parsedDateTo = new Date(date_to);
    const timeRange = differenceInCalendarDays(parsedDateTo, parsedDateFrom);

    if (timeRange > 90 && !selectedBookings.length) {
      showWarningMsg({
        content:
          "You can only export 90 days bookings timeframe at a time, please adjust the dates and try again",
      });
      return;
    }

    const { payment_type, payment_status } = paymentFilters;
    const model_ids = selectedBookings;
    const apiParams = generateExportParams({
      fleet_id: id,
      model_ids,
      payment_status,
      payment_type,
      date_from,
      date_to,
    });
    showLoadingMsg();

    exportMultipleBookings(apiParams)
      .unwrap()
      .then(() => {
        showSuccessMsg({ content: "Bookings successfuly exported" });
      })
      .catch(() => {
        showErrorMsg({ content: "Bookings couldn't export" });
      });
  }, [id, exportMultipleBookings, params, paymentFilters, selectedBookings]);

  const syncBookings = useCallback(
    (date) => {
      const [startDate, endDate] = date;
      syncBooking({
        id,
        params: {
          date_from: format(startDate, "yyyy-MM-dd"),
          date_to: format(endDate, "yyyy-MM-dd"),
        },
      })
        .unwrap()
        .then(() => {
          showSuccessMsg({ content: "Your request will update" });
        })
        .catch(() => {
          showErrorMsg({ content: "Something went wrong" });
        });
    },
    [id, syncBooking],
  );

  const onDateChange = useCallback(
    ([firstDate, lastDate]) => {
      const first = firstDate || new Date();
      const last = lastDate || new Date();
      setShowTableLoader(true);
      handleDateChange([first, last]);
      resetApiState();
      setParams((currentParams) => ({
        ...currentParams,
        date_from: format(first, "yyyy-MM-dd"),
        date_to: format(last, "yyyy-MM-dd"),
        page: 1,
      }));
    },
    [resetApiState, handleDateChange],
  );

  const onPaymentTypeFilterChange = useCallback(
    ({ entries }) => {
      resetApiState();
      setParams((currentParams) => ({
        ...currentParams,
        cursor: undefined,
        payment_type: entries,
      }));
    },
    [resetApiState],
  );

  const onPayoutStatusFilterChange = useCallback(
    ({ entries }) => {
      resetApiState();
      setParams((currentParams) => ({
        ...currentParams,
        cursor: undefined,
        payout_status: entries,
      }));
    },
    [resetApiState],
  );

  const onItemSelect = useCallback(
    (item) => {
      if (item === "approve") {
        onBookingApprove();
      }
      if (item === "on_hold") {
        handleDrawer();
      }
      if (item === "exportCsv") {
        onExport();
      }
      if (item === "syncBookings") {
        syncBookings(date);
      }
    },
    [date, onBookingApprove, onExport, syncBookings],
  );

  const pageHeader = useMemo(() => {
    return (
      <>
        <MenuToggle />
        <PageHeader title={"Transactions"} icon={<TransactionsIcon />} />
        {isTabletOrMobile && (
          <CustomDropdown
            options={bookingActionOptions}
            onItemSelect={onItemSelect}
          />
        )}
      </>
    );
  }, [isTabletOrMobile, onItemSelect]);

  return (
    <div className="bookings-wrapper">
      <Table
        data={bookingsList}
        loading={showTableLoader}
        id={id}
        getNextBookings={getNextBookings}
        hasMore={paginationInfo?.nextPage}
        onScroll={onTableScroll}
        onRowSelect={onRowSelect}
        selectedBookings={selectedBookings}
        topScrollId={BOOKING_TABLE_ID}
        apiLoading={loading}
        headerSection={
          <div className="actions-header-section">
            {isMobile ? (
              <div className="bookings-sticky-header">{pageHeader}</div>
            ) : (
              pageHeader
            )}
            {id && (
              <Filters
                onSearch={onSearch}
                onDateChange={onDateChange}
                setPaymentFilters={setPaymentFilters}
                searchInputValue={searchInputValue}
                onClearSearch={onClearSearch}
                isTabletOrMobile={isTabletOrMobile}
                onItemSelect={onItemSelect}
                date={date}
                setDate={handleDateChange}
                onPaymentTypeFilterChange={onPaymentTypeFilterChange}
                onPayoutStatusFilterChange={onPayoutStatusFilterChange}
                paymentTypeFilterValue={params?.payment_type}
                payoutStatusFilterValue={params?.payout_status}
              />
            )}
          </div>
        }
        isUninitialized={isUninitialized}
        params={params}
      />
      <div className="bookings-footer">
        <span>{loading && <Loader />}</span>
        {showScrollToTop && <ScrollTop scrollId={BOOKING_TABLE_ID} />}
      </div>
      <DeclinementDrawer
        visible={visibleDrawer}
        onChange={handleDrawer}
        onDeclinement={onDeclinement}
      />
    </div>
  );
};

export default BookingsList;
