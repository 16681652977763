import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Field } from "components/Field";
import { Button } from "components/Button";
import { UpdatePasswordModal } from "./UpdatePasswordModal";

export function PasswordSection() {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState();

  return (
    <Field label={t("field.password")}>
      <Button
        block
        uppercase
        variant="primary"
        shape="round"
        onClick={() => setModalVisible(true)}
      >
        {t("action.updatePassword")}
      </Button>

      <UpdatePasswordModal
        visible={modalVisible}
        onModalClose={() => setModalVisible(false)}
      />
    </Field>
  );
}
