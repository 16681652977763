import {
  startOfMonth,
  endOfMonth,
  format,
  subMonths,
  startOfYear,
} from "date-fns";

const dateFormat = "yyyy-MM-dd";
const currentMonthStart = startOfMonth(new Date());
const currentMonthEnd = endOfMonth(new Date());

export const dashboardPickerOptions = [
  {
    label: "This month",
    value: "thisMonth",
    dateRange: {
      date_from: format(currentMonthStart, dateFormat),
      date_to: format(currentMonthEnd, dateFormat),
    },
  },
  {
    label: "Last month",
    value: "lastMonth",
    dateRange: {
      date_from: format(subMonths(currentMonthStart, 1), dateFormat),
      date_to: format(subMonths(currentMonthEnd, 1), dateFormat),
    },
  },
  {
    label: "Last 3 months",
    value: "lastThreeMonths",
    dateRange: {
      date_from: format(subMonths(currentMonthStart, 3), dateFormat),
      date_to: format(subMonths(currentMonthEnd, 1), dateFormat),
    },
  },
  {
    label: "This year",
    value: "thisYear",
    dateRange: {
      date_from: format(startOfYear(new Date()), dateFormat),
      date_to: format(new Date(), dateFormat),
    },
  },
];
