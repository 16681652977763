export function calculateDriverCommissionValue(
  total,
  driverCommission,
  flag = false,
) {
  const driverCommissionDividedBy100 = driverCommission
    ? driverCommission / 100
    : 0;
  const commissionValue = flag ? driverCommissionDividedBy100 : 0;

  return (total * commissionValue) / 100;
}
