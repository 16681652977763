import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Checkbox } from "antd";
import clsx from "clsx";

import { Button } from "components/Button";
import { DoubleDropdownMobile } from "./DoubleDropdownMobile";

import { ReactComponent as DropdownIcon } from "assets/icons/dropdown-icon.svg";
import { ReactComponent as ArrowDownIcon } from "assets/icons/Sidebar/arrowDown.svg";
import styles from "./DoubleDropdown.module.scss";

export const DoubleDropdown = ({
  firstOptions,
  secondOptions,

  firstOptionValues,
  secondOptionValues,

  firstOptionInitialValue = [],
  secondOptionInitialValue = [],

  firstOptionHeader,
  secondOptionHeader,

  onFirstOptionChange,
  onSecondOptionChange,

  wrapperClassName,
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState({
    first: firstOptionInitialValue,
    second: secondOptionInitialValue,
  });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const isFirstCheckAll = values.first.length === firstOptions.options.length;
  const isSecondCheckAll =
    values.second.length === secondOptions.options.length;

  const handleSelect = useCallback(
    (e, { value }, isFirst) => {
      const { checked } = e.target;
      const isCheckAll = isFirst ? isFirstCheckAll : isSecondCheckAll;
      const propertyToUpdate = isFirst ? "first" : "second";
      const updatedValues = [
        ...(isCheckAll ? [] : values[propertyToUpdate]),
        value,
      ];
      if (checked) {
        setValues((curr) => ({
          ...curr,
          [propertyToUpdate]: updatedValues,
        }));
        isFirst
          ? onFirstOptionChange(updatedValues)
          : onSecondOptionChange(updatedValues);
      } else {
        const newValue = values[propertyToUpdate].filter(
          (item) => item !== value,
        );
        setValues((curr) => ({
          ...curr,
          [propertyToUpdate]: newValue,
        }));
        isFirst
          ? onFirstOptionChange(newValue)
          : onSecondOptionChange(newValue);
      }
    },
    [
      isFirstCheckAll,
      isSecondCheckAll,
      onFirstOptionChange,
      onSecondOptionChange,
      values,
    ],
  );

  const handleSelectAll = useCallback(
    (isFirst) => {
      const propertyToUpdate = isFirst ? "first" : "second";
      const valueToUpdate = isFirst
        ? firstOptions.options
        : secondOptions.options;
      const condition = isFirst ? isFirstCheckAll : isSecondCheckAll;
      const newValue = condition ? [] : valueToUpdate.map(({ value }) => value);
      setValues((curr) => ({
        ...curr,
        [propertyToUpdate]: newValue,
      }));
      isFirst ? onFirstOptionChange(newValue) : onSecondOptionChange(newValue);
    },
    [
      firstOptions.options,
      isFirstCheckAll,
      isSecondCheckAll,
      onFirstOptionChange,
      onSecondOptionChange,
      secondOptions.options,
    ],
  );

  const handleToggleDropdown = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  useEffect(() => {
    if (firstOptionValues) {
      setValues((currentValues) => ({
        ...currentValues,
        first: firstOptionValues,
      }));
    }
    if (secondOptionValues) {
      setValues((currentValues) => ({
        ...currentValues,
        second: secondOptionValues,
      }));
    }
  }, [firstOptionValues, secondOptionValues]);

  const renderOptionCheckbox = useCallback(
    (option, isFirst) => {
      const property = isFirst ? "first" : "second";

      if (!isMobile) {
        return (
          <div key={option.value}>
            <Checkbox
              checked={values?.[property]?.includes(option.value)}
              onChange={(event) => handleSelect(event, option, isFirst)}
            />
            <span>{option.label}</span>
          </div>
        );
      } else {
        return (
          <Checkbox
            key={option.value}
            checked={values?.[property]?.includes(option.value)}
            onChange={(event) => handleSelect(event, option, isFirst)}
          >
            {option.label}
          </Checkbox>
        );
      }
    },
    [handleSelect, isMobile, values],
  );

  const renderSelectAllCheckbox = useCallback(
    (isFirst) => {
      if (!isMobile) {
        return (
          <div onClick={() => handleSelectAll(isFirst)}>
            <Checkbox checked={isFirst ? isFirstCheckAll : isSecondCheckAll} />
            <span>{t("action.showAll")}</span>
          </div>
        );
      } else {
        return (
          <Checkbox
            checked={isFirst ? isFirstCheckAll : isSecondCheckAll}
            onChange={() => handleSelectAll(true)}
          >
            {t("action.showAll")}
          </Checkbox>
        );
      }
    },
    [handleSelectAll, isFirstCheckAll, isMobile, isSecondCheckAll, t],
  );

  const options = useMemo(() => {
    return (
      <div className={styles.options}>
        <div className={styles.optionName}>{firstOptions.name}</div>
        <div className={styles.list}>
          {renderSelectAllCheckbox(true)}
          {firstOptions.options.map((option) =>
            renderOptionCheckbox(option, true),
          )}
        </div>
        {isMobile && <div className={styles.divider} />}
        <div className={styles.optionName}>{secondOptions.name}</div>
        <div className={styles.list}>
          {renderSelectAllCheckbox(false)}
          {secondOptions.options.map((option) =>
            renderOptionCheckbox(option, false),
          )}
        </div>
        {isMobile && (
          <div className="mobile-filter-dropdown__button-wrapper">
            <Button
              block
              variant="primary"
              shape="round"
              onClick={handleToggleDropdown}
            >
              {t("action.filter")}
            </Button>
          </div>
        )}
      </div>
    );
  }, [
    firstOptions.name,
    isMobile,
    firstOptions.options,
    renderOptionCheckbox,
    secondOptions.name,
    secondOptions.options,
    renderSelectAllCheckbox,
    t,
  ]);

  return (
    <div
      className={clsx(styles.wrapper, wrapperClassName, {
        [styles.wrapperOpen]: isOpen,
      })}
    >
      <div className={styles.dropdownHeader} onClick={handleToggleDropdown}>
        <DropdownIcon />

        <div className={styles.labelContainer}>
          <span className={clsx(styles.labelItem, styles.bold)}>
            {firstOptionHeader}
          </span>
          <span className={styles.labelSeparator} />
          <span className={styles.labelItem}>{secondOptionHeader}</span>
        </div>

        <ArrowDownIcon />
      </div>

      {!isMobile && isOpen ? options : null}

      {isMobile ? (
        <DoubleDropdownMobile
          options={options}
          open={isOpen}
          handleTypeDropdown={handleToggleDropdown}
        />
      ) : null}
    </div>
  );
};
