import { ReactComponent as Edit } from "../../../../assets/icons/editIcon.svg";

export const headerItems = [
  "company name",
  "stripe enabled",
  "driver pay enabled",
  "number of users",
  "number of drivers",
  "",
];

export const options = [
  {
    id: 0,
    value: "edit",
    label: "Edit",
    icon: Edit,
  },
];
