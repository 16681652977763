import { ReactComponent as ExportIcon } from "../../../assets/icons/export.svg";

export const individualReportOptions = [
  {
    id: 0,
    value: "export",
    label: "Export",
    icon: ExportIcon,
  },
];

export const reportOptions = [
  individualReportOptions[0],
  {
    id: 1,
    value: "export_dispatch",
    label: "Export for Dispatch",
    icon: ExportIcon,
  },
];
