import { Grid } from "antd";
import { RedirectToCompany, useQueryCompanies } from "hooks/useQueryCompanies";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as IcabbiIcon } from "../../assets/icons/icabbiIcon.svg";
import { ReactComponent as NavBurgerIcon } from "../../assets/icons/Sidebar/nav_burger.svg";
import { ReactComponent as NavBurgerOpenIcon } from "../../assets/icons/Sidebar/nav_burger_open.svg";

import Sidebar from "../../assets/icons/Sidebar";
import "./CompanySelector.scss";
import { useCallback } from "react";
import { useWindowSize } from "hooks/useWindowSize";
import { CompanySelectorDesktopDrawer } from "./CompanySelectorDesktopDrawer";
import { CompanySelectorMobileDrawer } from "./CompanySelectorMobileDrawer";
const { useBreakpoint } = Grid;

const CompanySelector = ({
  isWallet = false,
  onMobilePickerOpen,
  isDrawerClosed = false,
  onCollapse,
}) => {
  const [open, setOpen] = useState(false);

  const {
    currentCompanyId,
    driverCompaniesById,
    adminCompaniesById,
    setCurrentCompanyId: handleChangeSelectedCompany,
    driverCompanies,
    adminCompanies,
  } = useQueryCompanies({
    redirectTo: isWallet ? RedirectToCompany.DRIVER : RedirectToCompany.ADMIN,
  });

  const allFleets = isWallet ? driverCompanies : adminCompanies;

  const selectedCompany = useMemo(() => {
    return (
      driverCompaniesById[currentCompanyId] ??
      adminCompaniesById[currentCompanyId]
    );
  }, [driverCompaniesById, currentCompanyId, adminCompaniesById]);

  useWindowSize([open]);
  const screens = useBreakpoint();
  const xs = screens.xs;

  useEffect(() => {
    onMobilePickerOpen?.(open);
  }, [onMobilePickerOpen, open]);

  const toggle = () => setOpen((prevState) => !prevState);

  const [search, setSearch] = useState("");
  const onSearch = (event) => {
    setSearch(event.target.value);
  };

  const filteredCompanies = useMemo(() => {
    if (search.length === 0) {
      return allFleets;
    }

    return allFleets.filter((company) => {
      return company.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [search, allFleets]);

  const handleCollapse = useCallback(
    (event) => {
      event.stopPropagation();
      onCollapse?.();
    },
    [onCollapse],
  );

  const navIcon = useMemo(() => {
    if (isWallet) {
      return <IcabbiIcon />;
    }

    if (isDrawerClosed) {
      return <NavBurgerIcon onClick={handleCollapse} />;
    } else {
      return <NavBurgerOpenIcon onClick={handleCollapse} />;
    }
  }, [handleCollapse, isDrawerClosed, isWallet]);

  return (
    <div className="company-selector">
      <div className="menu-trigger" onClick={toggle}>
        <span className="top-icon">
          {navIcon}
          <h3 className="selected-company-name">{selectedCompany?.name}</h3>
        </span>
        <Sidebar.ArrowDown />
      </div>

      {xs ? (
        <CompanySelectorMobileDrawer
          filteredCompanies={filteredCompanies}
          onSubmit={handleChangeSelectedCompany}
          open={open}
          selectedCompany={selectedCompany}
          setOpen={setOpen}
        />
      ) : (
        <CompanySelectorDesktopDrawer
          filteredCompanies={filteredCompanies}
          onSearch={onSearch}
          onSubmit={handleChangeSelectedCompany}
          open={open}
          selectedCompany={selectedCompany}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

export default CompanySelector;
