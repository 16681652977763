import { allowDrivers } from "hooks/useRoles";

import { AuthRequired } from "components/AuthRequired";
import { RolesRequired } from "components/RolesRequired";
import { StripeCompletedPage } from "./StripeCompletedPage";

export function StripeCompletedPageWithGates() {
  return (
    <AuthRequired>
      <RolesRequired allowByRoles={allowDrivers}>
        <StripeCompletedPage />
      </RolesRequired>
    </AuthRequired>
  );
}
