import { LoadingComp } from "components/LoadingComp";
import { MenuToggle } from "components/MenuToggle/MenuToggle";
import { RedirectToCompany, useQueryCompanies } from "hooks/useQueryCompanies";
import { api } from "api";
import { Banner } from "components/Banner";
import PayoutFrequency from "./PaymentFrequency/PayoutFrequency";
import "./Payments.scss";

export const Payments = () => {
  const { currentCompany: selectedCompany } = useQueryCompanies({
    redirectTo: RedirectToCompany.ADMIN,
  });

  const {
    data: company,
    isLoading,
    isFetching,
  } = api.endpoints.getFleetById.useQuery(
    { id: selectedCompany?.id },
    {
      skip: Boolean(!selectedCompany),
    },
  );

  if (!company || isLoading || isFetching) {
    return <LoadingComp loading />;
  }

  return (
    <div className="payments-wrapper">
      <div className="header">
        <MenuToggle />
        <h1>Payout settings</h1>
      </div>
      <div className="content">
        <h1>Company level settings</h1>
        <h2>Payouts</h2>
        <PayoutFrequency selectedCompany={company.data} />
        <Banner
          headingText="Contact"
          headingLevel="h2"
          description="To report any issues or request further information, contact us at:"
        />
        <span className="contact-links">
          <Banner
            headingText="Email"
            headingLevel="h3"
            description={selectedCompany?.email || "No email"}
          />
          <Banner
            headingText="Phone number"
            headingLevel="h3"
            description={selectedCompany?.phone_number || "No phone number"}
          />
        </span>
      </div>
    </div>
  );
};

export default Payments;
