import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function NotFoundErrorPage() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <p>{t("pageErrorNotFound.pageNotFound")}</p>
      <p>
        <Link to="/">{t("pageErrorNotFound.goToMainPage")}</Link>
      </p>
    </div>
  );
}
