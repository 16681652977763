import { useEffect, useMemo, useRef } from "react";

import { useWindowSize } from "hooks/useWindowSize";
import { StateHandler } from "components/LoadingComp";

import { GroupedBookings } from "../GroupedBookings/GroupedBookings";
import { DRIVER_PROFILE_ID_QUERY_PARAM } from "../constants";

import { getBookingGroups } from "./groupedBookingsByDate";

export const BookingsList = ({
  id,
  getNextBookings,
  bookings,
  isLoading,
  pagination,
  isUninitialized,
}) => {
  const scrollComponent = useRef(null);
  useWindowSize();

  const fetchNextBookings = () => {
    if (pagination?.next_cursor && !isLoading && bookingsList?.length) {
      getNextBookings();
    }
  };

  const bookingsList = useMemo(() => {
    const list = bookings?.data || [];

    return list.map((booking) => ({
      ...booking,
      [DRIVER_PROFILE_ID_QUERY_PARAM]: id,
    }));
  }, [bookings?.data, id]);

  const groups = getBookingGroups(bookingsList);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        fetchNextBookings();
      }
    });

    if (scrollComponent.current) {
      observer.observe(scrollComponent.current);
    }

    return () => {
      if (scrollComponent.current) {
        observer.unobserve(scrollComponent.current);
      }
    };
  }, [fetchNextBookings, scrollComponent.current]);

  return (
    <>
      <div className="bookings-list-wrapper">
        <StateHandler
          companySelected={!id}
          showLoader={isLoading && isUninitialized}
          showEmpty={!bookingsList?.length && !isLoading}
          emptyTitle="bookings"
        >
          {groups.map((group) => (
            <GroupedBookings key={group.title} group={group} />
          ))}
        </StateHandler>
      </div>
      <div ref={scrollComponent} />
    </>
  );
};
