import React from "react";
import styles from "./SingleScreen.module.scss";
import clsx from "clsx";

export const SingleScreen = ({ children, wrapperClassName, fullHeight }) => {
  return (
    <div
      className={clsx(styles.documentScreenWrapper, wrapperClassName, {
        [styles.fullHeight]: fullHeight,
      })}
    >
      {children}
    </div>
  );
};
