import { useCallback } from "react";
import { api } from "api";

export function useOpenFleetView() {
  const [fetchToken, { isLoading }] = api.endpoints.getToken.useLazyQuery();

  const open = useCallback(
    (event) => {
      event.preventDefault();

      fetchToken()
        .unwrap()
        .then((result) => {
          window.open(result.url, "_blank", "noreferrer");
        });
    },
    [fetchToken],
  );

  return { open, isLoading };
}
