import { useOpenFleetView } from "pages/auth/useOpenFleetView";

import { NavLinkInternal } from "../NavLinkInternal";
import { LoadingIcon } from "../LoadingIcon/LoadingIcon";

export function NavLinkFleetView(props) {
  const { open: openFleetView, isLoading } = useOpenFleetView();

  return (
    <NavLinkInternal
      {...props}
      icon={
        isLoading ? (
          <LoadingIcon style={{ width: 24, height: 24 }} />
        ) : (
          props.icon
        )
      }
      onClick={openFleetView}
    />
  );
}
