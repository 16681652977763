import { Link } from "react-router-dom";
import { ReactComponent as BackArrow } from "assets/icons/backArrow.svg";
import "./SubTopNavigator.scss";

export const SubTopNavigator = ({ pathName, subject }) => {
  return (
    <div className="sub-top-wrapper">
      <Link to={`/${pathName}`}>
        <BackArrow />
      </Link>
      <h1>{subject}</h1>
    </div>
  );
};
