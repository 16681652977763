import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "components/Form";
import { Field } from "components/Field";
import { emailRegex, Input } from "components/Input";
import { Button } from "components/Button";

export function ResetPasswordForm({ initialValues, onSubmit }) {
  const { t } = useTranslation();

  const renderForm = useCallback(
    ({ values, errors, onChange }) => {
      return (
        <>
          <Field label={t("field.email")} htmlFor="email" error={errors.email}>
            <Input
              name="email"
              type="email"
              value={values.email}
              invalid={typeof errors.email === "string"}
              onChange={onChange}
            />
          </Field>

          <Field>
            <Button block variant="primary" shape="round" type="submit">
              {t("action.reset")}
            </Button>
          </Field>
        </>
      );
    },
    [t],
  );

  const validationRules = useMemo(() => {
    return [
      {
        field: "email",
        isValid: (value) => typeof value === "string" && value.length > 0,
        error: t("error.emailRequired"),
      },
      {
        field: "email",
        isValid: (value) => emailRegex.test(value),
        error: t("error.emailInvalid"),
      },
    ];
  }, [t]);

  return (
    <Form
      initialValues={initialValues}
      validationRules={validationRules}
      onSubmit={onSubmit}
      noValidate
    >
      {renderForm}
    </Form>
  );
}
