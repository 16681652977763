import { useEffect, useMemo } from "react";
import { NotificationGroup } from "./components/NotificationGroup/NotificationGroup";
import { groupedNotificationsByDate } from "./helpers";
import styles from "./NotificationsPage.module.scss";
import { api } from "api";

export const NotificationsPage = () => {
  const { data: notificationsData, isSuccess: isGetNotificationsSuccess } =
    api.endpoints.getNotifications.useQuery();
  const [
    readAllNotifications,
    { isUninitialized: isReadAllNotificationUninitialized },
  ] = api.endpoints.readAllNotifications.useMutation();

  useEffect(() => {
    if (isGetNotificationsSuccess && isReadAllNotificationUninitialized) {
      readAllNotifications();
    }
  }, [
    isGetNotificationsSuccess,
    isReadAllNotificationUninitialized,
    readAllNotifications,
  ]);

  const groupedNotifications = useMemo(() => {
    return groupedNotificationsByDate(notificationsData?.data || []);
  }, [notificationsData?.data]);

  return (
    <div className={styles.notificationsWrapper}>
      {groupedNotifications.map((notificationGroup) => (
        <NotificationGroup
          key={notificationGroup[0]}
          notificationGroup={notificationGroup}
        />
      ))}
    </div>
  );
};
