import { useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { api } from "api";
import { routes } from "routes";

import { useEffectOnce } from "hooks/useEffectOnce";
import { showErrorMsg, showSuccessMsg } from "helpers/windowMassages";

import { LoadingComp } from "components/LoadingComp";
import { NotFoundErrorPage } from "../NotFoundErrorPage";

export function AuthWithTokenPage() {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [authWithToken, { isError }] =
    api.endpoints.authWithToken.useMutation();

  useEffectOnce(() => {
    authWithToken({ token: searchParams.get("token") })
      .unwrap()
      .then(
        (response) => {
          showSuccessMsg({ content: t("success.signedIn") });
          localStorage.setItem(
            "auth_token",
            JSON.stringify(response.meta.token),
          );
          navigate(routes.walletHome.getURL(), { replace: true });
        },
        (error) => {
          showErrorMsg({
            content: error?.data?.message ?? t("error.somethingWentWrong"),
          });
        },
      );
  });

  if (isError) {
    return <NotFoundErrorPage />;
  }

  return <LoadingComp loading fullScreen />;
}
