export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      getCustomDocumentTypes: build.query({
        query: ({ fleet_id }) => ({
          url: "custom-document-types",
          params: { fleet_id },
        }),
      }),
    }),
  });
}
