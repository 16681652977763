import { forwardRef } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { ReactComponent as LoadingIcon } from "./loader.svg";

import styles from "./Button.module.scss";

export const Button = forwardRef(function ButtonInternal(
  {
    type = "button",
    variant = "default",
    fontWeight = "normal",
    fontSize = "md",
    loading = false,
    uppercase,
    nowrap,
    shape,
    block,
    importance,
    hideSpinner,
    unsetLetterSpacing,
    children,
    external,
    ...otherProps
  },
  ref,
) {
  /**
   * Classnames are defined inside the component.
   * Use style-specific props to change button's appearance.
   *
   * @type {string}
   */
  const className = clsx(
    styles.button,
    getVariant(variant),
    getShape(shape),
    getImportance(importance),
    getFontWeight(fontWeight),
    getFontSize(fontSize),
    otherProps.disabled ? styles.disabled : styles.enabled,
    {
      [styles.displayBlock]: block,
      [styles.uppercase]: uppercase,
      [styles.nowrap]: nowrap,
      [styles.unsetLetterSpacing]: unsetLetterSpacing,
    },
  );

  if (typeof otherProps.to !== "string") {
    return (
      <button type={type} {...otherProps} className={className} ref={ref}>
        {!hideSpinner ? (
          <span className={clsx(styles.loaderIconWrapper)}>
            <LoadingIcon
              className={clsx(styles.loaderIcon, { [styles.visible]: loading })}
            />
          </span>
        ) : null}
        {children}
      </button>
    );
  }

  if (external) {
    const { to, otherPropsWithoutTo } = otherProps;

    return (
      <a {...otherPropsWithoutTo} href={to} className={className} ref={ref}>
        <span className={clsx(styles.loaderIconWrapper)}>
          <LoadingIcon
            className={clsx(styles.loaderIcon, { [styles.visible]: loading })}
          />
        </span>
        {children}
      </a>
    );
  }

  return (
    <Link {...otherProps} className={className} ref={ref}>
      <span className={clsx(styles.loaderIconWrapper)}>
        <LoadingIcon
          className={clsx(styles.loaderIcon, { [styles.visible]: loading })}
        />
      </span>
      {children}
    </Link>
  );
});

function getFontSize(fontSize) {
  const fontSizeOptions = {
    sm: styles.fontSm,
    md: styles.fontMd,
    lg: styles.fontL,
  };

  if (!fontSizeOptions.hasOwnProperty(fontSize)) {
    console.warn(
      `<Button>'s font size options may be "sm", "md" or "lg", you have provided "${fontSize}". "md" is used as a fallback.`,
    );
    return fontSizeOptions.md;
  }

  return fontSizeOptions[fontSize];
}

function getFontWeight(weight) {
  const weightOptions = {
    light: styles.fontLight,
    normal: styles.fontNormal,
    bold: styles.fontBold,
  };

  if (!weightOptions.hasOwnProperty(weight)) {
    console.warn(
      `<Button>'s font weight options may be "light", "normal" or "bold", you have provided "${weight}". "normal" is used as a fallback.`,
    );
    return weightOptions.normal;
  }

  return weightOptions[weight];
}

function getVariant(variant) {
  const variantOptions = {
    primary: styles.primary,
    icon: styles.icon,
    default: styles.default,
    link: styles.link,
  };

  if (!variantOptions.hasOwnProperty(variant)) {
    console.warn(
      `<Button>'s variant may be "primary", "icon", "link" or "default", you have provided "${variant}". "default" is used as a fallback.`,
    );
    return variantOptions.default;
  }

  return variantOptions[variant];
}

function getShape(shape) {
  const shapeOptions = {
    round: styles.shapeRound,
    rounded: styles.shapeRounded,
    undefined: null,
  };

  if (!shapeOptions.hasOwnProperty(shape)) {
    console.warn(
      `<Button>'s shape may be "round", "rounded" or undefined for square shape, you have provided "${shape}". No shape (undefined) is used as a fallback.`,
    );
    return shapeOptions.undefined;
  }

  return shapeOptions[shape];
}

function getImportance(importance) {
  const importanceOptions = {
    error: styles.error,
    warning: styles.warning,
    undefined: null,
  };

  if (!importanceOptions.hasOwnProperty(importance)) {
    console.warn(
      `<Button>'s importance may be "error", "warning" or undefined for success importance, you have provided "${importance}". Success importance (undefined) is used as a fallback.`,
    );
    return importanceOptions.undefined;
  }

  return importanceOptions[importance];
}
