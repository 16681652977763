const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const monthDaysNumbers = [...Array(28).keys()].map((i) => i + 1);

export const paymentFrequencyTypes = {
  Daily: [],
  Weekly: days,
  Monthly: monthDaysNumbers,
};
