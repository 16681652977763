export const PAYMENT_METHOD = Object.freeze({
  CARD: "CARD",
  INVOICE: "INVOICE",
  CASH: "CASH",
});

export const PAYMENT_STATUS = Object.freeze({
  PENDING: "pending",
  PAID: "paid",
  CHARGEBACK: "chargeback",
  REFUND: "refund",
});

export const PAYOUT_STATUS = Object.freeze({
  APPROVED: "approved",
  PENDING: "pending",
  DECLINED: "declined",
  PAID: "paid",
});
