import clsx from "clsx";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useSelectedFleet } from "hooks/useSelectedFleet";
import useRoles from "hooks/useRoles";
import { useIsMobile } from "hooks/useMobile";

import { setMenuCollapsed } from "store/slices/menuSlice";

import { routes } from "../../routes";

import { NavLinkInternal } from "../NavLinkInternal";

import { NavLinkDocuments } from "./NavLinkDocuments";
import { NavLinkNotifications } from "./NavLinkNotifications";
import { NavLinkFleetView } from "./NavLinkFleetView";

import { ReactComponent as HomeIcon } from "assets/icons/Wallet/home.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/Wallet/profile.svg";
import { ReactComponent as DocumentsIcon } from "assets/icons/Wallet/documents.svg";
import { ReactComponent as BookingsIcon } from "assets/icons/Wallet/bookings.svg";
import { ReactComponent as NotificationIcon } from "assets/icons/Wallet/notification.svg";
import { ReactComponent as ContactIcon } from "assets/icons/Wallet/contact.svg";
import { ReactComponent as SwitchIcon } from "assets/icons/Sidebar/switch.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/Sidebar/logout.svg";

import styles from "./Navigation.module.scss";

export function DriverNavigation({ collapsed, onLogoutClick }) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const { isSuperAdmin, isFleet } = useRoles();

  const { selectedFleet } = useSelectedFleet();
  const isDriverPayEnabled = Boolean(selectedFleet?.enable_driver_pay);
  const areDocumentsEnabled = Boolean(selectedFleet?.enable_documents_feature);

  const collapseMenuIfMobile = useCallback(() => {
    if (isMobile) {
      dispatch(setMenuCollapsed(true));
    }
  }, [dispatch, isMobile]);

  return (
    <nav>
      <ul
        className={clsx(styles.navigationList, {
          [styles.collapsed]: collapsed,
        })}
      >
        {isDriverPayEnabled ? (
          <li>
            <NavLinkInternal
              to={routes.walletHome.getURL()}
              icon={<HomeIcon />}
              collapsed={collapsed}
              onClick={collapseMenuIfMobile}
              end
            >
              {t("navigation.home")}
            </NavLinkInternal>
          </li>
        ) : null}

        <li>
          <NavLinkInternal
            to={routes.profilePage.getURL()}
            icon={<ProfileIcon />}
            collapsed={collapsed}
            onClick={collapseMenuIfMobile}
            end
          >
            {t("navigation.profile")}
          </NavLinkInternal>
        </li>

        {areDocumentsEnabled ? (
          <li>
            <NavLinkDocuments
              to={routes.documents.getURL()}
              icon={<DocumentsIcon />}
              collapsed={collapsed}
              onClick={collapseMenuIfMobile}
              end
            >
              {t("navigation.documents")}
            </NavLinkDocuments>
          </li>
        ) : null}

        {isDriverPayEnabled ? (
          <li>
            <NavLinkInternal
              to={routes.walletBookings.getURL()}
              icon={<BookingsIcon />}
              collapsed={collapsed}
              onClick={collapseMenuIfMobile}
            >
              {t("navigation.bookings")}
            </NavLinkInternal>
          </li>
        ) : null}

        <li>
          <NavLinkNotifications
            to={routes.notificationsPage.getURL()}
            icon={<NotificationIcon />}
            collapsed={collapsed}
            onClick={collapseMenuIfMobile}
          >
            {t("navigation.notifications")}
          </NavLinkNotifications>
        </li>
        <li>
          <NavLinkInternal
            to={routes.faqsPage.getURL()}
            icon={<ContactIcon />}
            collapsed={collapsed}
            onClick={collapseMenuIfMobile}
          >
            {t("navigation.faqs")}
          </NavLinkInternal>
        </li>

        {isFleet || isSuperAdmin ? (
          <li>
            <NavLinkFleetView
              to="/fleet-view"
              icon={<SwitchIcon />}
              collapsed={collapsed}
              onClick={collapseMenuIfMobile}
            >
              {t("navigation.fleetView")}
            </NavLinkFleetView>
          </li>
        ) : null}

        <li>
          <NavLinkInternal
            to="/sign-out"
            onClick={(event) => {
              event.preventDefault();
              collapseMenuIfMobile();
              onLogoutClick();
            }}
            icon={<LogoutIcon />}
            collapsed={collapsed}
          >
            {t("navigation.signOut")}
          </NavLinkInternal>
        </li>
      </ul>
    </nav>
  );
}
