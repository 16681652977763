import { api } from "api";
import { isFeatureEnabled } from "featureFlags";

import {
  usePredefinedDriverDocumentTypes,
  usePredefinedVehicleDocumentTypes,
} from "./constants";
import { useQueryPredefinedDocumentTypes } from "./DocumentTypeDetails";

import { getCommonData } from "./getCommonData";
export function useQueryDocumentTypes() {
  const predefinedDocumentTypes = useQueryPredefinedDocumentTypes();
  return {
    data: Object.values(predefinedDocumentTypes),
    isLoading: false,
  };
}

const noDocuments = [];
export function useQueryDriverCustomDocumentTypes(id) {
  const predefinedDriverDocumentTypes = usePredefinedDriverDocumentTypes();
  const predefinedVehicleDocumentTypes = usePredefinedVehicleDocumentTypes();
  const { data: documentTypes, isLoading: isMockedDocumentTypesLoading } =
    useQueryDocumentTypes();

  const { data, isLoading: areCustomDocumentTypesLoading } =
    api.endpoints.getDriverCustomDocumentTypes.useQuery(id);
  const customDocumentTypes = data?.data ?? noDocuments;

  return getCommonData({
    customDocumentTypes,
    documentTypes,
    isLoading: isMockedDocumentTypesLoading || areCustomDocumentTypesLoading,
    predefinedDriverDocumentTypes,
    predefinedVehicleDocumentTypes,
  });
}

export function useQueryCustomDocumentTypes({ currentCompanyId }) {
  const predefinedDriverDocumentTypes = usePredefinedDriverDocumentTypes();
  const predefinedVehicleDocumentTypes = usePredefinedVehicleDocumentTypes();

  const { data: documentTypes, isLoading: isMockedDocumentTypesLoading } =
    useQueryDocumentTypes();

  const { data, isLoading: areCustomDocumentTypesLoading } =
    api.endpoints.getCustomDocumentTypes.useQuery(
      {
        fleet_id: currentCompanyId,
      },
      {
        skip:
          !isFeatureEnabled.CUSTOM_DOCUMENTS ||
          currentCompanyId === undefined ||
          currentCompanyId === null,
      },
    );
  const customDocumentTypes = data?.data ?? noDocuments;

  return getCommonData({
    isLoading: isMockedDocumentTypesLoading || areCustomDocumentTypesLoading,
    documentTypes,
    customDocumentTypes,
    predefinedDriverDocumentTypes,
    predefinedVehicleDocumentTypes,
  });
}
