const NUMBER_BY_DAY = Object.freeze({
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
});

const DAYS_PER_WEEK = 7;

/**
 *
 * @param {"sunday" | "monday" | "tuesday" | "wednesday" | "thurthday" | "friday" | "saturday"} day - lowercase string day name
 * @returns {number}
 */
export function getDayNumberByDayString(day) {
  return NUMBER_BY_DAY[day];
}

export function getDayDifference(first, second) {
  if (first === second) {
    return 0;
  }

  if (first > second) {
    return DAYS_PER_WEEK - getDayDifference(second, first);
  }

  return second - first;
}
