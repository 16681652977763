import { differenceInCalendarDays } from "date-fns";
import { DocumentStatus } from "./DocumentStatus";

export function getDocumentStatusByDate(date) {
  /**
   * When no date is provided, assume the document has no expiration date;
   * hence, assume the document is up-to-date.
   */
  if (!date) {
    return DocumentStatus.UP_TO_DATE;
  }

  const today = new Date();
  const expirationDay = new Date(date);
  const daysUntilExpiration = differenceInCalendarDays(expirationDay, today);

  if (daysUntilExpiration <= 7) {
    return DocumentStatus.EXPIRED;
  }

  if (daysUntilExpiration > 7 && daysUntilExpiration < 30) {
    return DocumentStatus.EXPIRING;
  }

  return DocumentStatus.UP_TO_DATE;
}
