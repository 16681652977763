import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLogout } from "hooks/useLogout";

export function SomethingWentWrongErrorPage() {
  const { t } = useTranslation();
  const logOut = useLogout();

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <p>{t("error.somethingWentWrong")}</p>
      <p>
        <Link
          to="/sign-out"
          onClick={(event) => {
            event.preventDefault();
            logOut();
          }}
        >
          {t("action.signOut")}
        </Link>
      </p>
    </div>
  );
}
