import { useEffect, useState } from "react";
import { api } from "api";

import { ModalLatestUserAgreementVersionNotAccepted } from "./ModalLatestUserAgreementVersionNotAccepted";

export function AcceptedLatestUserAgreementVersionRequired({ children }) {
  const [open, setOpen] = useState(false);

  const { data, isLoading } =
    api.endpoints.checkLatestUserAgreementVersion.useQuery();
  const { accepted } = data ?? {};

  useEffect(() => {
    if (isLoading || typeof accepted === "undefined") {
      return;
    }

    const shouldBeOpen = !accepted && !open;
    if (shouldBeOpen) {
      setOpen(true);
    }

    const shouldNotBeOpen = accepted && open;
    if (shouldNotBeOpen) {
      setOpen(false);
    }
  }, [isLoading, accepted, open, setOpen]);

  const [acceptLatestUserAgreementVersion] =
    api.endpoints.acceptLatestUserAgreementVersion.useMutation();

  return (
    <>
      <ModalLatestUserAgreementVersionNotAccepted
        open={open}
        onSubmit={acceptLatestUserAgreementVersion}
      />
      {children}
    </>
  );
}
