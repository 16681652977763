import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { api } from "api";

import { useAuth } from "hooks/useAuth";
import { LoadingComp } from "components/LoadingComp";

import { getLatestDocumentByStatusByType } from "../getLatestDocumentByStatusByType";
import { DocumentType } from "../DocumentType";
import { DocumentForm } from "./DocumentForm";

export function OnboardingDocumentForm({
  documentType,
  documentTypeDetails,
  nextPage,
}) {
  const { t } = useTranslation();

  const predefinedDocumentTypeIds = Object.values(DocumentType);
  const isPredefined = predefinedDocumentTypeIds.includes(documentType);

  const navigate = useNavigate();
  const { fleetSlug } = useParams();

  const { data, isLoading: isMarketingPageLoading } =
    api.endpoints.getMarketingPageByFleetSlug.useQuery({
      fleetSlug,
    });
  const fleetId = data?.data?.fleet_id ?? null;

  const { user, isLoading: isAuthLoading } = useAuth();

  const driverApplication = (user?.driver_applications ?? []).find(
    (application) => application.fleet_id === Number(fleetId),
  );

  const latestDocumentByStatusByType = getLatestDocumentByStatusByType(
    driverApplication?.documents,
  );
  const lastDocumentOfCurrentType =
    latestDocumentByStatusByType?.[documentType]?.last;

  const [
    createNewDocumentRequest,
    {
      error: creatingError,
      isLoading: isCreating,
      isSuccess: isCreatingSucceed,
      isError: isCreatingFailed,
      reset: resetCreating,
    },
  ] = api.endpoints.createDocument.useMutation();

  const isLoading = isMarketingPageLoading || isAuthLoading;

  const [deleteDocument, { isLoading: isDeleting }] =
    api.endpoints.deleteDocument.useMutation();

  useEffect(() => {
    if (isDeleting) {
      message.loading({
        content: t("processing.clearing"),
        duration: 0,
        key: "clearing",
      });
    } else {
      message.destroy("clearing");
    }
  }, [isDeleting, t]);

  useEffect(() => {
    if (isCreating) {
      message.loading({
        content: t("processing.documentSaving"),
        duration: 0,
        key: "saving_document",
      });
    } else {
      message.destroy("saving_document");
    }
  }, [isCreating, t]);

  useEffect(() => {
    if (isCreatingSucceed) {
      message.success({
        content: t("success.documentSaved"),
      });

      resetCreating();
      navigate(nextPage);
    }
  }, [isCreatingSucceed, navigate, nextPage, resetCreating, t]);

  useEffect(() => {
    if (isCreatingFailed) {
      const errorMessage = creatingError?.data?.message;

      if (errorMessage === undefined) {
        message.error({
          content: t("error.documentNotSaved"),
          duration: 8,
        });
      } else {
        message.error({
          content: t("error.documentNotSavedWithReason", {
            reason: errorMessage,
          }),
          duration: 8,
        });
      }
    }
  }, [isCreatingFailed, t, creatingError?.data?.message]);

  if (isLoading) {
    return <LoadingComp loading />;
  }

  const isCurrentDocumentExist = lastDocumentOfCurrentType !== undefined;

  return (
    <DocumentForm
      isEditPage={!isCurrentDocumentExist}
      document={lastDocumentOfCurrentType}
      documentType={documentTypeDetails}
      buttons={
        isCurrentDocumentExist
          ? [
              {
                block: true,
                variant: "default",
                importance: "error",
                shape: "round",
                fontWeight: "bold",
                onClick: () => {
                  deleteDocument({ id: lastDocumentOfCurrentType.id });
                },
                children: t("action.clear"),
                loading: isDeleting,
                disabled: isDeleting,
              },
              {
                block: true,
                variant: "primary",
                shape: "round",
                fontWeight: "bold",
                to: nextPage,
                children: t("action.next"),
              },
            ]
          : [
              {
                block: true,
                variant: "primary",
                shape: "round",
                fontWeight: "bold",
                onClick: "onSave",
                loading: isCreating,
                disabled: isCreating,
                children: t("action.saveAndNext"),
              },
              {
                block: true,
                variant: "link",
                importance: "warning",
                fontWeight: "bold",
                shape: "round",
                to: nextPage,
                onClick: () => {
                  message.warning("Data not saved");
                },
                children: t("action.skipForNow"),
              },
            ]
      }
      onSave={({ extraInfo, expireAt, files }) => {
        createNewDocumentRequest({
          type: isPredefined ? documentType : DocumentType.CUSTOM_DOCUMENT,
          customDocumentTypeId: isPredefined ? undefined : documentType,
          extraInfo,
          expireAt,
          files,
          documentableId: driverApplication?.id,
          documentableType: "driver_application",
        });
      }}
    />
  );
}
