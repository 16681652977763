import last from "lodash/last";
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";

import { log } from "helpers/logger";
import { DocumentType } from "./DocumentType";

const logInternal = (...args) =>
  log("getLatestDocumentByStatusByType.js", ...args);

export function getLatestDocumentByStatusByType(documents = []) {
  logInternal(
    "getLatestDocumentByStatusByType",
    "started with documents",
    documents,
  );

  const documentsByType = groupBy(documents, "type");

  logInternal(
    "getLatestDocumentByStatusByType",
    "grouped documents by type",
    documentsByType,
  );

  const documentsByTypeAndCustomType =
    groupCustomDocumentsByTypeAndCombine(documentsByType);

  logInternal(
    "getLatestDocumentByStatusByType",
    "grouped documents by type and custom type",
    documentsByTypeAndCustomType,
  );

  const documentsByStatusByTypeAndCustomType = mapValues(
    documentsByTypeAndCustomType,
    (documents) => {
      return {
        ...groupBy(documents, "status"),
        last: [last(documents)], // in array the same way as other documents by status
      };
    },
  );

  logInternal(
    "getLatestDocumentByStatusByType",
    "grouped documents by status by type and custom type",
    documentsByStatusByTypeAndCustomType,
  );

  const latestDocumentByStatusByTypeAndCustomType = mapValues(
    documentsByStatusByTypeAndCustomType,
    (documentsByStatus) => mapValues(documentsByStatus, last),
  );

  logInternal(
    "getLatestDocumentByStatusByType",
    "pick latest document by status by type and custom type",
    latestDocumentByStatusByTypeAndCustomType,
  );

  return latestDocumentByStatusByTypeAndCustomType;
}

function groupCustomDocumentsByTypeAndCombine(documentsByType) {
  logInternal(
    "groupCustomDocumentsByTypeAndCombine",
    "started with argument",
    documentsByType,
  );

  const customDocuments = documentsByType[DocumentType.CUSTOM_DOCUMENT];
  const customDocumentsByType = groupBy(
    customDocuments,
    "custom_document_type_id",
  );

  logInternal(
    "groupCustomDocumentsByTypeAndCombine",
    "grouped custom documents by type",
    customDocumentsByType,
  );

  const combined = {
    ...documentsByType,
    ...customDocumentsByType,
  };

  delete combined[DocumentType.CUSTOM_DOCUMENT]; // the data from there is in `customDocumentsByType`
  delete combined["null"]; // some old custom documents may have `null` as a value of `custom_document_type_id`, ignore them

  logInternal(
    "groupCustomDocumentsByTypeAndCombine",
    "combined documents by type with custom documents by custom type",
    combined,
  );

  return combined;
}
