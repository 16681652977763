import { useFlags } from "launchdarkly-react-client-sdk";
import { getContentOfRelevantItems } from "helpers/getContentOfRelevantItems";

import { useQueryPredefinedDocumentTypes } from "./DocumentTypeDetails";
import { DocumentType } from "./DocumentType";

export function usePredefinedDriverDocumentTypes() {
  const flags = useFlags();
  const predefinedDocumentTypes = useQueryPredefinedDocumentTypes();

  return getContentOfRelevantItems([
    {
      condition: !flags.permissionAddressNotRequired,
      content: predefinedDocumentTypes[DocumentType.ADDRESS_PROOF],
    },
    {
      condition: !flags.permissionDriverLicenseNotRequired,
      content: predefinedDocumentTypes[DocumentType.DRIVER_LICENSE],
    },
    {
      condition: !flags.permissionBadgeDocNotRequired,
      content: predefinedDocumentTypes[DocumentType.BADGE],
    },
    {
      condition: !flags.permissionSchoolBadgeDocNotRequired,
      content: predefinedDocumentTypes[DocumentType.SCHOOL_BADGE],
    },
  ]);
}

export function usePredefinedVehicleDocumentTypes() {
  const predefinedDocumentTypes = useQueryPredefinedDocumentTypes();
  const flags = useFlags();

  return getContentOfRelevantItems([
    {
      condition: true,
      content: predefinedDocumentTypes[DocumentType.VEHICLE_DETAILS],
    },
    {
      condition: !flags.permissionTaxiPhvDocumentNotRequired,
      content: predefinedDocumentTypes[DocumentType.PLATE],
    },
    {
      condition: !flags.permissionInsuranceDocumentNotRequired,
      content: predefinedDocumentTypes[DocumentType.INSURANCE],
    },
    {
      condition: !flags.permissionCouncilComplianceDocNotRequired,
      content: predefinedDocumentTypes[DocumentType.COUNCIL_COMPLIANCE],
    },
  ]);
}
