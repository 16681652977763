import React from "react";
import { useCallback } from "react";

export const scrollToId = (scrollId) => {
  const elementToScrollTo = document.getElementById(scrollId);
  if (elementToScrollTo) {
    elementToScrollTo.scrollIntoView({ behavior: "smooth" });
  }
};

export const ScrollTop = ({ scrollId, className, customLabel }) => {
  const onClickaHandled = useCallback(() => scrollToId(scrollId), [scrollId]);
  return (
    <div className={`scrollTop ${className || ""}`} onClick={onClickaHandled}>
      {customLabel ? customLabel : "Scroll to top"}
    </div>
  );
};
