import { StateHandler } from "components/LoadingComp";
import { InfiniteScrollComp } from "components/InfiniteScroll";
import Invitation from "../Invitation/Invitation";
import { UserListItem } from "../UserListItem/UserListItem";
import { useCallback, useState } from "react";
import { useWindowSize } from "hooks/useWindowSize";
import useRoles from "hooks/useRoles";

export const List = ({
  id,
  isLoading,
  usersData,
  usersList,
  getNextUsers,
  showInvitation,
}) => {
  const { isSuperAdmin } = useRoles();
  const [wrapperComponent, setWrapperComponent] = useState(null);
  useWindowSize();

  const onWrapperMount = useCallback((node) => {
    if (node) {
      setWrapperComponent(node);
    }
  }, []);

  const wrapperHeight = wrapperComponent?.clientHeight;

  return (
    <StateHandler
      companySelected={!id}
      showLoader={isLoading}
      showEmpty={!usersData?.data?.length}
      emptyTitle="users"
    >
      <InfiniteScrollComp
        dataLength={usersList?.length}
        next={getNextUsers}
        height={wrapperHeight}
        hasMore={false}
        className="userlist-section"
        topic="users"
        wrapperRef={onWrapperMount}
      >
        {showInvitation && <Invitation />}
        <div className="users-list">
          <div className="header">
            <h2>Team members</h2>
          </div>
          {usersList?.map((user) => (
            <UserListItem
              user={user}
              isSuperAdmin={isSuperAdmin}
              key={user.id}
            />
          ))}
        </div>
      </InfiniteScrollComp>
    </StateHandler>
  );
};

export default List;
