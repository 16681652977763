import React from "react";
import { Select } from "antd";
import { dashboardPickerOptions } from "./DashboardPickerOptions";

import "./DashboardPicker.scss";

export const DashboardPicker = ({ wrapperClassName, onOptionSelect }) => {
  const getSelectedDateRange = (selectedOption) =>
    dashboardPickerOptions.find((option) => option.value === selectedOption)
      .dateRange;

  const handleOptionSelect = (selectedOption) =>
    onOptionSelect(getSelectedDateRange(selectedOption));

  return (
    <div className={`dashboard-picker ${wrapperClassName}`}>
      <Select
        defaultValue={dashboardPickerOptions[0]}
        options={dashboardPickerOptions}
        onChange={(option) => handleOptionSelect(option)}
        className={"dashboard-picker__input"}
      />
    </div>
  );
};
