import { useIsMobile } from "hooks/useMobile";
import React from "react";
import { useDispatch } from "react-redux";
import { setMenuCollapsed } from "store/slices/menuSlice";
import { ReactComponent as ToggleSlider } from "../../assets/icons/Wallet/toggleSlider.svg";
import styles from "./MenuToggle.module.scss";

export const MenuToggle = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const openSlider = () => {
    dispatch(setMenuCollapsed(false));
  };

  if (!isMobile) {
    return null;
  }

  return (
    <div className={styles.menuToggle}>
      <ToggleSlider onClick={openSlider} />
    </div>
  );
};
