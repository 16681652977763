import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import { useSelectedFleet } from "hooks/useSelectedFleet";
import { twoDecimals } from "helpers/twoDecimal";

import {
  WidgetContainer,
  WidgetLink,
  WidgetSmall,
  WidgetStats,
  WidgetTitle,
} from "./Widget";

export function LastPayoutWidget({ driverProfile }) {
  const { t } = useTranslation();

  const { selectedFleetCurrency } = useSelectedFleet();

  if (!driverProfile?.last_payout) {
    return <h4>{t("pageHome.noPayouts")}</h4>;
  }

  return (
    <WidgetContainer>
      <WidgetTitle>{t("pageHome.lastPayout")}</WidgetTitle>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <WidgetStats size="large">
            {selectedFleetCurrency}
            {twoDecimals(driverProfile?.last_payout?.amount / 100)}
          </WidgetStats>
          <WidgetSmall>
            {format(
              new Date(driverProfile?.last_payout?.arrival_date),
              "MMMM dd, yyyy",
            )}
          </WidgetSmall>
        </div>

        {driverProfile?.last_payout?.receipt ? (
          <div>
            <WidgetLink
              href={driverProfile.last_payout.receipt}
              target="_blank"
              rel="noreferrer nofollow"
            >
              {t("action.downloadVatReceipt")}
            </WidgetLink>
          </div>
        ) : null}
      </div>
    </WidgetContainer>
  );
}
