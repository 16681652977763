import { TagTypes } from "../TagTypes";

export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      createDocument: build.mutation({
        invalidatesTags: [TagTypes.ME, TagTypes.DRIVER_DOCUMENTS],
        query: ({
          type, // enum: "address_prof", "driver_license", "phv", "badge", "plate"
          extraInfo,
          expireAt,
          files,
          documentableId, // documentableType's id
          documentableType, // enum: "vehicle", "driver_profile"
          customDocumentTypeId,
        }) => ({
          url: "documents",
          method: "POST",
          data: {
            type,
            extra_info: extraInfo,
            documents: files,
            documentable_id: documentableId,
            documentable_type: documentableType,
            custom_document_type_id: customDocumentTypeId,
            expire_at: expireAt,
          },
        }),
      }),

      deleteDocument: build.mutation({
        invalidatesTags: [TagTypes.ME],
        query: ({ id }) => ({
          url: `documents/${id}`,
          method: "DELETE",
        }),
      }),
    }),
  });
}
