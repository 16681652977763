/**
 * See backend: Models/Enum/DocumentType.php
 * @type {Readonly<{PSV: "psv", BADGE: "badge", ADDRESS_PROOF: "address_prof",
 *   INSURANCE: "insurance", DRIVER_LICENSE: "driver_license", PLATE: "plate",
 *   VEHICLE_DETAILS: "vehicle_details", CUSTOM_DOCUMENT: "custom_document",
 *   SCHOOL_BADGE: "school-badge", COUNCIL_COMPLIANCE: "council_compliance"}>}
 */
export const DocumentType = Object.freeze({
  DRIVER_LICENSE: "driver_license",
  BADGE: "badge",
  PLATE: "plate",
  ADDRESS_PROOF: "address_prof",
  PSV: "psv",
  INSURANCE: "insurance",
  CUSTOM_DOCUMENT: "custom_document",

  SCHOOL_BADGE: "school-badge",
  COUNCIL_COMPLIANCE: "council_compliance",

  VEHICLE_DETAILS: "vehicle_details", // for frontend use
});
