export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      getDvla: build.mutation({
        query: ({ reg }) => ({
          url: "dvla",
          method: "POST",
          data: {
            reg,
          },
        }),
      }),
    }),
  });
}
