import { Navigate, useSearchParams, useLocation } from "react-router-dom";

import { api, DriverApplicationStatus } from "api";
import { routes } from "routes";

import { useRoles } from "hooks/useRoles";
import { useAuth } from "hooks/useAuth";
import { useQueryCompanies } from "hooks/useQueryCompanies";
import { getIsStripeCompletedByDriverProfile } from "helpers/getIsStripeCompletedByDriverProfile";
import { DocumentType } from "pages/documents";
import { SomethingWentWrongErrorPage } from "pages/SomethingWentWrongErrorPage";

import { LoadingComp } from "components/LoadingComp";

export function IndexPage() {
  const [searchParams] = useSearchParams();
  const joinFleetSlug = searchParams.get("join-fleet");
  const hasJoinFleetSlug = joinFleetSlug !== null;

  const { user } = useAuth();
  const intentSignupFleetId = user?.intent?.driver_signup?.fleet_id;
  const hasIntentSignupFleetId = intentSignupFleetId !== undefined;

  if (hasJoinFleetSlug) {
    return <OnboardingFromUrlFlow />;
  }

  if (hasIntentSignupFleetId) {
    return <OnboardingFromIntentFlow />;
  }

  return <RegularAuthFlow />;
}

function OnboardingFromUrlFlow() {
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const joinFleetSlug = searchParams.get("join-fleet");

  const { data: fleetToJoinMarketingData, isLoading: isMarketingDataLoading } =
    api.endpoints.getMarketingPageByFleetSlug.useQuery({
      fleetSlug: joinFleetSlug,
    });

  const joinFleetId = fleetToJoinMarketingData?.data?.fleet_id;

  if (isMarketingDataLoading) {
    return <LoadingComp loading fullScreen />;
  }

  const hasJoinFleetSlug = joinFleetSlug !== null;
  if (hasJoinFleetSlug) {
    const driverProfileWithTheSameFleetSlugAsTheJoinFleetSlug =
      user?.driver_profiles?.find(
        (driverProfile) => driverProfile?.fleet?.url_slug === joinFleetSlug,
      );
    const isDriverAlreadyJoinedFleetToJoin =
      driverProfileWithTheSameFleetSlugAsTheJoinFleetSlug !== undefined;

    if (isDriverAlreadyJoinedFleetToJoin) {
      return <Navigate to="/wallet" replace />;
    }

    const currentDriverApplication = user?.driver_applications?.find(
      (driverApplication) => driverApplication?.fleet_id === joinFleetId,
    );
    const hasCurrentDriverApplication = currentDriverApplication !== undefined;

    const isCurrentDriverApplicationInProgress =
      currentDriverApplication?.status === DriverApplicationStatus.IN_PROGRESS;
    const doesTheDriverApplicationWithTheSameFleetIdAsInTheUrlNew =
      currentDriverApplication?.status === DriverApplicationStatus.NEW;

    if (hasCurrentDriverApplication) {
      if (isCurrentDriverApplicationInProgress) {
        return (
          <Navigate
            to={routes.onboardingDocument.getURL({
              fleetSlug: joinFleetSlug,
              documentTypeId: DocumentType.ADDRESS_PROOF,
            })}
            replace
          />
        );
      }

      if (doesTheDriverApplicationWithTheSameFleetIdAsInTheUrlNew) {
        return (
          <Navigate
            to={routes.onboardingApplicationIsSubmitted.getURL(joinFleetSlug)}
            replace
          />
        );
      }

      // driver already started onboarding, but the application status
      // is not "in_progress" and is not "new";
      // until needed UI is designed;
      return (
        <Navigate
          to={routes.onboardingApplicationIsSubmitted.getURL(joinFleetSlug)}
          replace
        />
      );
    }

    if (!hasCurrentDriverApplication) {
      return (
        <Navigate
          to={routes.onboardingCreateDriverProfilePage.getURL(joinFleetSlug)}
          replace
        />
      );
    }
  }

  return <SomethingWentWrongErrorPage />;
}

function OnboardingFromIntentFlow() {
  const { user } = useAuth();
  const intentSignupFleetId = user?.intent?.driver_signup?.fleet_id;

  return (
    <NavigateToFleetSlugPathByFleetId
      fleetId={intentSignupFleetId}
      getPath={(slug) => routes.onboardingCreateDriverProfilePage.getURL(slug)}
    />
  );
}

function RegularAuthFlow() {
  const { isAdmin, isDriver } = useRoles();
  const { driverCompaniesById, currentCompanyId, driverProfilesByCompanyId } =
    useQueryCompanies();
  const currentFleet = driverCompaniesById[currentCompanyId];
  const currentDriverProfile = driverProfilesByCompanyId[currentCompanyId];

  const { state } = useLocation();
  const savedURL = [state?.pathname, state?.search]
    .filter((value) => typeof value === "string")
    .join("");

  const hasSavedURL = savedURL.length > 0;
  if (hasSavedURL) {
    return <Navigate to={savedURL} replace />;
  }

  const isKycNeeded =
    currentFleet?.enable_driver_pay === true &&
    !getIsStripeCompletedByDriverProfile(currentDriverProfile);
  if (isDriver && isKycNeeded) {
    return (
      <Navigate
        to={routes.onboardingStripeKyc.getURL(currentFleet?.url_slug)}
        replace
      />
    );
  }

  if (isAdmin && isDriver) {
    return <Navigate to={routes.welcome.getURL()} replace />;
  }

  if (isAdmin) {
    return <Navigate to="/console/dashboard" replace />;
  }

  if (isDriver) {
    return <Navigate to="/wallet" replace />;
  }

  return <SomethingWentWrongErrorPage />;
}

function NavigateToFleetSlugPathByFleetId({ fleetId, getPath }) {
  const { data: publicData, isLoading: isPublicFleetDataLoading } =
    api.endpoints.getFleetPublicInfoById.useQuery({
      fleetId: fleetId,
    });

  const fleetSlug = publicData?.data?.url_slug;

  if (isPublicFleetDataLoading) {
    return null;
  }

  return <Navigate to={getPath(fleetSlug)} />;
}
