import clsx from "clsx";

import { DateFnsDatePicker } from "components/DateFnsDatePicker";
import styles from "./DatePicker.module.scss";

export function DatePicker({ invalid, ...otherProps }) {
  return (
    <DateFnsDatePicker
      className={clsx(styles.datePicker, {
        [styles.invalid]: invalid,
      })}
      {...otherProps}
    />
  );
}
