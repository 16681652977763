export function getCommonData({
  documentTypes,
  customDocumentTypes,
  isLoading,
  predefinedDriverDocumentTypes,
  predefinedVehicleDocumentTypes,
}) {
  const customDriverDocumentTypes = customDocumentTypes.filter(
    (type) =>
      Array.isArray(type.documentable_types) &&
      type.documentable_types.includes("driver_profile"),
  );

  const customApplicationDriverDocumentTypes = customDocumentTypes.filter(
    (type) =>
      Array.isArray(type.documentable_types) &&
      type.documentable_types.includes("driver_profile") &&
      type.documentable_types.includes("driver_application"),
  );

  const customVehicleDocumentTypes = customDocumentTypes.filter(
    (type) =>
      Array.isArray(type.documentable_types) &&
      type.documentable_types.includes("vehicle"),
  );

  const customApplicationVehicleDocumentTypes = customDocumentTypes.filter(
    (type) =>
      Array.isArray(type.documentable_types) &&
      type.documentable_types.includes("vehicle") &&
      type.documentable_types.includes("driver_application"),
  );

  const customApplicationOnlyDocumentTypes = customDocumentTypes.filter(
    (type) =>
      Array.isArray(type.documentable_types) &&
      type.documentable_types.length === 1 &&
      type.documentable_types.includes("driver_application"),
  );

  const onboardingSteps = [
    ...predefinedDriverDocumentTypes,
    ...customApplicationDriverDocumentTypes,
    ...customApplicationOnlyDocumentTypes,
    ...predefinedVehicleDocumentTypes,
    ...customApplicationVehicleDocumentTypes,
  ]
    .map((type) => String(type.id))
    .concat("submit");

  return {
    isLoading,
    documentTypes: [...documentTypes, ...customDocumentTypes],
    predefinedDriverDocumentTypes,
    customDriverDocumentTypes,
    customApplicationDriverDocumentTypes,
    customApplicationOnlyDocumentTypes,
    predefinedVehicleDocumentTypes,
    customVehicleDocumentTypes,
    customApplicationVehicleDocumentTypes,
    onboardingSteps,
  };
}
