import { useCallback, useMemo, useState } from "react";
import { StateHandler } from "components/LoadingComp";
import clsx from "clsx";
import { InfiniteScrollComp } from "components/InfiniteScroll";
import "./ReportTable.scss";
import { statusColor } from "../../BookingsList/Table/constants";
import { useIsMobile } from "hooks/useMobile";
import { MobileTableItem } from "components/MobileTableItem";
import { Button, Checkbox } from "antd";
import { twoDecimals } from "helpers/twoDecimal";
import { useWindowSize } from "hooks/useWindowSize";
import { CustomDropdown } from "components/CustomDropdown";
import { individualReportOptions } from "../reportOptions";
import { format } from "date-fns";

const payoutHeaderItems = [
  "payout id",
  "driver ref",
  "driver name",
  "payout amount",
  "est. arrival",
  "status",
];

export const ReportTable = ({
  headerSection,
  loading,
  reportsList,
  isUninitialized,
  id,
  getNextReports,
  paginationInfo,
  topScrollId,
  selectedReports,
  onRowSelect,
  onExport,
}) => {
  const isMobile = useIsMobile();

  const [wrapperComponent, setWrapperComponent] = useState(null);
  useWindowSize();

  const onWrapperMount = useCallback((node) => {
    if (node) {
      setWrapperComponent(node);
    }
  }, []);

  const onItemSelect = (type, report) => {
    if (type === "export") {
      onExport(report);
    }
  };

  const emptyMessage = useMemo(() => {
    if (!id) {
      return;
    }

    return "Try changing your filters";
  }, [id]);

  const wrapperHeight = wrapperComponent?.clientHeight;

  return (
    <div ref={onWrapperMount} className="reports-table-wrapper">
      <div className={clsx(!loading && "reports-table-container")}>
        <StateHandler
          emptyTitle="reports"
          companySelected={!id}
          showEmpty={!reportsList?.length && !isUninitialized && !loading}
          headerComponent={headerSection}
          customEmptyMessage={emptyMessage}
        >
          <InfiniteScrollComp
            dataLength={reportsList?.length}
            next={getNextReports}
            height={`${wrapperHeight}px`}
            topic="reports"
            scrollThreshold={0.95}
            showEndMessage={!loading}
            hasMore={paginationInfo?.nextPage}
          >
            <div className="reports-list-header-section-wrapper">
              {headerSection}
            </div>
            <div
              className={clsx(
                "reports-list-table-wrapper",
                loading && "reports-table-loading",
              )}
            >
              <div className="reports-list-table-section-wrapper">
                <span id={topScrollId}></span>
                {!isMobile && (
                  <div className="reports-header">
                    {payoutHeaderItems.map((item, index) => {
                      return (
                        <div key={index}>
                          <p>{item.toUpperCase()}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
                {isMobile ? (
                  <>
                    {reportsList?.map((report) => {
                      const {
                        id,
                        amount,
                        currency,
                        arrival_date,
                        status,
                        external_id,
                        driver,
                      } = report;
                      const isRowSelected = selectedReports[external_id];
                      return (
                        <div key={id} className="mobile-row">
                          <MobileTableItem
                            fields={[
                              {
                                label: "Driver Ref",
                                render: driver?.ref,
                              },
                              {
                                label: "Driver Name",
                                render: driver?.name,
                              },
                              {
                                label: "Payout Amount",
                                render: (
                                  <>
                                    {new Intl.NumberFormat(
                                      window.navigator.language,
                                      {
                                        style: "currency",
                                        currency: currency.toUpperCase(),
                                        currencyDisplay: "narrowSymbol",
                                      },
                                    )
                                      .format(0)
                                      .replace(/[0-9]./g, "")}
                                    {twoDecimals(amount / 100)}
                                  </>
                                ),
                              },
                              {
                                label: "Est. Arrival",
                                render: format(
                                  new Date(arrival_date),
                                  "dd/MM/yyyy",
                                ),
                              },
                              {
                                label: "Status",
                                render: (
                                  <div className="status-tag cell">
                                    <div
                                      style={{
                                        color: statusColor[status],
                                        border: `1px solid ${
                                          statusColor[status]
                                        }`,
                                      }}
                                    >
                                      {status}
                                    </div>
                                  </div>
                                ),
                              },
                            ]}
                            onSelect={() => onRowSelect(report)}
                            isSelected={isRowSelected}
                            actions={
                              <div className="mobile-report-actions">
                                <p className="mobile-report-id">
                                  {external_id}
                                </p>
                                <CustomDropdown
                                  options={individualReportOptions}
                                  onItemSelect={(type) =>
                                    onItemSelect(type, report)
                                  }
                                  mobileContent={
                                    <div className="mobile-report-action-drawer">
                                      <h1>Actions</h1>
                                      <p>
                                        Export a CSV file of the Driver Pay
                                        payout you have choosen.
                                      </p>
                                      <Button onClick={() => onExport(report)}>
                                        Export
                                      </Button>
                                    </div>
                                  }
                                />
                              </div>
                            }
                          />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {reportsList?.map((report, index) => {
                      const {
                        amount,
                        currency,
                        arrival_date,
                        status,
                        external_id,
                        driver,
                      } = report;
                      const isRowSelected = selectedReports[external_id];
                      return (
                        <div
                          className={clsx("row", isRowSelected && "selected")}
                          key={index}
                        >
                          <div className="cell report_id">
                            <Checkbox
                              checked={isRowSelected}
                              onChange={() => onRowSelect(report)}
                            />
                            {external_id}
                          </div>
                          <div className="cell">{driver?.ref}</div>
                          <div className="cell">{driver?.name}</div>
                          <div className="cell">
                            {new Intl.NumberFormat(window.navigator.language, {
                              style: "currency",
                              currency: currency.toUpperCase(),
                              currencyDisplay: "narrowSymbol",
                            })
                              .format(0)
                              .replace(/[0-9]./g, "")}
                            {twoDecimals(amount / 100)}
                          </div>
                          <div className="cell">
                            {format(new Date(arrival_date), "dd/MM/yyyy")}
                          </div>
                          <div className="status-tag cell">
                            <div
                              style={{
                                color: statusColor[status],
                                border: `1px solid ${statusColor[status]}`,
                              }}
                            >
                              {status}
                            </div>
                          </div>
                          <div className="cell dropdown">
                            <CustomDropdown
                              options={individualReportOptions}
                              onItemSelect={(type) =>
                                onItemSelect(type, report)
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </InfiniteScrollComp>
        </StateHandler>
      </div>
    </div>
  );
};
