import { DriverDocumentLayout } from "../DriverDocumentLayout";
import { DocumentsPage } from "./DocumentsPage";

export function DriverDocumentListPage() {
  return (
    <DriverDocumentLayout>
      <DocumentsPage />
    </DriverDocumentLayout>
  );
}
