import { useDispatch } from "react-redux";
import { BurgerButton } from "components/BurgerButton";
import { useDocumentsBadgeStatus } from "components/Navigation";
import { setMenuCollapsed } from "store/slices/menuSlice";
import { useAuth } from "hooks/useAuth";

export function BurgerSidebarTriggerButton() {
  const dispatch = useDispatch();

  const { meta } = useAuth();
  const { unread_notifications: unreadNotifications } = meta ?? {
    unread_notifications: 0,
  };
  const documentsBadgeStatus = useDocumentsBadgeStatus();
  const documentsNeedAttention = documentsBadgeStatus !== undefined;
  const badgeImportance = (() => {
    if (unreadNotifications > 0) {
      return "danger";
    }

    return documentsBadgeStatus === "warning" ? "warning" : "danger";
  })();

  const badge =
    unreadNotifications > 0
      ? String(unreadNotifications)
      : documentsNeedAttention;

  return (
    <BurgerButton
      badge={badge}
      badgeImportance={badgeImportance}
      onClick={() => {
        dispatch(setMenuCollapsed(false));
      }}
    />
  );
}
