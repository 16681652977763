import { AuthRequired } from "components/AuthRequired";
import { IndexPage as IndexPageInternal } from "./IndexPage";

export function IndexPageWithGates() {
  return (
    <AuthRequired>
      <IndexPageInternal />
    </AuthRequired>
  );
}
