import { useCallback, useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

import { routes } from "routes";

import { Form } from "components/Form";
import { Field } from "components/Field";
import { FormPasswordInput } from "components/FormPasswordInput/FormPasswordInput";
import { Button } from "components/Button";
import { emailRegex, Input } from "components/Input";

import styles from "./OnboardingSignUpPage.module.scss";

export function OnboardingSignUpForm({ fleetSlug, emailExists, onSubmit }) {
  const { t } = useTranslation();

  const renderForm = useCallback(
    ({ values, errors, onChange }) => {
      return (
        <>
          <Field
            htmlFor="email"
            label={t("field.email")}
            error={
              emailExists ? (
                <Trans i18nKey="pageSignUp.emailInUsePleaseSignIn">
                  The email is already used. Please
                  <Link
                    to={routes.signIn.getURL(fleetSlug)}
                    className={styles.signInLink}
                  >
                    sign in
                  </Link>
                  .
                </Trans>
              ) : (
                errors.email
              )
            }
          >
            <Input
              id="email"
              name="email"
              type="email"
              value={values.email}
              onChange={onChange}
            />
          </Field>

          <Field
            htmlFor="password"
            label={t("field.password")}
            error={errors.password}
          >
            <FormPasswordInput
              id="password"
              name="password"
              value={values.password}
              onChange={onChange}
            />
          </Field>

          <Field
            htmlFor="passwordConfirm"
            label={t("field.passwordConfirm")}
            error={errors.passwordConfirm}
          >
            <FormPasswordInput
              id="passwordConfirm"
              name="passwordConfirm"
              value={values.passwordConfirm}
              onChange={onChange}
            />
          </Field>

          <Field>
            <Button
              block
              type="submit"
              variant="primary"
              shape="round"
              fontWeight="bold"
            >
              {t("action.signUp")}
            </Button>
          </Field>
        </>
      );
    },
    [t, emailExists],
  );

  const validationRules = useMemo(() => {
    return [
      {
        field: "email",
        isValid: (value) => typeof value === "string" && value.length > 0,
        error: t("error.emailRequired"),
      },
      {
        field: "email",
        isValid: (value) => emailRegex.test(value),
        error: t("error.emailInvalid"),
      },
      {
        field: "password",
        isValid: (value) => typeof value === "string" && value.length > 0,
        error: t("error.passwordRequired"),
      },
      {
        field: "password",
        isValid: (value) => typeof value === "string" && value.length > 8,
        error: t("error.passwordMustBeLonger"),
      },
      {
        field: "passwordConfirm",
        isValid: (value) => typeof value === "string" && value.length > 0,
        error: t("error.passwordConfirmationRequired"),
      },
      {
        field: "passwordConfirm",
        isValid: (value, form) => value === form.password,
        error: t("error.passwordsNotMatch"),
      },
    ];
  }, [t]);

  return (
    <Form
      initialValues={initialValues}
      validationRules={validationRules}
      onSubmit={onSubmit}
      noValidate
    >
      {renderForm}
    </Form>
  );
}

const initialValues = {
  email: "",
  password: "",
  passwordConfirm: "",
};
