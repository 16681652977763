import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { showErrorMsg } from "helpers/windowMassages";
import useRoles from "hooks/useRoles";

export function RolesRequired({ children, allowByRoles = () => true }) {
  const { t } = useTranslation();

  const rolesReturn = useRoles();

  useEffect(() => {
    if (Array.isArray(rolesReturn.roles) && !allowByRoles(rolesReturn)) {
      showErrorMsg({
        content: t("error.youNotAuthorizedToAccess"),
      });
    }
  }, [rolesReturn, allowByRoles, t]);

  if (!Array.isArray(rolesReturn.roles)) {
    return null;
  }

  if (Array.isArray(rolesReturn.roles) && allowByRoles(rolesReturn)) {
    return children;
  }

  return <Navigate to="/" replace />;
}
