import { WaveLayout } from "components/WaveLayout";
import { Container } from "components/Container";

import { OnboardingCheckEmailContent } from "./OnboardingCheckEmailContent";

export function OnboardingCheckEmailPage() {
  return (
    <WaveLayout>
      <Container width="small">
        <OnboardingCheckEmailContent />
      </Container>
    </WaveLayout>
  );
}
