import styles from "./Title.module.scss";

export function Title({ children, level = 1, ...otherProps }) {
  const levelToElement = {
    1: "h1",
    2: "h2",
    3: "h3",
    4: "h4",
    5: "h5",
    6: "h6",
  };

  const validatedLevel = level < 1 ? 1 : level > 6 ? 6 : level;

  const Element = levelToElement[validatedLevel];

  return (
    <Element {...otherProps} className={styles.title}>
      {children}
    </Element>
  );
}
