import { api } from "api";

export function useAuth() {
  const {
    data: userData,
    error,
    isLoading,
    refetch,
  } = api.endpoints.getMe.useQuery();

  return {
    user: userData?.data,
    meta: userData?.meta,
    error,
    isLoading,
    refetch,
  };
}
