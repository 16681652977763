import { OnboardingCreateDriverProfileInternal } from "./OnboardingCreateDriverProfileInternal";
import { OnboardingWrapperScreen } from "../OnboardingWrapperScreen";

export function OnboardingCreateDriverProfilePage() {
  return (
    <OnboardingWrapperScreen>
      <OnboardingCreateDriverProfileInternal />
    </OnboardingWrapperScreen>
  );
}
