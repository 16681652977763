import { NavLink } from "react-router-dom";
import clsx from "clsx";

import { ReactComponent as DocumentWarningIcon } from "assets/icons/documentWarning.svg";
import { ReactComponent as DocumentErrorIcon } from "assets/icons/documentError.svg";

import styles from "./NavLinkInternal.module.scss";

const badgeIconByType = {
  warning: DocumentWarningIcon,
  error: DocumentErrorIcon,
  more: () => (
    <div className={clsx(styles.navLinkBadgeText, styles.small)}>9+</div>
  ),
  9: () => <div className={styles.navLinkBadgeText}>9</div>,
  8: () => <div className={styles.navLinkBadgeText}>8</div>,
  7: () => <div className={styles.navLinkBadgeText}>7</div>,
  6: () => <div className={styles.navLinkBadgeText}>6</div>,
  5: () => <div className={styles.navLinkBadgeText}>5</div>,
  4: () => <div className={styles.navLinkBadgeText}>4</div>,
  3: () => <div className={styles.navLinkBadgeText}>3</div>,
  2: () => <div className={styles.navLinkBadgeText}>2</div>,
  1: () => <div className={styles.navLinkBadgeText}>1</div>,
};

export function NavLinkInternal({
  to,
  external,
  children,
  onClick,
  end,
  status,
  icon,
  badge,
  collapsed,
}) {
  const BadgeIcon = badgeIconByType[badge];

  const content = (
    <>
      {Boolean(icon) ? <div className={styles.navLinkIcon}>{icon}</div> : null}
      {Boolean(BadgeIcon) ? (
        <div className={styles.navLinkBadge}>
          <BadgeIcon />
        </div>
      ) : null}
      <div
        className={clsx(styles.navLinkText, { [styles.collapsed]: collapsed })}
      >
        <div className={clsx(styles.navLinkTextInner)}>{children}</div>
      </div>
    </>
  );

  if (external) {
    return (
      <a
        href={to}
        role="link"
        onClick={onClick}
        className={clsx(styles.navLink, {
          [styles.warning]: status === "warning",
          [styles.error]: status === "error",
          [styles.collapsed]: collapsed,
        })}
      >
        {content}
      </a>
    );
  }

  return (
    <NavLink
      to={to}
      onClick={onClick}
      end={end}
      className={({ isActive }) =>
        clsx(styles.navLink, {
          [styles.active]: isActive,
          [styles.warning]: status === "warning",
          [styles.error]: status === "error",
          [styles.collapsed]: collapsed,
        })
      }
    >
      {content}
    </NavLink>
  );
}
