import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { api } from "api";
import { routes } from "routes";

import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "helpers/windowMassages";

import { WaveLayout } from "components/WaveLayout";
import { Container } from "components/Container";

import { SelectAnAccountForPayoutsForm } from "./SelectAnAccountForPayoutsForm";

export function OnboardingSelectPayoutAccountPage() {
  const { t } = useTranslation();

  const { driverProfileId } = useParams();
  const navigate = useNavigate();

  const [createStripeBankAccount, { isSuccess, isError, isLoading }] =
    api.endpoints.createStripeBankAccounts.useMutation();

  useEffect(() => {
    if (isLoading) {
      showLoadingMsg({ content: t("processing.processing") });
    }
  }, [isLoading, t]);

  useEffect(() => {
    if (isError) {
      showErrorMsg({
        content: t("error.formInvalid"),
      });
    }
  }, [isError, t]);

  useEffect(() => {
    if (isSuccess) {
      showSuccessMsg({
        content: t("success.accountSelected"),
      });
      navigate(routes.stripeCompleted.getURL());
    }
  }, [isSuccess, t, navigate]);

  function onSubmit(form) {
    createStripeBankAccount({
      driverProfileId,
      currency: form.currency,
      country: form.country,
      account_number: form.accountNumber,
      routing_number:
        form.routingNumber.length === 0 ? undefined : form.routingNumber,
    });
  }

  return (
    <WaveLayout>
      <Container>
        <SelectAnAccountForPayoutsForm onSubmit={onSubmit} />
      </Container>
    </WaveLayout>
  );
}
