import React, { useCallback, useEffect, useState } from "react";
import { Select } from "antd";
import { paymentFrequencyTypes } from "./FrequencyData";
import { api } from "api";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "helpers/windowMassages";
import capitalize from "lodash/capitalize";
import { RedirectToCompany, useQueryCompanies } from "hooks/useQueryCompanies";
import useRoles from "hooks/useRoles";

const { Option } = Select;

export const PayoutFrequency = () => {
  const {
    currentCompany: selectedCompany,
    setCurrentCompanyId: handleChangeSelectedCompany,
  } = useQueryCompanies({ redirectTo: RedirectToCompany.ADMIN });

  const [modifySettings, { isLoading }] =
    api.endpoints.modifySettings.useMutation();
  const selectedCompanyPayoutFrequency = capitalize(
    selectedCompany.payout_frequency || "Daily",
  );
  const selectedCompanyPayoutDay = capitalize(selectedCompany.payout_day);
  const [frequency, setFrequency] = useState(selectedCompanyPayoutFrequency);
  const [frequencyType, setFrequencyType] = useState(selectedCompanyPayoutDay);
  const { isSupport } = useRoles();

  useEffect(() => {
    setFrequency(selectedCompanyPayoutFrequency);
    setFrequencyType(selectedCompanyPayoutDay);
  }, [selectedCompanyPayoutDay, selectedCompanyPayoutFrequency]);

  const onSubmit = useCallback(
    (payoutFrequency, payoutFrequencyType) => {
      if (payoutFrequency !== "Daily") {
        if (!payoutFrequencyType) {
          return;
        }
      }
      showLoadingMsg();
      modifySettings({
        fleetId: selectedCompany.id,
        data: {
          payout_frequency: payoutFrequency.toLowerCase(),
          payout_day: (payoutFrequencyType?.toString() || "1").toLowerCase(),
        },
      })
        .unwrap()
        .then(({ data }) => {
          handleChangeSelectedCompany(data.id);
          showSuccessMsg({
            content: "Settings saved!",
          });
          setFrequency(capitalize(data.payout_frequency) || "Daily");
          setFrequencyType(capitalize(data.payout_day));
        })
        .catch(() => {
          showErrorMsg({
            content: "Some error occurred while updating settings",
          });
        });
    },
    [handleChangeSelectedCompany, modifySettings, selectedCompany.id],
  );

  const onTypeSelect = (e) => {
    setFrequency(e);
    const newFrequencyType = paymentFrequencyTypes?.[e]?.[0];
    setFrequencyType(newFrequencyType);
    onSubmit(e, newFrequencyType);
  };

  const onOptionSelect = (e) => {
    setFrequencyType(e);
    onSubmit(frequency, e);
  };

  return (
    <>
      <div className="payment-frequency-wrapper">
        <div className="input-box">
          <label>payout frequency</label>
          <Select
            disabled={isLoading || isSupport}
            value={frequency}
            onSelect={onTypeSelect}
          >
            {Object.keys(paymentFrequencyTypes).map((type, i) => (
              <Option value={type} key={i}>
                {type}
              </Option>
            ))}
          </Select>
        </div>

        {frequency !== "Daily" ? (
          <div className="input-box">
            <label>payout day</label>
            <Select
              disabled={isLoading || isSupport}
              onSelect={onOptionSelect}
              value={frequencyType}
            >
              {paymentFrequencyTypes?.[frequency]?.map((option, i) => (
                <Option value={option} key={i}>
                  {option}
                </Option>
              ))}
            </Select>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default PayoutFrequency;
