export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      getReports: build.query({
        keepUnusedDataFor: 0,
        query: (params) => ({
          url: "payouts",
          params,
        }),
        serializeQueryArgs: ({ endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems) => {
          currentCache.data = [...currentCache.data, ...(newItems?.data || [])];
          currentCache.meta = newItems?.meta;
          currentCache.links = newItems?.links;
        },
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
      }),

      exportReport: build.mutation({
        query: (data) => ({
          data,
          url: "payouts/export",
          method: "POST",
        }),
      }),
    }),
  });
}
