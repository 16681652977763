export const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: "${label} is required!",
  types: {
    // eslint-disable-next-line no-template-curly-in-string
    email: "${label} is not a valid email!",
  },
};

const required = [
  {
    required: true,
  },
];

export const formItemData = [
  {
    name: "name",
    label: "Company name",
    rules: required,
  },
  {
    name: "dispatch_company_id",
    label: "Client ID",
    rules: required,
  },
  {
    name: "website",
    label: "Website",
    rules: required,
  },
  {
    name: "stripe_account_id",
    label: "Stripe account id",
    rules: required,
  },
  {
    name: "phone_number",
    label: "Company phone number",
    rules: required,
  },
  {
    name: "email",
    label: "Company Email",
    type: "email",
    rules: [
      {
        type: "email",
        required: true,
      },
    ],
  },
  {
    name: "fleet_integration_id",
    label: "Integration name",
    type: "select",
    optionsKeyName: "integrations",
    rules: required,
  },
  {
    name: "country",
    label: "Country",
    type: "select",
    optionsKeyName: "countries",
    rules: required,
  },
  {
    name: "url",
    label: "API URL",
    rules: required,
  },
  {
    name: "app_key",
    label: "App key",
    appKeyNote: true,
  },
  {
    name: "app_secret",
    label: "App secret",
    type: "password",
    appSecretNote: true,
  },
];

export const formItemDataKeys = formItemData.reduce(
  (prevValues, currentValue) => {
    return {
      ...prevValues,
      [currentValue.name]: true,
    };
  },
  {},
);

export const countries = [
  {
    id: "AU",
    name: "Australia",
    currency: "AUD",
  },
  {
    id: "CA",
    name: "Canada",
    currency: "CAD",
  },
  {
    id: "SF",
    name: "Finland",
    currency: "EUR",
  },
  {
    id: "IE",
    name: "Ireland",
    currency: "EUR",
  },
  {
    id: "NZ",
    name: "New Zeland",
    currency: "NZD",
  },
  {
    id: "GB",
    name: "United Kingdom of Great Britain and Northern Ireland",
    currency: "GBP",
  },
  {
    id: "US",
    name: "United States",
    currency: "USD",
  },
];
