import { useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLDClient } from "launchdarkly-react-client-sdk";

import { routes } from "routes";
import { api } from "api";

import { LoadingComp } from "components/LoadingComp";
import { Title } from "components/Title";
import { OnboardingWrapperScreen } from "./OnboardingWrapperScreen";
import { SubmitApplicationPage } from "./SubmitApplicationPage";

import {
  DocumentType,
  OnboardingDocumentForm,
  useQueryDocumentTypeById,
  useQueryCustomDocumentTypes,
  VehicleDetailsOnboardingPage,
} from "../documents";

export function OnboardingDocumentPage() {
  const ldClient = useLDClient();
  const { t } = useTranslation();

  const { fleetSlug, documentTypeId } = useParams();

  const { data, isLoading: isMarketingPageLoading } =
    api.endpoints.getMarketingPageByFleetSlug.useQuery({
      fleetSlug,
    });
  const fleetId = data?.data?.fleet_id ?? null;

  useEffect(() => {
    const context = ldClient.getContext();
    const nextContextKey = String(fleetId);

    if (fleetId !== null && context?.key !== nextContextKey) {
      ldClient.identify({
        kind: "fleet",
        key: nextContextKey,
      });
    }
  }, [fleetId, ldClient]);

  const { isLoading: isFleetPublicDataLoading } =
    api.endpoints.getFleetPublicInfoById.useQuery(
      {
        fleetId,
      },
      { skip: fleetId === undefined || fleetId === null },
    );
  const { isLoading: areDocumentTypesDetailLoading, onboardingSteps } =
    useQueryCustomDocumentTypes({ currentCompanyId: fleetId });

  const {
    data: currentDocumentTypeDetails,
    isLoading: areCurrentDocumentTypeDetailsLoading,
  } = useQueryDocumentTypeById({ documentTypeId, currentCompanyId: fleetId });
  const currentStepIndex = onboardingSteps.indexOf(documentTypeId);

  const nextPage =
    currentStepIndex < 0 || currentStepIndex >= onboardingSteps.length
      ? routes.onboardingApplicationIsSubmitted.getURL(fleetSlug)
      : routes.onboardingDocument.getURL({
          fleetSlug,
          documentTypeId: onboardingSteps[currentStepIndex + 1],
        });

  if (!onboardingSteps.includes(documentTypeId)) {
    return (
      <Navigate
        to={routes.onboardingDocument.getURL({
          fleetSlug,
          documentTypeId: onboardingSteps[0],
        })}
        replace
      />
    );
  }
  if (
    areCurrentDocumentTypeDetailsLoading ||
    isMarketingPageLoading ||
    isFleetPublicDataLoading ||
    areDocumentTypesDetailLoading
  ) {
    return <LoadingComp loading fullScreen />;
  }

  if (documentTypeId === "submit") {
    return (
      <OnboardingWrapperScreen breadcrumbs>
        <SubmitApplicationPage />
      </OnboardingWrapperScreen>
    );
  }

  if (documentTypeId === DocumentType.VEHICLE_DETAILS) {
    return (
      <OnboardingWrapperScreen breadcrumbs>
        <VehicleDetailsOnboardingPage />
      </OnboardingWrapperScreen>
    );
  }

  if (!currentDocumentTypeDetails) {
    return (
      <OnboardingWrapperScreen breadcrumbs>
        <Title>{t("pageErrorNotFound.pageNotFound")}</Title>
      </OnboardingWrapperScreen>
    );
  }

  return (
    <OnboardingWrapperScreen breadcrumbs key={currentDocumentTypeDetails.id}>
      <OnboardingDocumentForm
        documentType={currentDocumentTypeDetails.id}
        documentTypeDetails={currentDocumentTypeDetails}
        nextPage={nextPage}
      />
    </OnboardingWrapperScreen>
  );
}
