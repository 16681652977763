import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { ReactComponent as Loading } from "assets/icons/loader.svg";
import { Empty } from "components/Empty";
import "./LoadingComp.scss";

const LoadingComp = ({
  loading,
  children,
  LoaderComp = Loading,
  noDataMsg,
  className = "",
  smallLogo = true,
  fullScreen,
}) => {
  const { t } = useTranslation();

  return loading ? (
    loading.length !== 0 ? (
      <div
        className={clsx(
          "loading-logo",
          className,
          smallLogo && "small-loading-logo",
          fullScreen && "full-loader",
        )}
      >
        <LoaderComp />
      </div>
    ) : (
      (noDataMsg ?? t("componentLoading.noResultsDefaultMessage"))
    )
  ) : (
    children
  );
};

const StateHandler = ({
  companySelected = false,
  showLoader = false,
  // showError = false,
  showEmpty = false,
  // retryHandler = () => {
  //   console.log("Retry!!");
  // },
  className = "",
  emptyTitle,
  children,
  headerComponent = null,
  smallLogo = true,
  customEmptyMessage,
}) => {
  if (companySelected && headerComponent) {
    return (
      <div>
        {headerComponent}
        <Empty customEmptyMessage={customEmptyMessage} title={emptyTitle} />
      </div>
    );
  }
  if (companySelected) {
    return <Empty customEmptyMessage={customEmptyMessage} title={emptyTitle} />;
  }

  if (showLoader) {
    const loaderComp = (
      <div
        className={clsx(
          "loading-logo",
          smallLogo && "small-loading-logo",
          className,
        )}
      >
        <Loading />
      </div>
    );
    if (headerComponent) {
      return (
        <div>
          {headerComponent}
          {loaderComp}
        </div>
      );
    }

    return loaderComp;
  }
  // if (showError) {
  //   return (
  //     <div>
  //       <h4>Err msg</h4>
  //       <button onClick={retryHandler}></button>
  //     </div>
  //   );
  // }

  if (showEmpty && headerComponent) {
    return (
      <div>
        {headerComponent}
        <Empty customEmptyMessage={customEmptyMessage} title={emptyTitle} />
      </div>
    );
  }

  if (showEmpty) {
    return <Empty customEmptyMessage={customEmptyMessage} title={emptyTitle} />;
  }
  return children;
};
export { LoadingComp, StateHandler };
