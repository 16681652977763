import clsx from "clsx";
import styles from "./Badge.module.scss";

export function Badge({ status = "warning", ...otherProps }) {
  return (
    <div
      className={clsx(styles.badge, {
        [styles.warning]: status === "warning",
        [styles.danger]: status === "danger",
      })}
      {...otherProps}
    />
  );
}

export function BadgeList(props) {
  return <div className={styles.badgeList} {...props} />;
}
