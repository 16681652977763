import { DocumentType } from "./DocumentType";

export function getExpiryGetterByDocumentTypeId(documentTypeId) {
  const expiryGetterByDocumentTypeId = {
    [DocumentType.DRIVER_LICENSE]: (driverProfile) =>
      driverProfile.driver_licence_expiry,
    [DocumentType.BADGE]: (driverProfile) => driverProfile.badge_expiry,
    [DocumentType.PLATE]: (vehicle) => vehicle.plate_expiry,
    [DocumentType.INSURANCE]: (vehicle) => vehicle.insurance_expiry,
  };

  const defaultGetter = () => undefined;

  if (documentTypeId in expiryGetterByDocumentTypeId) {
    return expiryGetterByDocumentTypeId[documentTypeId];
  }

  return defaultGetter;
}
