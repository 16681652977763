import { createContext, useContext, useEffect, useMemo, useState } from "react";

export const CurrentCompanyIdContext = createContext(null);

export function useCurrentCompanyIdContext() {
  return useContext(CurrentCompanyIdContext);
}

export function CurrentCompanyIdContextProvider({ children }) {
  const [currentCompanyId, setCurrentCompanyId] = useState(
    getNormalizedCurrentCompanyIdFromLocalStorage,
  );

  useEffect(() => {
    const isCurrentCompanyIdNotDefined =
      currentCompanyId === null || currentCompanyId === undefined;

    const isCurrentCompanyIdTheSameAsInLocalStorage =
      getCurrentCompanyIdFromLocalStorage() === String(currentCompanyId);

    if (
      isCurrentCompanyIdNotDefined ||
      isCurrentCompanyIdTheSameAsInLocalStorage
    ) {
      return;
    }

    setCurrentCompanyIdToLocalStorage(currentCompanyId);
  }, [currentCompanyId]);

  const value = useMemo(
    () => [currentCompanyId, (value) => setCurrentCompanyId(String(value))],
    [currentCompanyId, setCurrentCompanyId],
  );

  return (
    <CurrentCompanyIdContext.Provider value={value}>
      {children}
    </CurrentCompanyIdContext.Provider>
  );
}

function getCurrentCompanyIdFromLocalStorage() {
  return localStorage.getItem("currentCompanyId");
}

function setCurrentCompanyIdToLocalStorage(value) {
  return localStorage.setItem("currentCompanyId", value);
}

function normalize(value) {
  return value === "undefined" || value === "null" ? null : value;
}

export function getNormalizedCurrentCompanyIdFromLocalStorage() {
  return normalize(getCurrentCompanyIdFromLocalStorage());
}
