import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { api } from "api";

import { WaveLayout } from "components/WaveLayout";

import { Spacer } from "components/Spacer";
import { Title } from "components/Title";
import { Description } from "components/Description";
import { Container } from "components/Container";
import { LoadingComp } from "components/LoadingComp";

import {
  OnboardingBreadcrumbs,
  useCurrentDriverApplication,
} from "./OnboardingBreadcrumbs";

import { ReactComponent as EmptyImg } from "assets/icons/empty.svg";
import { useLDClient } from "launchdarkly-react-client-sdk";

export function OnboardingApplicationIsSubmittedPage() {
  const { t } = useTranslation();
  const { fleetSlug } = useParams();
  const ldClient = useLDClient();

  const driverApplication = useCurrentDriverApplication();
  const isApplicationRejected = driverApplication?.status === "rejected";

  const { data: marketingData, isLoading: isMarketingDataLoading } =
    api.endpoints.getMarketingPageByFleetSlug.useQuery({
      fleetSlug,
    });
  const { fleet_id: fleetId } = marketingData?.data ?? {};

  useEffect(() => {
    const context = ldClient.getContext();
    const nextContextKey = String(fleetId);

    if (fleetId && context?.key !== nextContextKey) {
      ldClient.identify({
        kind: "fleet",
        key: nextContextKey,
      });
    }
  }, [ldClient, fleetId]);

  const { data: fleetPublicData, isLoading: isFleetPublicDataLoading } =
    api.endpoints.getFleetPublicInfoById.useQuery(
      {
        fleetId,
      },
      { skip: fleetId === undefined || fleetId === null },
    );
  const { name } = fleetPublicData?.data ?? {};

  const isLoading = isMarketingDataLoading || isFleetPublicDataLoading;
  if (isLoading) {
    return <LoadingComp loading fullScreen />;
  }

  if (isApplicationRejected) {
    return (
      <WaveLayout>
        <Container>
          <div style={{ textAlign: "center", textWrap: "balance" }}>
            <EmptyImg />
            <Spacer />
            <Title>
              {t("pageApplicationRejected.yourApplicationRejected")}
            </Title>
            <Description>
              <p>{t("pageApplicationRejected.thankYouButNo")}</p>

              {typeof driverApplication?.comment === "string" &&
              driverApplication.comment.length > 0 ? (
                <p>
                  {t("pageApplicationRejected.comment", {
                    comment: driverApplication?.comment,
                  })}
                </p>
              ) : null}
            </Description>
            <Spacer />
            <OnboardingBreadcrumbs />
          </div>
        </Container>
      </WaveLayout>
    );
  }

  return (
    <WaveLayout>
      <Container>
        <div style={{ textAlign: "center", textWrap: "balance" }}>
          <EmptyImg />
          <Spacer />
          <Title>
            {t("pageApplicationSubmitted.yourApplicationSubmitted")}
          </Title>
          <Description>
            {name
              ? t("pageApplicationSubmitted.fleetAdminContactYou", {
                  fleetName: name,
                })
              : null}
          </Description>
          <Spacer />
          <OnboardingBreadcrumbs />
        </div>
      </Container>
    </WaveLayout>
  );
}
