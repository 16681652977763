import clsx from "clsx";
import styles from "./Description.module.scss";

export function Description({ importance, ...otherProps }) {
  return (
    <div
      {...otherProps}
      className={clsx(styles.description, {
        [styles.error]: importance === "error",
      })}
    />
  );
}
