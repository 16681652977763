import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button as AntButton, Checkbox, Drawer, Radio } from "antd";
import clsx from "clsx";

import { useIsMobile } from "hooks/useMobile";
import { useOnClickOutside } from "hooks/useClickOutside";
import { Button } from "components/Button";
import Sidebar from "assets/icons/Sidebar";
import "./FilterDropdown.scss";

export const FilterDropdown = ({
  Icon = null,
  options = [],
  btnLabel = "",
  btnLabelMobile = "",
  className = "",
  onChange = () => {},
  name = "",
  initialVal = [],
  isSingular = true,
  filterValue = null,
  closeOnClickOutside = () => {},
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(initialVal);
  const [removedVal, setRemovedVal] = useState("");
  const optionsRef = useRef(null);
  const isInitialRender = useRef(true);

  const clickOutsideHandler = useCallback(() => {
    if (closeOnClickOutside) {
      setOpen(false);
    }
  }, [closeOnClickOutside]);

  useOnClickOutside(optionsRef, clickOutsideHandler);

  const isMobile = useIsMobile();

  const isCheckAll = options.length === values.length;

  const handleSelectAll = (e) => {
    isInitialRender.current = false;
    if (isCheckAll) {
      setValues([]);
    } else {
      setValues(options.map((o) => o.value));
    }
  };

  const handleSelect = useCallback(
    (e, { value }) => {
      const { checked } = e.target;
      if (isSingular) {
        setValues(value);
        onChange(value);
        return;
      }
      isInitialRender.current = false;
      const updatedValues = [...(isCheckAll ? [] : values), value];
      setValues(updatedValues);
      if (checked) {
        setRemovedVal("");
      }
      if (!checked) {
        setRemovedVal(value);
        setValues(values.filter((item) => item !== value));
      }
    },
    [isSingular, isCheckAll, values, onChange],
  );

  const list = options.map((option, index) => {
    const { value } = option;
    if (isSingular && isMobile) {
      return (
        <Radio
          key={index}
          checked={values === value}
          onChange={(e) => handleSelect(e, option)}
        >
          {option.label}
        </Radio>
      );
    }

    return (
      <Checkbox
        key={index}
        checked={isCheckAll ? isInitialRender.current : values.includes(value)}
        onChange={(e) => handleSelect(e, option)}
      >
        {option.label}
      </Checkbox>
    );
  });

  const handleTypeDropdown = () => {
    setOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (!isInitialRender.current) {
      if (isSingular) {
        onChange(values);
        return;
      }
      onChange?.({ name, entries: values, removedVal });
    }
  }, [isSingular, removedVal, values, name, onChange]);

  useEffect(() => {
    if (filterValue) {
      setValues(filterValue);
    }
  }, [filterValue]);

  return (
    <div className={clsx("filter-dropdown-wrapper", className)}>
      <AntButton icon={Icon && <Icon />} onClick={handleTypeDropdown}>
        {btnLabel}
        <Sidebar.ArrowDown />
      </AntButton>
      {open && !isMobile && (
        <div ref={optionsRef} className="options">
          {!isSingular && (
            <Checkbox
              checked={options.length === values.length}
              onChange={handleSelectAll}
            >
              {t("action.showAll")}
            </Checkbox>
          )}
          {list}
        </div>
      )}
      {open && isMobile && (
        <Drawer
          onClose={handleTypeDropdown}
          placement="bottom"
          visible={open}
          closable={false}
          className="mobile-filter-dropdown"
        >
          <p className="mobileLabel">{btnLabelMobile}</p>
          <div className="options">
            {!isSingular && (
              <Checkbox checked={isCheckAll} onChange={handleSelectAll}>
                {t("action.showAll")}
              </Checkbox>
            )}
            {list}
          </div>
          <div className="mobile-filter-dropdown__button-wrapper">
            <Button
              block
              variant="primary"
              shape="round"
              onClick={handleTypeDropdown}
            >
              {t("action.filter")}
            </Button>
          </div>
        </Drawer>
      )}
    </div>
  );
};
