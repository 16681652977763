import { ReactComponent as ExportIcon } from "../../../../assets/icons/export.svg";
import { ReactComponent as Sync } from "../../../../assets/icons/sync.svg";

export const paymentTypeOptions = [
  {
    value: "CARD",
    label: "Card",
  },
  {
    value: "INVOICE",
    label: "Invoice",
  },
  {
    value: "CASH",
    label: "Cash",
  },
];

export const paymentTypeOptionsInitialValue = paymentTypeOptions.map(
  ({ value }) => value,
);

export const paymentStatusOptions = [
  {
    value: "approved",
    label: "Approved",
  },
  {
    value: "pending",
    label: "Pending",
  },
  // {
  //   value: "on_hold",
  //   label: "Withhold",
  // },
  {
    value: "declined",
    label: "Declined",
  },
  {
    value: "paid",
    label: "Paid out",
  },
  {
    value: "refund",
    label: "Refund",
  },
  {
    value: "chargeback",
    label: "Chargeback",
  },
];

export const paymentStatusOptionsInitialValue = paymentStatusOptions.map(
  ({ value }) => value,
);

export const bookingListInitalPaymentFilterValue = {
  payment_type: paymentTypeOptions.map(({ value }) => value),
  payment_status: paymentStatusOptions.map(({ value }) => value),
};

export const bookingActionOptions = [
  // {
  //   id: 0,
  //   value: "approve",
  //   label: "Approve",
  //   icon: ApproveIcon,
  // },
  // {
  //   id: 1,
  //   value: "on_hold",
  //   label: "Withhold",
  //   icon: DeclineIcon,
  // },
  {
    id: 2,
    value: "exportCsv",
    label: "Export CSV",
    icon: ExportIcon,
  },

  // TODO: Super admin only can see this button
  {
    id: 3,
    value: "syncBookings",
    label: "Sync",
    icon: Sync,
  },
];
