import {
  usePredefinedDriverDocumentTypes,
  usePredefinedVehicleDocumentTypes,
} from "../../documents";

import { DocumentType } from "../../documents/DocumentType";
import {
  useCurrentDriverApplication,
  useLatestDocumentByStatusByType,
} from "../OnboardingBreadcrumbs";

export function useApplicationCompleted() {
  const latestDocumentByStatusByType = useLatestDocumentByStatusByType();
  const driverApplication = useCurrentDriverApplication();
  const hasOwnVehicle = driverApplication?.vehicle_registration !== null;

  const predefinedDriverDocumentTypes = usePredefinedDriverDocumentTypes();

  const areDriverDocumentsReady = predefinedDriverDocumentTypes.every(
    (type) =>
      latestDocumentByStatusByType?.[type.id]?.last !== null &&
      latestDocumentByStatusByType?.[type.id]?.last !== undefined,
  );

  const predefinedVehicleDocumentTypesToSubmit =
    usePredefinedVehicleDocumentTypes();

  const areVehicleDocumentsReady = predefinedVehicleDocumentTypesToSubmit
    .filter(({ id }) => id !== DocumentType.VEHICLE_DETAILS)
    .every((type) => {
      return (
        latestDocumentByStatusByType?.[type.id]?.last !== null &&
        latestDocumentByStatusByType?.[type.id]?.last !== undefined
      );
    });

  if (!hasOwnVehicle) {
    return areDriverDocumentsReady;
  }

  return areDriverDocumentsReady && areVehicleDocumentsReady;
}
