export const paths = {
  getFleetPublicInfo: (fleetId) => `fleets/${fleetId}/public`,
  getFleetMarketingPage: (fleetSlug) => `fleets/${fleetSlug}/marketing-page`,
  getFleets: () => "fleets",
};

export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      getFleetPublicInfoById: build.query({
        query: ({ fleetId }) => ({
          url: paths.getFleetPublicInfo(fleetId),
        }),
      }),

      getMarketingPageByFleetSlug: build.query({
        query: ({ fleetSlug }) => ({
          url: paths.getFleetMarketingPage(fleetSlug),
        }),
      }),

      getFleets: build.query({
        query: ({ params } = {}) => ({
          url: paths.getFleets(),
          params,
        }),
      }),

      getFleetById: build.query({
        query: ({ params, id } = {}) => ({
          url: `fleets/${id}`,
          params,
        }),
      }),

      getWalletFleets: build.query({
        query: () => ({
          url: "fleets",
          params: {
            for_role: "driver",
          },
        }),
      }),

      getFleetUsers: build.query({
        query: ({ id }) => ({
          url: `fleets/${id}/users`,
        }),
      }),

      companyIcabbyModifySubscription: build.mutation({
        query: ({ action, id }) => ({
          url: `fleets/${id}/icabbi-${action}`,
          method: "PATCH",
        }),
      }),

      getFleetIntegrations: build.query({
        query: () => ({
          url: "fleets/integrations",
        }),
      }),

      createEditFleet: build.mutation({
        query: ({ method, data, fleetId }) => ({
          method,
          data,
          url: fleetId ? `fleets/${fleetId}` : "fleets",
        }),
      }),

      modifySettings: build.mutation({
        query: ({ fleetId, data }) => ({
          method: "PATCH",
          url: `fleets/${fleetId}`,
          data,
        }),
      }),

      exportPayouts: build.mutation({
        query: ({ fleetId, data }) => ({
          method: "POST",
          url: `fleets/${fleetId}/export-items`,
          data,
        }),
      }),

      getFleetUsersBySearchTerm: build.query({
        keepUnusedDataFor: 0,
        query: ({ term, fleetId }) => ({
          params: {
            term,
          },
          url: `fleets/${fleetId}/users`,
        }),
      }),
    }),
  });
}
