import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  earningsFiltersData: {
    payment_types: ["CARD", "INVOICE", "CASH"],
    timeframe: "today",
  },
};

export const earningsFiltersSlice = createSlice({
  name: "earningsFiltersData",
  initialState,
  reducers: {
    handleEarningFilters: (state, action) => {
      const { type, item } = action.payload;
      state.earningsFiltersData = {
        ...state.earningsFiltersData,
        [type]: item,
      };
    },
  },
});

export const { handleEarningFilters } = earningsFiltersSlice.actions;

export default earningsFiltersSlice.reducer;
