import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SubTopNavigator } from "components/SubTopNavigator";
import "./Fleet.scss";
import FleetForm from "./FleetForm/FleetForm";

export const Fleet = ({ isCreatePage = false }) => {
  const { fleetId } = useParams();
  const { companies } = useSelector((state) => state.companies);

  const fleet = companies?.find((c) => c.id == fleetId);

  return (
    <div className="fleet-wrapper">
      <SubTopNavigator
        pathName="console/admin"
        subject={isCreatePage ? "Create Company" : fleet?.name || ""}
      />
      <FleetForm {...{ fleetId, fleet }} />
    </div>
  );
};

export default Fleet;
