import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
  setSelectedFleetIdToLocalStorage,
  setSelectedFleetId,
  normalizeFleetId,
} from "./slices/selectedFleetIdSlice";

export const syncSelectedFleetWithLocalStorageMiddleware =
  createListenerMiddleware();

syncSelectedFleetWithLocalStorageMiddleware.startListening({
  actionCreator: setSelectedFleetId,
  effect: (action) => {
    setSelectedFleetIdToLocalStorage(normalizeFleetId(action.payload));
  },
});
