import { useTranslation } from "react-i18next";

import { routes } from "routes";
import { useAuth } from "hooks/useAuth";

import { WaveLayout } from "components/WaveLayout";
import { Container } from "components/Container";
import { Title } from "components/Title";
import { Description } from "components/Description";
import { Button } from "components/Button";

import { useOpenFleetView } from "../useOpenFleetView";

export const WelcomePage = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const { open: openFleetView, isLoading } = useOpenFleetView();

  return (
    <WaveLayout>
      <Container width="small">
        <Title>{t("pageWelcome.greeting", { userName: user.name })}</Title>
        <Description>{t("pageWelcome.whatWouldYouLikeDo")}</Description>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            marginTop: 20,
          }}
        >
          <Button
            to={routes.walletHome.getURL()}
            block
            unsetLetterSpacing
            shape="round"
            fontSize="lg"
          >
            <div style={{ padding: 12, textAlign: "center" }}>
              <div style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                {t("pageWelcome.goToDriverView")}
              </div>
              <div style={{ marginTop: 8, fontSize: "initial" }}>
                {t("pageWelcome.driverViewDescription")}
              </div>
            </div>
          </Button>

          <Button
            onClick={openFleetView}
            loading={isLoading}
            block
            unsetLetterSpacing
            shape="round"
            fontSize="lg"
          >
            <div style={{ padding: 12, textAlign: "center" }}>
              <div style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                {t("pageWelcome.goToFleetView")}
              </div>
              <div style={{ marginTop: 8, fontSize: "initial" }}>
                {t("pageWelcome.fleetViewDescription")}
              </div>
            </div>
          </Button>
        </div>
      </Container>
    </WaveLayout>
  );
};
