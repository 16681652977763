import { useTranslation } from "react-i18next";
import { Form, Input, Modal } from "antd";

import { useAuth } from "hooks/useAuth";
import { api } from "api";
import { showErrorMsg, showSuccessMsg } from "helpers/windowMassages";

import { Field } from "components/Field";
import { Button } from "components/Button";

import styles from "./UpdatePasswordModal.module.scss";

export function UpdatePasswordModal({ visible, onModalClose }) {
  const { t } = useTranslation();

  const { user } = useAuth();
  const [updatePassword, { isLoading }] =
    api.endpoints.updateUser.useMutation();

  const [form] = Form.useForm();

  const onSubmit = (data) => {
    if (!user) {
      return;
    }

    const providedPassword = data?.password;
    const confirmedPassword = data?.confirm_password;
    const currentPassword = data?.current_password;

    if (confirmedPassword !== providedPassword) {
      form.setFields([
        {
          errors: [t("error.passwordsNotMatch")],
          value: confirmedPassword,
          name: "confirm_password",
        },
      ]);
      return;
    }

    updatePassword({
      id: user?.id,
      body: {
        email: user?.email,
        password: providedPassword,
        password_confirmation: confirmedPassword,
        current_password: currentPassword,
      },
    })
      .unwrap()
      .then(() => {
        showSuccessMsg({
          content: t("success.passwordUpdated"),
        });
        onModalClose();
        form.resetFields();
      })
      .catch(() => {
        showErrorMsg({
          content: t("error.somethingWentWrong"),
        });
      });
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      centered
      closable={false}
      onCancel={onModalClose}
      className={styles.updatePasswordContainer}
    >
      <Form
        form={form}
        layout="vertical"
        validateMessages={{
          required: t("error.fieldRequired"),
        }}
        onFinish={onSubmit}
      >
        <Field label={t("field.currentPassword")} htmlFor="password" dense>
          <Form.Item
            name="current_password"
            rules={[
              {
                required: true,
                message: t("error.passwordRequired"),
              },
            ]}
          >
            <Input.Password id="password" />
          </Form.Item>
        </Field>

        <Field label={t("field.newPassword")} htmlFor="newPassword" dense>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t("error.passwordRequired"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password").length >= 8) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t("error.passwordMustBeLonger")),
                  );
                },
                validateTrigger: "onBlur",
              }),
            ]}
          >
            <Input.Password id="newPassword" />
          </Form.Item>
        </Field>

        <Field
          label={t("field.confirmNewPassword")}
          htmlFor="confirmNewPassword"
          dense
        >
          <Form.Item
            name="confirm_password"
            rules={[
              {
                required: true,
                message: t("error.passwordRequired"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t("error.passwordsNotMatch")),
                  );
                },
              }),
            ]}
          >
            <Input.Password id="confirmNewPassword" />
          </Form.Item>
        </Field>
        <Button
          block
          uppercase
          variant="primary"
          shape="round"
          loading={isLoading}
          disabled={isLoading}
          type="submit"
        >
          {t("action.submit")}
        </Button>
      </Form>
    </Modal>
  );
}
