/**
 * Returns the value of the `content` field of relevant items.
 * Relevant items are those, which has `true` as a value of the `condition` field.
 *
 * This function is helpful in building a menu.
 *
 * @param {Array<{ condition: boolean, content: * }>} items - specific shape of items
 * @returns {Array<*>} - the values of the `content` fields of valid items
 */
export const getContentOfRelevantItems = (items) => {
  return items.filter((item) => item.condition).map((item) => item.content);
};
