import { ReactComponent as ViewDetailsIcon } from "assets/icons/eyeIcon.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
import { getContentOfRelevantItems } from "helpers/getContentOfRelevantItems";

export const driverRecordActionOptions = (
  showDriverInvite,
  canRemoveDriver = false,
  isDriverActive = true,
) => {
  return getContentOfRelevantItems([
    {
      condition: true,
      content: {
        value: "viewDetail",
        label: "View Detail",
        icon: ViewDetailsIcon,
      },
    },
    {
      condition: showDriverInvite,
      content: {
        value: "inviteUser",
        label: "Invite User",
        icon: MailIcon,
      },
    },
    {
      condition: canRemoveDriver && isDriverActive,
      content: {
        value: "removeDriver",
        label: "Remove Driver",
        icon: MailIcon,
      },
    },
  ]);
};
