import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialized: false,
};

export const launchDarklySlice = createSlice({
  name: "launchDarkly",
  initialState,
  reducers: {
    initialized: (state) => {
      state.initialized = true;
    },
  },
});

export const { initialized } = launchDarklySlice.actions;

export function selectLaunchDarklyInitialized(state) {
  return state.launchDarkly.initialized;
}
