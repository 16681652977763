import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { message } from "antd";

import { api, UserRoles } from "api";
import { routes } from "routes";

import { WaveLayout } from "components/WaveLayout";
import { Container } from "components/Container";
import { Title } from "components/Title";
import { Spacer } from "components/Spacer";

import { CreateNewPasswordForm } from "./CreateNewPasswordForm";

export function CreateNewPasswordPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") ?? "";
  const token = searchParams.get("token") ?? "";

  const [updatePassword] = api.endpoints.updatePassword.useMutation();
  const [logIn] = api.endpoints.login.useMutation();

  const onSubmit = useCallback(
    ({ password, passwordConfirm }) => {
      message.loading({
        content: t("processing.changingPassword"),
        key: "sign-in",
      });

      updatePassword({
        token,
        body: {
          email,
          password,
          password_confirmation: passwordConfirm,
        },
      })
        .unwrap()
        .then(() => {
          message.loading({
            content: t("processing.signingIn"),
            key: "sign-in",
          });

          logIn({
            email,
            password,
          })
            .unwrap()
            .then((res) => {
              const { data, meta } = res;
              localStorage.setItem("auth_token", JSON.stringify(meta.token));
              const roles = data?.roles || [];

              const isAdmin = [
                UserRoles.FLEET_ADMIN,
                UserRoles.FLEET_ADMIN_READONLY,
                UserRoles.SUPER_ADMIN,
              ].some((element) => roles.includes(element));

              const isDriver = roles.includes(UserRoles.DRIVER);

              const isDriverAndAdmin = isDriver && isAdmin;

              message.success({
                content: t("success.signedIn"),
                key: "sign-in",
              });

              if (isDriverAndAdmin) {
                return navigate(routes.welcome.getURL(), { replace: true });
              }

              if (isAdmin) {
                return navigate("/console/dashboard", { replace: true });
              }

              if (isDriver) {
                return navigate(routes.walletHome.getURL(), { replace: true });
              }
            })
            .catch((error) => {
              message.error({
                content:
                  error?.data?.error?.message ??
                  t("error.passwordOrUsernameIncorrect"),
                key: "sign-in",
              });
            });
        })
        .catch((error) => {
          message.error({
            content: error?.data?.error?.message,
            key: "sign-in",
          });
        });
    },
    [t, email, token, updatePassword, logIn],
  );

  return (
    <WaveLayout>
      <Container width="small">
        <Title>{t("pagePasswordCreateNew.createNewPassword")}</Title>
        <Spacer />
        <CreateNewPasswordForm onSubmit={onSubmit} />
      </Container>
    </WaveLayout>
  );
}
