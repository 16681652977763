import { api } from "api";
import { useTranslation } from "react-i18next";
import { useSelectedFleet } from "hooks/useSelectedFleet";

import { SelectedCompanyFeatureFlagRequired } from "components/SelectedCompanyFeatureFlagRequired";
import { LoadingComp } from "components/LoadingComp";
import {
  GeneralLayoutContent,
  GeneralLayoutHeader,
} from "components/GeneralLayout";

import { ReactComponent as ProfileIcon } from "assets/icons/Wallet/profile.svg";

import { Profile } from "./components/Profile";
import { ProfilePageContainer } from "./components/ProfilePageContainer";

export function ProfilePage() {
  const { t } = useTranslation();
  const { selectedFleet, selectedFleetId } = useSelectedFleet();

  const { data: driver, isLoading: isDriverQueryLoading } =
    api.endpoints.getDriver.useQuery(selectedFleetId, {
      skip: !selectedFleetId,
    });
  const driverProfile = driver?.data;

  return (
    <SelectedCompanyFeatureFlagRequired allowByFeatureFlags={() => true}>
      <GeneralLayoutContent
        header={
          <GeneralLayoutHeader
            title={t("title.profile")}
            icon={<ProfileIcon />}
          />
        }
      >
        {isDriverQueryLoading ? (
          <ProfilePageContainer>
            <LoadingComp loading />
          </ProfilePageContainer>
        ) : (
          <ProfilePageContainer>
            <Profile fleet={selectedFleet} profile={driverProfile} />
          </ProfilePageContainer>
        )}
      </GeneralLayoutContent>
    </SelectedCompanyFeatureFlagRequired>
  );
}
