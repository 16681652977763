import React from "react";
import styles from "./PageHeader.module.scss";
import { useIsMobile } from "hooks/useMobile";

export const PageHeader = ({ title, icon, children }) => {
  const isMobile = useIsMobile();

  return (
    <header className={styles.pageHeader}>
      {icon && !isMobile && (
        <div className={styles.pageHeader__icon}>{icon}</div>
      )}
      <h1 className={styles.pageHeader__title}>{title}</h1>
      {children}
    </header>
  );
};
