import SideBarIcons from "../../../assets/icons/Sidebar";
import { getContentOfRelevantItems } from "../../../helpers/getContentOfRelevantItems";

/**
 * Returns the list of menu items for fleet page.
 * Uses parameters to conditionally add menu items.
 *
 * @param {boolean} isDriver - whether the user is a driver
 * @param {boolean} isSuperAdmin - whether the user is a super admin
 * @returns {Array<{ label: string, key: string, icon: JSX.Element }>} list of
 *   menu items for fleet page
 */
const fleetMenuItems = (isDriver, isSuperAdmin) => {
  return getContentOfRelevantItems([
    {
      condition: true,
      content: {
        label: "Dashboard",
        key: "/console/dashboard",
        icon: <SideBarIcons.DashboardIcon />,
      },
    },
    {
      condition: true,
      content: {
        label: "Transactions",
        key: "/console/transactions",
        icon: <SideBarIcons.BookingsIcon />,
      },
    },
    {
      condition: true,
      content: {
        label: "Drivers",
        key: "/console/drivers",
        icon: <SideBarIcons.DriversIcon />,
      },
    },
    {
      condition: true,
      content: {
        label: "Payout settings",
        key: "/console/payments",
        icon: <SideBarIcons.PaymentSettIcon />,
      },
    },
    {
      condition: true,
      content: {
        label: "Payout reports",
        key: "/console/reports",
        icon: <SideBarIcons.ReportsIcon />,
      },
    },
    {
      condition: true,
      content: {
        label: "User management",
        key: "/console/users",
        icon: <SideBarIcons.UserManagementIcon />,
      },
    },
    {
      condition: isSuperAdmin,
      content: {
        label: "Admin",
        key: "/console/admin",
        icon: <SideBarIcons.Admin />,
      },
    },
    {
      condition: isDriver,
      content: {
        label: "Driver View",
        key: "switch_wallet",
        icon: <SideBarIcons.SwitchIcon />,
      },
    },
    {
      condition: true,
      content: {
        label: "Sign out",
        key: "signout",
        icon: <SideBarIcons.Logout />,
      },
    },
  ]);
};

export default fleetMenuItems;
