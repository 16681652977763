import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { api } from "api";

import { Field } from "components/Field";
import { Input } from "components/Input";
import { Button } from "components/Button";

import styles from "./VehicleDetails.module.scss";

export function DvlaLookupServiceForm({
  data,
  errors,
  setErrors,
  onVehicleDetailsChange,
  onVehicleFieldsetChange,
}) {
  const { t } = useTranslation();

  const [getInfo, dvlaResult] = api.endpoints.getDvla.useMutation();

  useEffect(
    function synchronizeDvlaDataWithData() {
      if (!dvlaResult?.data) return;

      onVehicleDetailsChange({
        make: dvlaResult?.data?.make ?? "",
        colour: dvlaResult?.data?.colour ?? "",
        emissions: dvlaResult?.data?.co2Emissions ?? "",
      });
    },
    [dvlaResult?.data, onVehicleDetailsChange],
  );

  useEffect(
    function synchronizeDvlaErrorsWithErrors() {
      if (!dvlaResult?.isError) {
        setErrors((prevState) => ({
          ...prevState,
          registration: undefined,
        }));

        return;
      }

      onVehicleDetailsChange({
        make: "",
        colour: "",
        emissions: "",
      });

      const errorDetail = dvlaResult?.error?.data?.errors?.[0]?.detail;
      setErrors((prevState) => ({
        ...prevState,
        registration: errorDetail,
      }));
    },
    [
      dvlaResult?.isError,
      onVehicleDetailsChange,
      setErrors,
      dvlaResult?.error?.data?.errors,
    ],
  );

  function onDvlaRegistrationSubmit(event) {
    event.preventDefault();
    const { registration } = data;

    const errors = getRegistrationValidationErrors({
      registration,
      t,
    });
    setErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    getInfo({ reg: registration });
  }

  return (
    <div>
      <form onSubmit={onDvlaRegistrationSubmit}>
        <Field
          htmlFor="registration"
          label={t("field.vehicleRegistration")}
          error={errors.registration}
        >
          <div className={styles.row}>
            <Input
              id="registration"
              name="registration"
              value={data.registration}
              onChange={(event) => {
                onVehicleFieldsetChange(
                  "registration",
                  event.target.value.toUpperCase(),
                );
              }}
              invalid={errors.registration !== undefined}
            />
            <Button
              type="submit"
              variant="primary"
              shape="round"
              disabled={dvlaResult?.isLoading}
            >
              {t("action.check")}
            </Button>
          </div>
        </Field>
      </form>

      <div className={styles.row}>
        <Field label={t("field.vehicleMake")} htmlFor="make">
          <Input id="make" name="make" value={data.make} readOnly />
        </Field>

        <Field label={t("field.vehicleModel")} htmlFor="model">
          <Input id="model" name="model" value={data.model} readOnly />
        </Field>
      </div>

      <div className={styles.row}>
        <Field label={t("field.colour")} htmlFor="colour">
          <Input id="colour" name="colour" value={data.colour} readOnly />
        </Field>

        <Field label={t("field.co2Emissions")} htmlFor="emissions">
          <Input
            id="emissions"
            name="emissions"
            value={data.emissions}
            readOnly
          />
        </Field>
      </div>
    </div>
  );
}

function getRegistrationValidationErrors({ registration, t }) {
  const errors = {};

  if (registration?.length === 0) {
    errors.registration = t("error.registrationNumberRequired");
  }

  return errors;
}
