import clsx from "clsx";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { api } from "api";

import { SingleScreen } from "components/SingleScreen";
import { OnboardingBreadcrumbs } from "../OnboardingBreadcrumbs";

import styles from "./OnboardingWrapperScreen.module.scss";

export const OnboardingWrapperScreen = ({
  children,
  title,
  breadcrumbs = false,
}) => {
  const { t } = useTranslation();

  const { fleetSlug } = useParams();

  const { data: marketingData } =
    api.endpoints.getMarketingPageByFleetSlug.useQuery({
      fleetSlug,
    });

  const { fleet_id: fleetId } = marketingData?.data ?? {};

  const { data: fleetPublicData } =
    api.endpoints.getFleetPublicInfoById.useQuery(
      {
        fleetId,
      },
      { skip: fleetId === undefined || fleetId === null },
    );
  const { name } = fleetPublicData?.data ?? {};

  return (
    <div className={clsx(styles.screenWrapper)}>
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <div className={styles.titleWrapper}>
            <p className={styles.title}>
              {title ??
                (name === undefined
                  ? ""
                  : t("componentOnboardingWrapper.driveWithFleet", {
                      fleet: name,
                    }))}
            </p>
            {breadcrumbs ? (
              <p className={styles.subtitle}>
                {t("componentOnboardingWrapper.driverDocumentsSuffix")}
              </p>
            ) : null}
          </div>
          {breadcrumbs ? <OnboardingBreadcrumbs /> : null}
        </div>
        <SingleScreen wrapperClassName={clsx(styles.singleScreenWrapper)}>
          <div className={styles.fullContainer}>{children}</div>
        </SingleScreen>
      </div>
    </div>
  );
};
