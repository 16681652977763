export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      getDashboard: build.query({
        query: ({ fleetId, date_from, date_to }) => ({
          url: `dashboard`,
          params: { date_from, date_to, fleet_id: fleetId },
        }),
      }),
    }),
  });
}
