import React from "react";

export const InviteDriverIcon = ({
  signed_up_at,
  invited_at,
  stripe_details_submitted,
  stripe_payouts_enabled,
  is_active,
}) => {
  if (!is_active) {
    return <div className="status-tag grey">Deactivated</div>;
  }

  if (signed_up_at) {
    if (!stripe_details_submitted && !stripe_payouts_enabled) {
      return <div className="status-tag red">KYC Incomplete</div>;
    }

    if (stripe_details_submitted && !stripe_payouts_enabled) {
      return <div className="status-tag blue">In review</div>;
    }

    return <div className="status-tag green">Onboarded</div>;
  }

  if (!invited_at) {
    return <div className="status-tag grey">Not Invited</div>;
  }

  return <div className="status-tag yellow">Invited</div>;
};
