import { forwardRef } from "react";
import { Radio } from "antd";
import "./RadioGroup.scss";
import clsx from "clsx";

export const RadioGroup = forwardRef(function RadioGroup(
  { className = "", list = [], ...otherProps },
  ref,
) {
  return (
    <Radio.Group
      className={clsx("custom-radio-group", className)}
      {...otherProps}
      ref={ref}
    >
      {list.map(({ value, label }) => (
        <Radio key={value} value={value}>
          {label}
        </Radio>
      ))}
    </Radio.Group>
  );
});
