import axios from "axios";
import { getReactAppBaseUrl } from "mocks/env";

const defaultOptions = {
  baseURL: getReactAppBaseUrl(),
};

export const axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use(function (config) {
  const token =
    config.url === "/auth/token-login"
      ? config.params.token
      : JSON.parse(localStorage.getItem("auth_token"));

  if (config.url === "/auth/token-login" && config.params.token !== undefined) {
    delete config.params.token;
  }

  config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.headers.patch["Accept"] = "application/json";
  return config;
});

export const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, data, params }) => {
    const { _customUrl, ...otherParams } = params ?? {};
    const hasCustomUrl = typeof _customUrl === "string";

    try {
      const result = await axiosInstance({
        url: hasCustomUrl ? _customUrl : baseUrl + url,
        method,
        data,
        params: otherParams,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
