import { useTranslation } from "react-i18next";
import { routes } from "routes";

import { SelectedCompanyFeatureFlagRequired } from "components/SelectedCompanyFeatureFlagRequired";
import {
  GeneralLayoutContent,
  GeneralLayoutHeader,
} from "components/GeneralLayout";

import { BookingItemInternal } from "./BookingItemInternal";

import { ReactComponent as BookingsIcon } from "assets/icons/Wallet/bookings.svg";

export function BookingItemPage() {
  const { t } = useTranslation();

  return (
    <SelectedCompanyFeatureFlagRequired
      allowByFeatureFlags={(flags) => flags.isDriverPayEnabled}
      redirectToWhenNotAllowed={routes.profilePage.getURL()}
    >
      <GeneralLayoutContent
        header={
          <GeneralLayoutHeader
            title={t("title.bookings")}
            icon={<BookingsIcon />}
          />
        }
      >
        <BookingItemInternal />
      </GeneralLayoutContent>
    </SelectedCompanyFeatureFlagRequired>
  );
}
