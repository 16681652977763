import { Navigate, useParams } from "react-router-dom";
import { useSelectedFleet } from "hooks/useSelectedFleet";
import { LoadingComp } from "components/LoadingComp";
import { useAuth } from "hooks/useAuth";
import { routes } from "routes";

import { DriverDocumentLayout } from "./DriverDocumentLayout";
import { VehicleDocumentForm } from "./components/VehicleDocumentForm";
import { useQueryDocumentTypeById } from "./useQueryDocumentTypeById";
import { useQueryDriverCustomDocumentTypes } from "./useQueryDocumentTypes";

export function VehicleDocumentPage({ isEditPage = false } = {}) {
  const { documentTypeId, vehicleId } = useParams();
  const { selectedDriverProfile, selectedFleetId } = useSelectedFleet();
  const { user } = useAuth();

  const {
    predefinedVehicleDocumentTypes,
    customVehicleDocumentTypes,
    isLoading: areDocumentTypesLoading,
  } = useQueryDriverCustomDocumentTypes(selectedDriverProfile?.id);

  const allowedVehiclesSet = user?.driver_profiles
    .flatMap(({ owner_vehicles, driver_vehicles }) => [
      ...owner_vehicles,
      ...driver_vehicles,
    ])
    .map(({ id }) => id.toString());

  const allowedDocumentsSet = [
    ...predefinedVehicleDocumentTypes,
    ...customVehicleDocumentTypes,
  ].map(({ id }) => id.toString());

  const { data: currentDocumentTypeDetails, isLoading } =
    useQueryDocumentTypeById({
      documentTypeId,
      currentCompanyId: selectedFleetId,
    });

  if (
    !allowedVehiclesSet.includes(vehicleId) ||
    !allowedDocumentsSet.includes(documentTypeId)
  ) {
    return <Navigate to={routes.documents.getURL()} replace />;
  }

  if (!currentDocumentTypeDetails || isLoading || areDocumentTypesLoading) {
    return (
      <DriverDocumentLayout>
        <LoadingComp loading fullScreen />
      </DriverDocumentLayout>
    );
  }

  return (
    <DriverDocumentLayout>
      <VehicleDocumentForm
        isEditPage={isEditPage}
        documentTypeId={currentDocumentTypeDetails.id}
        documentTypeDetails={currentDocumentTypeDetails}
      />
    </DriverDocumentLayout>
  );
}
