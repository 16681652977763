import clsx from "clsx";
import styles from "./Spacer.module.scss";

export function Spacer({ size = "medium" }) {
  return (
    <div
      className={clsx({
        [styles.small]: size === "small",
        [styles.medium]: size === "medium",
      })}
    />
  );
}
