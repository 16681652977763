import { Outlet } from "react-router-dom";

import { AuthRequired } from "components/AuthRequired";

export function OnboardingLayout() {
  return (
    <AuthRequired>
      <Outlet />
    </AuthRequired>
  );
}
