import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import clsx from "clsx";
import { Input } from "antd";

import useRoles from "hooks/useRoles";
import { useIsMobile } from "hooks/useMobile";
import { showInfoMsg } from "helpers/windowMassages";
import { RedirectToCompany, useQueryCompanies } from "hooks/useQueryCompanies";

import { api } from "api";

import List from "./List/List";
import { MenuToggle } from "components/MenuToggle/MenuToggle";

import { ReactComponent as UsersIcon } from "assets/icons/usersIcon.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

import "./UsersList.scss";

export const UsersList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get("search");
  const isSearchTermInvalid =
    typeof searchTerm === "string" &&
    searchTerm.length > 0 &&
    searchTerm.length < 3;

  useEffect(() => {
    /**
     * If the URL have invalid `searchTerm` value, then delete that value.
     */
    if (isSearchTermInvalid) {
      setSearchParams({});
    }
  }, [searchTerm]);

  function onSearch(event) {
    const { value } = event.target;

    if (value.length === 0) {
      return setSearchParams({});
    }

    if (value.length < 3) {
      return showInfoMsg({
        content: "The search term must be at least 3 characters long!",
      });
    }

    setSearchParams({ search: value });
  }

  const { currentCompanyId: id } = useQueryCompanies({
    redirectTo: RedirectToCompany.ADMIN,
  });
  const {
    data: usersData,
    isLoading,
    isFetching,
  } = api.endpoints.getFleetUsersBySearchTerm.useQuery(
    {
      term: searchTerm,
      fleetId: id,
    },
    {
      skip: !id || isSearchTermInvalid,
    },
  );

  const users = usersData?.data ?? [];

  const { isSuperAdmin, isFleetAdmin, isSupport } = useRoles();
  const isMobile = useIsMobile();

  const showInvitation = isSuperAdmin || isFleetAdmin || isSupport;

  return (
    <div
      className={clsx("userslist-wrapper", {
        "userslist-wrapper-loading": isLoading || isFetching,
      })}
    >
      <div className="header">
        {isMobile ? <MenuToggle /> : <UsersIcon />}
        <h1>User Management</h1>
        <Input
          placeholder="Search by name / email"
          defaultValue={searchTerm}
          prefix={<SearchIcon />}
          onPressEnter={onSearch}
          onBlur={onSearch}
        />
      </div>
      <List
        getNextUsers={() => {}}
        id={id}
        isLoading={isLoading || isFetching}
        showInvitation={showInvitation}
        usersList={users}
        usersData={usersData}
      />
    </div>
  );
};

export default UsersList;
