import clsx from "clsx";
import styles from "./Widget.module.scss";

export function WidgetContainer(props) {
  return <div className={styles.widgetContainer} {...props} />;
}

export function WidgetTitle(props) {
  return <h2 className={styles.widgetTitle} {...props} />;
}

export function WidgetStats({ size, ...otherProps }) {
  return (
    <div
      className={clsx(styles.widgetStats, {
        [styles.large]: size === "large",
      })}
      {...otherProps}
    />
  );
}

export function WidgetSmall(props) {
  return <div className={styles.widgetSmall} {...props} />;
}

export function WidgetLink({
  underlined = true,
  center = false,
  ...otherProps
}) {
  return (
    <a
      className={clsx(styles.widgetLink, {
        [styles.underlined]: underlined,
        [styles.center]: center,
      })}
      {...otherProps}
    />
  );
}
