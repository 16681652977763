import clsx from "clsx";
import styles from "./Details.module.scss";

export function Details({ label, children, first = false, last = false }) {
  return (
    <details
      className={clsx(styles.details, {
        [styles.noBorderBottom]: !last,
        [styles.roundedTop]: first,
        [styles.roundedBottom]: last,
      })}
    >
      <summary className={styles.summary}>
        <strong>{label}</strong>
      </summary>
      <div className={styles.content}>{children}</div>
    </details>
  );
}
