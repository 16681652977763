import { NavLinkInternal } from "../NavLinkInternal";
import { usePollingNotifications } from "hooks/usePollingNotifications";

export function NavLinkNotifications(props) {
  const { unread_notifications: unreadNotifications } =
    usePollingNotifications();

  return (
    <NavLinkInternal
      {...props}
      status={unreadNotifications > 0 ? "warning" : undefined}
      badge={
        unreadNotifications > 9
          ? "more"
          : unreadNotifications < 1
            ? undefined
            : unreadNotifications
      }
    />
  );
}
