import React from "react";
import { ReactComponent as TriangleIcon } from "assets/icons/triangle.svg";
import styles from "./DifferenceTag.module.scss";

export const DifferenceTagType = {
  Positive: "positive",
  Negative: "negative",
};

export const DifferenceTag = ({ text, type }) => (
  <div
    className={`${styles.differenceTag} ${styles[`differenceTag--${type}`]}`}
  >
    <TriangleIcon />
    <span
      aria-label="difference-tag-text"
      className={styles.differenceTag__text}
    >
      {text}
    </span>
  </div>
);
