import { useState } from "react";

export function Form({
  initialValues = {},
  initialErrors = {},
  validationRules = [],
  children = () => null,
  onSubmit = (event) => {},
  noValidate = false,
} = {}) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(initialErrors);

  function mergeValues(objectToMerge) {
    return setValues((prevValue) => ({
      ...prevValue,
      ...objectToMerge,
    }));
  }

  function onChange(event) {
    return mergeValues({
      [event.target.name]: event.target.value ?? event.target.checked,
    });
  }

  function getErrors() {
    return getFormErrors(values, validationRules);
  }

  function onSubmitInternal(event) {
    event.preventDefault();
    const errors = getErrors();
    setErrors(errors);

    if (hasErrors(errors)) {
      return;
    }

    return onSubmit(values);
  }

  return (
    <form onSubmit={onSubmitInternal} noValidate={noValidate}>
      {children({
        values,
        errors,
        onChange,
      })}
    </form>
  );
}

function getFormErrors(form, rules) {
  const errors = {};

  for (let field in form) {
    const firstFailedFieldRule = rules
      .filter((rule) => rule.field === field)
      .find((rule) => !rule.isValid(form[field], form));

    errors[field] = firstFailedFieldRule?.error;
  }

  return errors;
}

function hasErrors(errors) {
  return Object.values(errors).filter(Boolean).length > 0;
}
