import { useState } from "react";
import { ReactComponent as VerticalDotsIcon } from "assets/icons/verticalDots.svg";
import ClickAwayListener from "react-click-away-listener";
import "./CustomDropdown.scss";
import clsx from "clsx";
import { useIsMobile } from "hooks/useMobile";
import { Drawer } from "antd";

export const CustomDropdown = ({
  options,
  onItemSelect = () => {},
  mobileContent,
  className,
}) => {
  const [open, setOpen] = useState(false);

  const isMobile = useIsMobile();

  const handleVisibility = () => {
    setOpen((prevState) => !prevState);
  };
  const onSelect = (item) => {
    handleVisibility();
    onItemSelect(item);
  };

  if (isMobile && mobileContent) {
    return (
      <div className="custom-dropdown-wrapper">
        <VerticalDotsIcon onClick={handleVisibility} />
        <Drawer
          onClose={handleVisibility}
          placement="bottom"
          visible={open}
          closable={false}
          className="mobile-filter-dropdown"
        >
          {mobileContent}
        </Drawer>
      </div>
    );
  }

  return (
    <>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div className="custom-dropdown-wrapper">
          <VerticalDotsIcon
            className={className}
            onClick={handleVisibility}
            aria-label="custom-dropdown-dots"
          />
          {open && options && (
            <div className="options">
              {options.map((item) => {
                const { label, value, icon: Icon, disabled } = item;
                return (
                  <div
                    key={value}
                    className={clsx("option", disabled && "disabled")}
                    onClick={() => onSelect(value)}
                  >
                    <h4>{label}</h4>
                    <Icon />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </ClickAwayListener>
    </>
  );
};
