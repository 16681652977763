import { useTranslation } from "react-i18next";
import { SelectedCompanyFeatureFlagRequired } from "components/SelectedCompanyFeatureFlagRequired";
import {
  GeneralLayoutContent,
  GeneralLayoutHeader,
} from "components/GeneralLayout";

import { NotificationsPage } from "./NotificationsPage";

import { ReactComponent as NotificationIcon } from "assets/icons/Wallet/notification.svg";

export function NotificationsPageWithGates() {
  const { t } = useTranslation();

  return (
    <SelectedCompanyFeatureFlagRequired allowByFeatureFlags={() => true}>
      <GeneralLayoutContent
        header={
          <GeneralLayoutHeader
            title={t("title.notifications")}
            icon={<NotificationIcon />}
          />
        }
      >
        <NotificationsPage />
      </GeneralLayoutContent>
    </SelectedCompanyFeatureFlagRequired>
  );
}
