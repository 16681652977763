import { format } from "date-fns";
import { TagTypes } from "../TagTypes";

export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      getPayouts: build.query({
        providesTags: [TagTypes.DRAWDOWN_RELATED],
        query: ({
          fleetId,
          driverProfileId,
          perPage,
          cursor,
          payoutDateFrom,
          payoutDateTo,
        }) => ({
          url: "payouts",
          params: {
            fleet_id: fleetId,
            driver_profile_id: driverProfileId,
            per_page: perPage,
            cursor,
            arrival_from: payoutDateFrom,
            arrival_to: payoutDateTo,
          },
        }),
      }),
    }),
  });
}

const API_DATE_FORMAT = "yyyy-MM-dd";

/**
 * Use this function when calling `useQuery` hook
 * to assure that it is called with strings,
 * which RTK is able to memoize.
 */
export function formatDate(date) {
  return date instanceof Date ? format(date, API_DATE_FORMAT) : undefined;
}
