import { useTranslation } from "react-i18next";

import { WaveLayout } from "components/WaveLayout";
import { Container } from "components/Container";
import { Title } from "components/Title";
import { Spacer } from "components/Spacer";

import { SignInForm } from "./SignInForm";

export function SignInPage() {
  const { t } = useTranslation();

  return (
    <WaveLayout>
      <Container width="small">
        <Title>{t("pageSignIn.welcome")}</Title>
        <Spacer />
        <SignInForm />
      </Container>
    </WaveLayout>
  );
}
