import { Outlet } from "react-router-dom";

import { allowDrivers } from "hooks/useRoles";

import { AuthRequired } from "components/AuthRequired";
import { RolesRequired } from "components/RolesRequired";
import { GeneralLayout } from "components/GeneralLayout";
import { SelectedFleetRequired } from "components/SelectedFleetRequired";
import { AcceptedLatestUserAgreementVersionRequired } from "components/AcceptedLatestUserAgreementVersionRequired";

export function DriverLayout() {
  return (
    <AuthRequired>
      <RolesRequired allowByRoles={allowDrivers}>
        <SelectedFleetRequired>
          <AcceptedLatestUserAgreementVersionRequired>
            <GeneralLayout>
              <Outlet />
            </GeneralLayout>
          </AcceptedLatestUserAgreementVersionRequired>
        </SelectedFleetRequired>
      </RolesRequired>
    </AuthRequired>
  );
}
