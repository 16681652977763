import clsx from "clsx";
import { ReactComponent as BurgerIcon } from "./burger.svg";
import styles from "./BurgerButton.module.scss";

export function BurgerButton({
  badge,
  badgeImportance = "danger",
  ...otherProps
}) {
  return (
    <button
      className={clsx(styles.burgerButton, {
        [styles.withBadge]: Boolean(badge),
      })}
      type="button"
      {...otherProps}
    >
      <BurgerIcon />

      {Boolean(badge) ? (
        <div
          className={clsx(styles.badge, {
            [styles.badgeDanger]: badgeImportance === "danger",
            [styles.badgeWarning]: badgeImportance === "warning",
          })}
        >
          {badge}
        </div>
      ) : null}
    </button>
  );
}
