import React from "react";
import { useIsMobile } from "hooks/useMobile";
import { Drawer } from "antd";
import { ConfirmationModal } from "components/ConfirmationModal";
import styles from "./RemoveDriverModal.module.scss";
import { Button } from "components/Button";

export const RemoveDriverModal = ({ selectedDriver, onClose, onConfirm }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Drawer
        onClose={onClose}
        placement="bottom"
        visible={Boolean(selectedDriver)}
        closable={false}
        className="mobile-filter-dropdown"
      >
        <div className={styles.mobileDrawerContainer}>
          <p>
            Are you sure you want to remove{" "}
            <strong>{selectedDriver?.name}</strong>
            <br />
            from <strong>{selectedDriver?.fleet?.name}</strong>?
          </p>
          <Button
            variant="primary"
            block
            shape="round"
            fontWeight="bold"
            onClick={onConfirm}
          >
            Yes
          </Button>
        </div>
      </Drawer>
    );
  }

  return (
    <ConfirmationModal
      visible={Boolean(selectedDriver)}
      onCancel={onClose}
      onConfirm={onConfirm}
    >
      Are you sure you want to remove <strong>{selectedDriver?.name}</strong>
      <br />
      from <strong>{selectedDriver?.fleet?.name}</strong>?
    </ConfirmationModal>
  );
};
