import { useMemo } from "react";
import { useParams, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { api } from "api";
import { routes } from "routes";
import countries from "countries.json";

import { useAuth } from "hooks/useAuth";

import { DocumentType, ValidationWrapper } from "pages/documents";

import { Title } from "components/Title";
import { Description } from "components/Description";
import { Spacer } from "components/Spacer";

import { LoadingComp } from "components/LoadingComp";

import { CreateDriverProfileForm } from "./CreateDriverProfileForm";

export const getCountryDialCode = (country) => {
  const defaultPhoneCode = "+44";
  return (
    countries.countries.find(({ code }) => code === country)?.dial_code ||
    defaultPhoneCode
  );
};

export const OnboardingCreateDriverProfileInternal = () => {
  const { t } = useTranslation();

  const { fleetSlug } = useParams();

  const { data, isLoading: isMarketingPageLoading } =
    api.endpoints.getMarketingPageByFleetSlug.useQuery({
      fleetSlug,
    });
  const fleetId = data?.data?.fleet_id ?? null;

  const { data: fleetPublicData, isLoading: isFleetPublicInfoLoading } =
    api.endpoints.getFleetPublicInfoById.useQuery(
      {
        fleetId,
      },
      { skip: fleetId === undefined || fleetId === null },
    );
  const { name, country: fleetCountry } = fleetPublicData?.data ?? {};

  const country = countries.countries.find(({ code }) => code === fleetCountry);

  const { user } = useAuth();

  const driverApplicationToCurrentFleet = user?.driver_applications?.find(
    (application) => application.fleet_id === fleetId,
  );
  const userHasDriverApplicationToCurrentFleet =
    Boolean(driverApplicationToCurrentFleet) ?? false;

  const initialValues = useMemo(() => {
    const firstDriverProfile = user?.driver_profiles?.[0];
    const names = firstDriverProfile?.name?.split(" ") ?? ["", ""];
    const [firstName, ...otherNames] = names; // in case the driver has the last name which contains several words
    const lastName = otherNames.join("");

    return {
      firstName: firstName,
      lastName: lastName,
      phone: "+44-",
      acceptAgreement: false,
    };
  }, [user]);

  if (isMarketingPageLoading || isFleetPublicInfoLoading) {
    return <LoadingComp loading fullScreen />;
  }

  if (userHasDriverApplicationToCurrentFleet) {
    return (
      <Navigate
        to={routes.onboardingDocument.getURL({
          fleetSlug,
          documentTypeId: DocumentType.ADDRESS_PROOF,
        })}
      />
    );
  }

  return (
    <ValidationWrapper>
      <Title>{t("pageCreateDriverProfile.createDriverProfile")}</Title>
      <Description>
        {t("pageCreateDriverProfile.enterDetailsToGetStarted", {
          fleetName: name,
        })}
      </Description>
      <Spacer />
      <CreateDriverProfileForm
        fleetId={fleetId}
        fleetSlug={fleetSlug}
        initialValues={initialValues}
        fleetCountryCode={country}
      />
    </ValidationWrapper>
  );
};
