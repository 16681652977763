import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  password: "",
  fleetId: "",
};

export const onboardingSlice = createSlice({
  name: "onboardingSlice",
  initialState,
  reducers: {
    setOnboardingEmail: (state, action) => {
      state.email = action.payload;
    },
    setOnboardingPassword: (state, action) => {
      state.password = action.payload;
    },
    bulkSetOnboardingCredentials: (state, action) => {
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.fleetId = action.payload?.fleetId;
    },
    setOnboardingFleetId: (state, action) => {
      state.fleetId = action.payload;
    },
  },
});

export const {
  setOnboardingEmail,
  setOnboardingPassword,
  bulkSetOnboardingCredentials,
  setOnboardingFleetId,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
