import { forwardRef } from "react";
import ReactCountryFlag from "react-country-flag";
import countriesJSON from "../../countries.json";
import { Select } from "../Select";
import styles from "./SelectCountry.module.scss";

const countries = countriesJSON.countries;

export const SelectCountry = forwardRef(
  function SelectCountryInternal(props, ref) {
    return (
      <Select
        {...props}
        ref={ref}
        options={countries.map((country) => ({
          value: country.code,
          children: country.name,
        }))}
        renderIcon={({ value }) => (
          <ReactCountryFlag svg className={styles.flag} countryCode={value} />
        )}
      />
    );
  },
);
