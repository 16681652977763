import { getLatestTermsAndConditionsVersion } from "i18n";
import { TagTypes } from "../TagTypes";

export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      checkLatestUserAgreementVersion: build.query({
        providesTags: [TagTypes.ACCEPTANCE],
        query: () => ({
          url: "user-agreement-acceptances",
          params: {
            version: getLatestTermsAndConditionsVersion(),
          },
        }),
      }),

      acceptLatestUserAgreementVersion: build.mutation({
        invalidatesTags: [TagTypes.ACCEPTANCE],
        query: () => ({
          url: "user-agreement-acceptances",
          method: "POST",
          data: {
            version: getLatestTermsAndConditionsVersion(),
          },
        }),
      }),
    }),
  });
}
