import { useTranslation } from "react-i18next";
import { routes } from "routes";

import { SelectedCompanyFeatureFlagRequired } from "components/SelectedCompanyFeatureFlagRequired";
import {
  GeneralLayoutContent,
  GeneralLayoutHeader,
} from "components/GeneralLayout";

import { HomePageInternal } from "./HomePageInternal";

import { ReactComponent as HomeIcon } from "assets/icons/Wallet/home.svg";

export function HomePageWithGates() {
  const { t } = useTranslation();

  return (
    <SelectedCompanyFeatureFlagRequired
      allowByFeatureFlags={(flags) => flags.isDriverPayEnabled}
      redirectToWhenNotAllowed={routes.documents.getURL()}
    >
      <GeneralLayoutContent
        header={
          <GeneralLayoutHeader title={t("title.home")} icon={<HomeIcon />} />
        }
      >
        <HomePageInternal />
      </GeneralLayoutContent>
    </SelectedCompanyFeatureFlagRequired>
  );
}
