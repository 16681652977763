import { format } from "date-fns";
import { FieldType } from "./FieldType";

export function encodeExtraInfo(decodedExtraInfo, documentType) {
  const initialValue = {};

  return documentType.extra_info.reduce(
    (encodedExtraInfoAccumulator, { name, type, parameter }) => {
      return {
        ...encodedExtraInfoAccumulator,
        [parameter]: encodeValue(decodedExtraInfo[name], type),
      };
    },
    initialValue,
  );
}

export function encodeValue(value, type) {
  if (type === FieldType.DATETIME && !(value instanceof Date)) {
    // an empty date field
    return null;
  }

  if (type === FieldType.DATETIME) {
    return format(value, "yyyy-MM-dd");
  }

  if (type === FieldType.NUMBER_INTEGER) {
    return parseInt(value);
  }

  return value;
}

export function decodeExtraInfo(encodedExtraInfo, documentType) {
  const initialValue = {};

  return documentType.extra_info.reduce(
    (decodedExtraInfoAccumulator, { name, type, parameter }) => ({
      ...decodedExtraInfoAccumulator,
      [name]: decodeValue(encodedExtraInfo?.[parameter], type),
    }),
    initialValue,
  );
}

export function decodeValue(value, type) {
  if (type === FieldType.TEXT) {
    return value ?? "";
  }

  if (type === FieldType.SELECT) {
    return value ?? "-";
  }

  if (type === FieldType.DATETIME) {
    return value ? new Date(value) : undefined;
  }

  return value;
}
