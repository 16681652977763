import { allowDriversWhoAdmins } from "hooks/useRoles";

import { AuthRequired } from "components/AuthRequired";
import { RolesRequired } from "components/RolesRequired";

import { WelcomePage } from "./WelcomePage";

export function WelcomePageWithGates() {
  return (
    <AuthRequired>
      <RolesRequired allowByRoles={allowDriversWhoAdmins}>
        <WelcomePage />
      </RolesRequired>
    </AuthRequired>
  );
}
