import { useEffect, useMemo, useState } from "react";
import { api } from "api";
import { isFeatureEnabled } from "featureFlags";

import { log } from "helpers/logger";

import { useQueryPredefinedDocumentTypes } from "./DocumentTypeDetails";

const logInternal = (...args) => log("useQueryDocumentTypeById.js", ...args);

const noDocuments = [];
export function useQueryDocumentTypeById({ documentTypeId, currentCompanyId }) {
  const predefinedDocumentTypes = useQueryPredefinedDocumentTypes();

  const {
    data: customDocumentTypesData,
    isLoading: areCustomDocumentTypesLoading,
  } = api.endpoints.getCustomDocumentTypes.useQuery(
    {
      fleet_id: currentCompanyId,
    },
    {
      skip:
        !isFeatureEnabled.CUSTOM_DOCUMENTS ||
        currentCompanyId === null ||
        currentCompanyId === undefined,
    },
  );

  const customDocumentTypes = useMemo(() => {
    return customDocumentTypesData?.data ?? noDocuments;
  }, [customDocumentTypesData]);

  const [data, setData] = useState(null);

  useEffect(() => {
    logInternal("choose source effect", "started");

    const predefinedDocumentTypeFound = predefinedDocumentTypes[documentTypeId];
    if (predefinedDocumentTypeFound) {
      logInternal(
        "choose source effect",
        "predefined document type found - now save it and exit",
        { predefinedDocumentTypeFound },
      );
      setData(predefinedDocumentTypeFound);
      return;
    }

    const customDocumentTypeFound = customDocumentTypes.find(
      (type) => String(type.id) === documentTypeId,
    );
    if (customDocumentTypeFound) {
      logInternal(
        "choose source effect",
        "custom document type found - now save it and exit",
        { customDocumentTypeFound },
      );
      setData(customDocumentTypeFound);
    }
  }, [documentTypeId, customDocumentTypes, predefinedDocumentTypes]);

  return { data, isLoading: areCustomDocumentTypesLoading };
}
