export const bookingsHeaderItems = [
  "date",
  "time",
  "driver ref",
  "booking",
  "name",
  "type",
  "fare",
  "payout",
  "status",
];

export const statusColor = {
  pending: "#FFA552",
  paid: "#47D061",
  approved: "#47D061",
  declined: "#EB5757",
  refunded: "#51A3EE",
  disputed: "#FFA552",
  "Charge back": "#EB57CA",
};
