import { Drawer } from "antd";
import React from "react";

export const DoubleDropdownMobile = ({ options, handleTypeDropdown, open }) => {
  return (
    <Drawer
      onClose={handleTypeDropdown}
      placement="bottom"
      visible={open}
      closable={false}
      className="mobile-filter-dropdown"
    >
      {options}
    </Drawer>
  );
};
