import { TagTypes } from "../TagTypes";

export function connect(apiSlice) {
  apiSlice.injectEndpoints({
    overrideExisting: false,

    endpoints: (build) => ({
      login: build.mutation({
        invalidatesTags: [TagTypes.ME],
        query: (data) => ({
          data,
          url: "login",
          method: "POST",
        }),
      }),

      logout: build.mutation({
        query: () => ({
          url: "logout",
          method: "POST",
        }),
      }),

      verifyEmail: build.mutation({
        invalidatesTags: [TagTypes.ME],
        query: ({ emailUrl }) => ({
          url: "",
          method: "GET",
          params: {
            _customUrl: emailUrl,
          },
        }),
      }),

      authWithToken: build.mutation({
        invalidatesTags: [TagTypes.ME],
        query: ({ token }) => ({
          url: "auth/token-login",
          method: "POST",
          params: {
            token: token,
          },
        }),
      }),

      getToken: build.query({
        query: () => ({
          method: "GET",
          url: "auth/token",
        }),
      }),
    }),
  });
}
