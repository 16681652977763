import { ReactComponent as Admin } from "./admin.svg";
import { ReactComponent as ArrowDown } from "./arrowDown.svg";
import { ReactComponent as BookingsIcon } from "./bookings.svg";
import { ReactComponent as CompactArrow } from "./compactArrow.svg";
import { ReactComponent as DashboardIcon } from "./dashboard.svg";
import { ReactComponent as DriversIcon } from "./drivers.svg";
import { ReactComponent as ExpandArrow } from "./expandArrow.svg";
import { ReactComponent as Logout } from "./logout.svg";
import { ReactComponent as PaymentSettIcon } from "./paymentSettings.svg";
import { ReactComponent as ReportsIcon } from "./reports.svg";
import { ReactComponent as SwitchIcon } from "./switch.svg";
import { ReactComponent as UserManagementIcon } from "./userManagement.svg";
import { ReactComponent as WalletIcon } from "./walletIcon.svg";

const SideBarIcons = {
  DashboardIcon,
  BookingsIcon,
  DriversIcon,
  PaymentSettIcon,
  ReportsIcon,
  UserManagementIcon,
  Admin,
  Logout,
  ExpandArrow,
  CompactArrow,
  ArrowDown,
  WalletIcon,
  SwitchIcon,
};

export default SideBarIcons;
