import { Input } from "antd";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";
import { ReactComponent as MailIcon } from "../../../../assets/icons/mail.svg";
import { ReactComponent as RefreshIcon } from "../../../../assets/icons/refresh.svg";
import "./Filters.scss";
import clsx from "clsx";

export const Filters = ({
  onSearch,
  inputValue,
  onClearSearch,
  selectedDrivers = {},
  onBulkInvite,
  onRefresh,
  disabledBulk,
  isMobile,
}) => {
  const areSelectedDrivers =
    Object.keys(selectedDrivers).length && !disabledBulk;

  const isDisabledBulkInvite = isMobile ? false : !areSelectedDrivers;

  return (
    <>
      <div className="filters">
        <Input
          placeholder="Search Driver Ref"
          prefix={<SearchIcon />}
          onPressEnter={onSearch}
          onChange={({ target }) => onClearSearch(target.value)}
          value={inputValue}
          allowClear
        />
        <div className="refresh-btn" onClick={onRefresh}>
          <RefreshIcon />
        </div>
        <div
          onClick={onBulkInvite}
          className={clsx(
            "new-driver-btn",
            isDisabledBulkInvite && "new-driver-btn-disabled",
          )}
        >
          <MailIcon />
          <p>Bulk Invite</p>
        </div>
      </div>
    </>
  );
};

export default Filters;
