import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Banner } from "components/Banner";
import { twoDecimals } from "helpers/twoDecimal";
import { calculateDriverCommissionValue } from "helpers/calculateDriverCommissionValue";
import { ReactComponent as VisaIcon } from "assets/icons/Wallet/visaCard.svg";
import { PAYMENT_METHOD } from "constants/index";

export const RightDetails = ({ data: booking, currency }) => {
  const { t } = useTranslation();
  const { releaseDeductDriverCommission } = useFlags();
  const {
    payment_breakdown = {},
    payout_status,
    total_cost,
    payment_type,
    payout_arrival_date,
    driver_commission,
  } = booking;
  const { cost = 0, tip = 0, tolls = 0, extras = 0 } = payment_breakdown;

  const totalCostDividedBy100 = total_cost / 100;
  const commissionValue = calculateDriverCommissionValue(
    totalCostDividedBy100,
    driver_commission,
    releaseDeductDriverCommission,
  );

  return (
    <div className="right-details">
      <div className="row">
        <Banner
          headingLevel="h4"
          headingText={t("pageBookingDetails.tripValue")}
          description={`${currency}${twoDecimals(cost)}`}
        />
      </div>
      <div className="row">
        <Banner
          headingLevel="h4"
          headingText={t("pageBookingDetails.tip")}
          description={`${currency}${twoDecimals(tip)}`}
        />
      </div>
      <div className="row">
        <Banner
          headingLevel="h4"
          headingText={t("pageBookingDetails.toll")}
          description={`${currency}${twoDecimals(tolls)}`}
        />
      </div>
      <div className="row">
        <Banner
          headingLevel="h4"
          headingText={t("pageBookingDetails.extra")}
          description={`${currency}${twoDecimals(extras)}`}
        />
      </div>
      {releaseDeductDriverCommission && commissionValue > 0 && (
        <div className="row">
          <Banner
            headingLevel="h4"
            headingText={t("pageBookingDetails.commissionValue")}
            description={`${currency}${twoDecimals(commissionValue)}`}
          />
        </div>
      )}
      <div className="row">
        <Banner
          headingLevel="h4"
          headingText={t("pageBookingDetails.amountDue")}
          description={`${currency}${twoDecimals(
            totalCostDividedBy100 - commissionValue,
          )}`}
        />
      </div>
      <div className="bottom-row">
        <Banner
          headingLevel="h4"
          headingText={t("pageBookingDetails.payoutStatus")}
          description={payout_status}
        />
      </div>
      <div className="bottom-row">
        <Banner
          headingLevel="h4"
          headingText={t("pageBookingDetails.payoutDate")}
          description={
            Boolean(payout_arrival_date)
              ? format(new Date(payout_arrival_date), "LLL dd, yyyy")
              : t("pageBookingDetails.missingDate")
          }
        />
      </div>
      {payment_type === PAYMENT_METHOD.CARD && <VisaIcon />}
      {/* <Button>Report and issue</Button> */}
    </div>
  );
};
