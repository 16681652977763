import React, { useState } from "react";

import { ReactComponent as EyeIcon } from "assets/icons/eyeIcon.svg";
import { ReactComponent as EyeIconOff } from "assets/icons/eyeIconOff.svg";

import { FormInput } from "../FormInput/FormInput";

import styles from "./FormPasswordInput.module.scss";

export const FormPasswordInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((isHidden) => !isHidden);
  };

  return (
    <div className={styles.passwordInputWrapper}>
      <FormInput
        {...props}
        type={showPassword ? "text" : "password"}
        wrapperClassName={styles.passwordInput}
      />
      {showPassword ? (
        <EyeIcon onClick={toggleShowPassword} className={styles.eyeIcon} />
      ) : (
        <EyeIconOff onClick={toggleShowPassword} className={styles.eyeIcon} />
      )}
    </div>
  );
};
