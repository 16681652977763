import { useTranslation } from "react-i18next";

import { SelectedCompanyFeatureFlagRequired } from "components/SelectedCompanyFeatureFlagRequired";
import {
  GeneralLayoutContent,
  GeneralLayoutHeader,
} from "components/GeneralLayout";

import { ReactComponent as DocumentsIcon } from "assets/icons/Wallet/documents.svg";

export function DriverDocumentLayout({ children }) {
  const { t } = useTranslation();

  return (
    <SelectedCompanyFeatureFlagRequired
      allowByFeatureFlags={(flags) => flags.isDocumentsFeatureEnabled}
      redirectToWhenNotAllowed="/"
    >
      <GeneralLayoutContent
        header={
          <GeneralLayoutHeader
            title={t("title.documents")}
            icon={<DocumentsIcon />}
          />
        }
      >
        {children}
      </GeneralLayoutContent>
    </SelectedCompanyFeatureFlagRequired>
  );
}
