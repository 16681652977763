import React, { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { Provider } from "react-redux";

import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import * as Sentry from "@sentry/react";

import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

import "./i18n";

import { store } from "./store/store";
import { CurrentCompanyIdContextProvider } from "./CurrentCompanyIdContext";
import { CrashPage } from "pages/CrashPage";

if (
  process.env.NODE_ENV === "production" ||
  process.env.NODE_ENV === "staging"
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_DEPLOY_ENV,
  });
}

(async function () {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY,
    context: {
      kind: "System",
      key: "System",
    },
  });

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <Sentry.ErrorBoundary fallback={<CrashPage />}>
      <LDProvider>
        <StrictMode>
          <Suspense>
            <Provider store={store}>
              <CurrentCompanyIdContextProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </CurrentCompanyIdContextProvider>
            </Provider>
          </Suspense>
        </StrictMode>
      </LDProvider>
    </Sentry.ErrorBoundary>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
