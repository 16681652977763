import { useTranslation } from "react-i18next";
import { Banner } from "components/Banner";
import { ReactComponent as EmptyImg } from "assets/icons/empty.svg";
import "./Empty.scss";

export const Empty = ({ title, customEmptyMessage }) => {
  const { t } = useTranslation();

  const headingText = t("componentEmpty.noItem", { item: title });
  const description = customEmptyMessage
    ? customEmptyMessage
    : t("componentEmpty.selectFleetToViewItem", { item: title });

  return (
    <div className="empty-wrapper">
      <EmptyImg />
      <Banner
        {...{
          headingText,
          description,
          headingLevel: "h1",
          className: "empty-description",
        }}
      />
    </div>
  );
};
