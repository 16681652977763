import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import { bulkSetOnboardingCredentials } from "store/slices/onboardingSlice";

import { api } from "api";
import { routes } from "routes";

import { WaveLayout } from "components/WaveLayout";

import { Spacer } from "components/Spacer";
import { Container } from "components/Container";
import { Title } from "components/Title";
import { Description } from "components/Description";

import { OnboardingSignUpForm } from "./OnboardingSignUpForm";

export const OnboardingSignUpPageInternal = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fleetSlug } = useParams();
  const { data } = api.endpoints.getMarketingPageByFleetSlug.useQuery({
    fleetSlug,
  });
  const fleetId = data?.data?.fleet_id ?? null;

  const [emailExists, setEmailExists] = useState(false);

  const [signupUser] = api.endpoints.signupUser.useMutation();

  function onSubmit({ email, password, passwordConfirm }) {
    setEmailExists(false);

    dispatch(
      bulkSetOnboardingCredentials({
        fleetId,
        email,
        password,
      }),
    );

    message.loading({
      content: t("processing.submitting"),
      duration: 0,
      key: "submitting",
    });

    signupUser({
      driver_signup: true,
      fleet_id: fleetId,
      email: email,
      password: password,
      password_confirmation: passwordConfirm,
    })
      .unwrap()
      .then(({ meta }) => {
        localStorage.setItem("auth_token", JSON.stringify(meta.token));
        navigate(routes.checkEmail.getURL(fleetSlug));
      })
      .catch((error) => {
        if (error?.status === 422) {
          setEmailExists(true);
        }
        message.error({ content: t("error.somethingWentWrong") });
      })
      .finally(() => {
        message.destroy("submitting");
      });
  }

  return (
    <>
      <Title>{t("pageSignUp.title")}</Title>
      <Description>{t("pageSignUp.description")}</Description>
      <Spacer />
      <OnboardingSignUpForm
        fleetSlug={fleetSlug}
        emailExists={emailExists}
        onSubmit={onSubmit}
      />
    </>
  );
};

export function OnboardingSignUpPage() {
  return (
    <WaveLayout>
      <Container width="small">
        <OnboardingSignUpPageInternal />
      </Container>
    </WaveLayout>
  );
}
