import { format } from "date-fns";
import { twoDecimals } from "helpers/twoDecimal";
import { useParams } from "react-router-dom";
import { Banner } from "components/Banner";
import { SubTopNavigator } from "components/SubTopNavigator";
import { InviteDriverIcon } from "../DriversList/Table/InviteDriverIcon";
import "./Driver.scss";
import { api } from "api";

export const Driver = () => {
  const { driverId } = useParams();
  const { data: driverData, isLoading } = api.endpoints.getDriverById.useQuery(
    driverId,
    {
      skip: !driverId,
    },
  );

  if (isLoading || !driverData) {
    return <div>Loading...</div>;
  }

  const driver = driverData?.data;

  const {
    name,
    ref,
    email,
    trips,
    fleet,
    invited_at,
    signed_up_at,
    stripe_details_submitted,
    stripe_payouts_enabled,
    phone,
  } = driver;

  const getFormattedMoney = () => {
    if (fleet.currency) {
      return new Intl.NumberFormat(window.navigator.language, {
        style: "currency",
        currency: fleet.currency,
        currencyDisplay: "narrowSymbol",
      })
        .format(0)
        .replace(/[0-9]./g, "");
    } else {
      return "--";
    }
  };

  return (
    <div className="driver-wrapper">
      <SubTopNavigator pathName="console/drivers" subject={name} />
      <div className="content">
        <div className="identity-section">
          <Banner headingLevel="h1" headingText="Identity" />
          <div className="row-details">
            <Banner
              headingLevel="h4"
              headingText="driver name"
              description={name}
            />
            <Banner
              headingLevel="h4"
              headingText="driver ref"
              description={ref}
            />
          </div>
          <div className="row-details collapseMobile">
            <Banner headingLevel="h4" headingText="email" description={email} />
            <Banner
              headingLevel="h4"
              headingText="phone number"
              description={phone}
            />
          </div>
          {/* <div className="row-details collapseMobile">
            <Banner
              headingLevel="h4"
              headingText="documents"
              description={
                <span className="documents">Driver documentation</span>
              }
            />
          </div> */}
        </div>

        <div className="payment-section">
          <Banner headingLevel="h1" headingText="Payment" />
          <div className="row-details collapseMobile">
            <Banner
              headingLevel="h4"
              headingText="Instant Payout eligibility"
              description="Eligible"
            />
            <Banner
              headingLevel="h4"
              headingText="stripe status"
              description={
                <InviteDriverIcon
                  invited_at={invited_at}
                  signed_up_at={signed_up_at}
                  stripe_details_submitted={stripe_details_submitted}
                  stripe_payouts_enabled={stripe_payouts_enabled}
                />
              }
            />
          </div>
          <div className="row-details collapseMobile">
            <Banner
              headingLevel="h4"
              headingText="last payout date"
              description={
                driver?.last_payout
                  ? format(
                      new Date(driver?.last_payout?.arrival_date),
                      "dd/MM/yy",
                    )
                  : "--"
              }
            />
            <Banner
              headingLevel="h4"
              headingText="last payout value"
              description={
                driver?.last_payout
                  ? `${getFormattedMoney()}${twoDecimals(
                      driver?.last_payout?.amount / 100,
                    )}`
                  : "--"
              }
            />
          </div>
          {/* <div className="row-details collapseMobile">
            <Banner
              headingLevel="h4"
              headingText="next payout date"
              description={next_payment_date ? format(new Date(next_payment_date), "dd/MM/yy") : '-'}
            />
            <Banner
              headingLevel="h4"
              headingText="next payout value"
              description={`${formattedMoney}${twoDecimals(next_payment_amount)}`}
            />
          </div> */}
          {/* <div className="row-details">
            <Banner
              headingLevel="h4"
              headingText=""
              description={<span className="documents">View payments</span>}
            />
          </div> */}
        </div>

        <div className="performance-section collapseMobile">
          <Banner headingLevel="h1" headingText="Performance" />
          <div className="row-details">
            <Banner
              headingLevel="h4"
              headingText="number of trips"
              description={trips}
            />
            {/* <Banner
              headingLevel="h4"
              headingText="cancellations"
              description="23"
            />
            <Banner headingLevel="h4" headingText="no-show" description="22" /> */}
          </div>
        </div>
        {/* <div className="feedback-section">
          <h1>Custommer feedback</h1>
          <CommentTabs />
          <Comment
            rate={4}
            date={moment()}
            descrtiption="TEST"
          />
          <Comment rate={2} date={moment()} descrtiption="Test comment" />
          <Comment rate={1} date={moment()} descrtiption="Test comment" />
          <Comment rate={5} date={moment()} descrtiption="Test comment" />
          <Comment rate={3} date={moment()} descrtiption="Test comment" />
        </div> */}
      </div>
    </div>
  );
};

export default Driver;
