import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useTranslation, Trans } from "react-i18next";

import { showErrorMsg } from "helpers/windowMassages";
import { ReactComponent as FileLoader } from "assets/icons/file-loader.svg";
import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import styles from "./MultipleFilePicker.module.scss";

export const MAX_FILE_SIZE = 20_000_000;
export const ALLOWED_IMAGE_TYPES = [
  "image/png",
  "image/jpeg",
  "application/pdf",
];

const units = ["bytes", "KB", "MB", "GB"];

export function convertToByteSize(x) {
  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
}

export const MultipleFilePicker = ({
  id,
  providedFiles,
  viewOnly,
  onChange,
  onRemove,
  multiple = false,
  maxFiles = 2,
  loadingArray = [],
}) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!viewOnly) {
      setFiles(providedFiles);
    }
  }, [providedFiles, viewOnly]);

  if (viewOnly) {
    if (providedFiles?.length) {
      return (
        <div className={styles.viewOnlyList}>
          {providedFiles.map((file, index) => {
            const isFromServer = typeof file === "string";

            return (
              <div
                className={styles.viewOnlyFile}
                key={isFromServer ? file : file.file_name}
              >
                <p className={styles.fileItemName}>
                  {isFromServer ? (
                    <a
                      className={styles.fileLink}
                      href={file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("componentFilePicker.fileN", { count: index + 1 })}
                    </a>
                  ) : (
                    file.file_name
                  )}
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div>{t("componentFilePicker.noDocuments")}</div>;
    }
  }

  const onFileInputChange = ({ target }) => {
    const inputFiles = target.files;
    const fileList = [];

    if (inputFiles?.length > maxFiles && multiple) {
      showErrorMsg({
        content: t("error.fileExtra", { count: maxFiles }),
      });
    }

    let maxIndex = maxFiles;
    if (inputFiles?.length < maxFiles) {
      maxIndex = inputFiles?.length;
    }

    for (let index = 0; index < maxIndex; index++) {
      const fileToPush = inputFiles[index];

      if (fileToPush.size > MAX_FILE_SIZE) {
        showErrorMsg({
          content: t("error.fileSizeBeyondLimit", {
            fileName: fileToPush.name,
            limit: convertToByteSize(MAX_FILE_SIZE),
          }),
        });
      } else if (!ALLOWED_IMAGE_TYPES.includes(fileToPush.type)) {
        showErrorMsg({
          content: t("error.fileNotImageOrPdf", { fileName: fileToPush.name }),
        });
      } else {
        const fileWithPreview = Object.assign(fileToPush, {
          preview: URL?.createObjectURL?.(fileToPush),
        });
        fileList.push(fileWithPreview);
      }
    }

    setFiles(fileList);
    onChange(fileList);

    target.value = null;
  };

  const removeFileByIndex = (index) => {
    const newFiles = [...files];

    newFiles.splice(index, 1);

    onRemove?.(newFiles);
    setFiles(newFiles);
  };

  return (
    <div className={styles.wrapper}>
      <p className={clsx(styles.label, files.length && styles.labelWithData)}>
        <Trans i18nKey="componentFilePicker.dragOrBrowseFiles">
          Drag your files here or <label htmlFor={id}>browse files</label> to
          upload.
        </Trans>
      </p>
      <input
        multiple={multiple}
        type="file"
        onChange={onFileInputChange}
        className={styles.hiddenInput}
        id={id}
      />
      {files.map((file, index) => {
        const isLoading = loadingArray.includes(file.lastModified);
        const isFromServer = typeof file === "string";

        const fileName = isFromServer
          ? t("componentFilePicker.fileN", { count: index + 1 })
          : (file.name ?? file.file_name);

        return (
          <div className={styles.fileItem} key={index}>
            {isLoading ? <FileLoader /> : <TickIcon />}
            <span>
              <p className={styles.fileItemName}>
                {isFromServer ? (
                  <a
                    className={styles.fileLink}
                    href={file}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {fileName}
                  </a>
                ) : (
                  fileName
                )}
              </p>
            </span>
            <span className={styles.fileItemActionContainer}>
              {isFromServer ? null : (
                <p className={styles.fileItemSize}>
                  {convertToByteSize(file.size)}
                </p>
              )}
              <p
                className={styles.fileItemRemoveBtn}
                onClick={() => removeFileByIndex(index)}
              >
                {t("action.remove")}
              </p>
            </span>
          </div>
        );
      })}
    </div>
  );
};
