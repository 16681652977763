import { createSlice } from "@reduxjs/toolkit";

const lastSevenDays = new Date().setDate(new Date().getDate() - 7);

const initialState = {
  dateFilter: [new Date(lastSevenDays).toString(), new Date().toString()],
};

export const bookingFilterSlice = createSlice({
  name: "boookingsFilters",
  initialState,
  reducers: {
    setBookingDateFilter: (state, action) => {
      state.dateFilter = action.payload;
    },
  },
});

export const { setBookingDateFilter } = bookingFilterSlice.actions;

export default bookingFilterSlice.reducer;
