import { useEffect, useState } from "react";
import { isAfter } from "date-fns";

import { ReactComponent as CalendarIcon } from "assets/icons/Bookings/calendar.svg";
import { useMediaQuery } from "hooks/useMediaQuery";

import { DateFnsDatePicker } from "components/DateFnsDatePicker";

import { MobileDatePickerRangeDrawer } from "./MobileDatePickerRangeDrawer";
import "./DateRangeInput.scss";

export const REGULAR_DATE_FORMAT = "dd MMM, YYYY";

export const DateRangeInput = ({ value, onChange }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isMobilePickerVisible, setIsMobilePickerVisible] = useState(false);

  const hideMobilePicker = () => {
    setIsMobilePickerVisible(false);
  };

  const showMobilePicker = () => {
    setIsMobilePickerVisible(true);
  };

  useEffect(() => {
    /**
     * Hide the mobile range input picker when the user expanded the browser
     * so when the user shrink back the browser, the panel will be hidden.
     */
    if (!isMobile) {
      /**
       * When `isMobile` changes to `false`
       * then hide mobile picker drawer.
       */
      hideMobilePicker();
    }
  }, [isMobile]);

  const applyMobilePicker = (range) => {
    if (typeof onChange === "function") {
      onChange(range);
    }
    setIsMobilePickerVisible(false);
  };

  if (!isMobile) {
    return (
      <DateFnsDatePicker.RangePicker
        allowClear={false}
        className="custom-date-range-picker"
        disabledDate={isFuture}
        format={REGULAR_DATE_FORMAT}
        separator={<RangeSeparator />}
        suffixIcon={<CalendarIcon />}
        value={value}
        onChange={onChange}
      />
    );
  }

  return (
    <>
      <DateFnsDatePicker.RangePicker
        allowClear={false}
        open={false}
        disabledDate={isFuture}
        format={REGULAR_DATE_FORMAT}
        separator={<RangeSeparator />}
        suffixIcon={<CalendarIcon />}
        value={value}
        onClick={showMobilePicker}
      />

      <MobileDatePickerRangeDrawer
        visible={isMobilePickerVisible}
        value={value}
        onClose={hideMobilePicker}
        onApply={applyMobilePicker}
      />
    </>
  );
};

export function isFuture(date) {
  return isAfter(new Date(date), new Date());
}

function RangeSeparator() {
  return <div className="date-range-separator">-</div>;
}
